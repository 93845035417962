// @flow

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {compose } from 'redux';

const StyleWarpper = styled.div`
  width: ${props => props.isFullWidth ? '100%' : '48%'};
  position: relative;
`;

class Dropdown extends PureComponent<Props> {
  render() {
    const {
      input: {
        onChange,
        value,
      },
      isFullWidth,
      placeholder,
      data,
      langData,
      optiontype,
    } = this.props;
    return (
      <StyleWarpper isFullWidth={isFullWidth}>
        <select
            class={langData.type === "zh-TW" ? "content-input content-input-full" : "content-input content-input-full"}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e)}>
            <option>{langData.option[optiontype]}</option>
            {data && data.map(val =>
                <option id={val.title} value={val.title}>
                    {val.title}
                </option>
            )}
        </select>
      </StyleWarpper>
    );
  }
}

const reduxHook = connect(
  state => ({
      langData: state.Content.lang,
  }),
);
  
export default compose(
  reduxHook,
)(Dropdown);
  