import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  compose,
  bindActionCreators,
} from 'redux';
import * as indexAction from '../actions/index.js';
import { withRouter } from 'react-router';

import iconSteak from '../static/icon01.svg';
import iconFleur from '../static/fleur-gray.svg';
import dividerCenter from '../static/divider-center.png';
import dividerLeft from '../static/divider-left.png';
import dividerRight from '../static/divider-right.png';
import dots from '../static/dots.png';


const StyleImg = styled.div`
    width: 100%;
    height: 730px;
    background-image: url('${props => props.src}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width:1400px) {
        height: 550px;
      }
    @media (max-width:1280px) {
        height: 500px;
    }
    @media (max-width: 1023px) {
        width: 100%;
        box-shadow: none;
        height: 550px;
      }
      @media (max-width: 414px) {
        height: 350px;
      }
`;

const StyleSubTitleRed = styled(Link)`
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    letter-spacing: -1px;
    width: fit-content;
    color: ${props => props.isClick ? '#be2f37': '#5d5d5d'};
    font-size: 1.8rem;
    line-height: 2rem;
    display: block;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: .3rem;
    >span >i {
        width: 20px;
        height: 16px;
        position: absolute;
        transition: left .5s;
        left: .5rem;
        top: 30%;
        transform: translate(-50%, -50%) rotate(-90deg);
    }
    >span {
        position: relative;
        font-weight: 700;
    }
    &:hover {
        >span >i {
            left: .7rem;
        }
    }
    @media (max-width: 1366px) {
        >span >i {
            width: 18px;
            height: 15px;
            top: 30%;
        }
    }
    @media (max-width: 768px) {
        font-size: 1.5rem;
        line-height: 1.7rem;
        >span >i {
            width: 16px;
            height: 12px;
            top: 30%;
        }
    }
    @media (max-width: 414px) {
        margin-bottom: 2px;
        font-size: 1.2rem;
        line-height: 1.4rem;
        >span {
            line-height: 1rem;
        }
        >span >i {
            width: 12px;
            height: 10px;
        }
    }
`;

const StyleSubTitle = styled.div`
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    letter-spacing: -1px;
    width: fit-content;
    color: #5d5d5d;
    font-size: 1.8rem;
    line-height: 2rem;
    display: block;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: .3rem;
    >span {
        position: relative;
        font-weight: 700;
    }
    @media (max-width: 768px) {
        font-size: 1.5rem;
        line-height: 1.7rem;
    }
    @media (max-width: 414px) {
        margin-bottom: 2px;
        font-size: 1.2rem;
        line-height: 1.4rem;
        >span {
            line-height: 1rem;
        }
    }
`;

const StyleContentFoodList = styled.div`
    display: flex;
    width: 1160px;
    margin: 70px auto;
    justify-content: center;
    flex-flow: wrap;
    @media (max-width: 1366px) {
        width: 90%;
      }
    @media (max-width: 768px) {
        justify-content: flex-start;
        margin: 35px auto;
      }
`;

const StyleFoodItem = styled.div`
    width: 22%;
    margin: 0 3% 35px 0;
    padding: 25px 0;
    >div {
        @media (max-width: 1023px) {
            text-align: start; 
        } 
    }
    @media (max-width: 1023px) {
        width: 100%;
        margin-bottom: 35px;
        padding: 0;
    }
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
    @media (max-width: 414px) {
        margin-bottom: 15px;
    }
`;

const StyleTextTitleLink = styled(Link)`
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    letter-spacing: -1px;
    display: block;
    color: #be2f37;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1.8rem;
    line-height: 2rem;
    margin-bottom: .5rem;
    font-weight: 700;
    >span >i {
        width: 20px;
        height: 16px;
        position: absolute;
        transition: left .5s;
        left: .7rem;
        top: 30%;
        transform: translate(-50%, -50%) rotate(-90deg);
    }
    >span {
        position: relative;
    }
    &:hover {
        >span >i {
            left: 1rem; 
        }
    }
    @media (max-width: 1366px) {
        >span >i {
            width: 18px;
            height: 14px;
            top: 30%;
        }
      }
    @media (max-width: 1023px) {
        text-align: start; 
      }
    @media (max-width: 768px) {
        font-size: 1.5rem;
        line-height: 1.7rem; 
        >span >i {
            width: 16px;
            height: 12px;
            top: 30%;
        }
      }
      @media (max-width: 414px) {
        letter-spacing: 0;
        font-size: 1.2rem;
        line-height: 1.4rem;
        margin-bottom: 2px;
        >span >i {
            width: 12px;
            height: 10px;
        }
      }
`;

const StyleTextTitle = styled.div`
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    letter-spacing: -1px;
    display: block;
    color: #be2f37;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1.8rem;
    line-height: 2rem;
    margin-bottom: .5rem;
    font-weight: 700;
    @media (max-width: 1023px) {
        text-align: start; 
      }
      @media (max-width: 768px) {
        font-size: 1.5rem;
        line-height: 1.7rem;
      }
      @media (max-width: 414px) {
        letter-spacing: 0;
        font-size: 1.2rem;
        line-height: 1.4rem;
        margin-bottom: 2px;
      }
`;


const StyleContentImg = styled.div`
    width: 1160px;
    height: 550px;
    margin: 0 auto 120px;
    background-image: url('${props => props.imgUrl}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: 1366px) {
        width: 90%;
        margin: 0 auto 70px;
      }
    @media (max-width: 768px) {
        height: 350px;
    }
    @media (max-width: 414px) {
        margin: 0 auto 35px;
        height: 200px;
    }
`;

const StyleDividingLine = styled.div`
    width: 1160px;
    margin: 0 auto 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: 1366px) {
        width: 90%;
      }
    @media (max-width: 414px) {
        margin: 0 auto 35px;
    }
`;

const StyleIconFleur = styled.div`
    width: 35px;
    height: 38px;
    margin-bottom: 10px;
    background-image: url('${iconFleur}');
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 414px) {
        width: 25px;
        height: 26px;
    }
`;

const StyleDividingText = styled.div`
    width: 100%;
    background: url('${dividerLeft}') 0 .5rem/1.9375rem .375rem no-repeat,
                url('${dividerRight}') 100% .5rem/1.9375rem .375rem no-repeat,
                url('${dividerCenter}') 0 .5rem/.0625rem .375rem repeat-x;
    background-position: 0 .75rem,100% .75rem,0 .75rem;
    background-size: 4rem .75rem,4rem .75rem,.0625rem .75rem;
    @media (max-width: 768px) {
        background: url('${dividerLeft}') 0 .5em/2.5em .6em no-repeat,
        url('${dividerRight}') 100% .5em/2.5em .6em no-repeat,
        url('${dividerCenter}') 0 .5em/1em .6em repeat-x;
    }
`;

const StyleContentSpecialtyList = styled.div`
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    width: 1160px;
    margin: 0 auto 40px;
    @media (max-width: 1366px) {
        width: 90%;
      }
`;

const StyleSpecialtytItem = styled.div`
    width: 22%;
    margin: 1% 3% 4% 0;
    padding: 25px 0;
    color: #5d5d5d;
    color: #868686;
    @media (max-width: 1023px) {
        width: 100%;
        text-align: start;
        margin: 0 0 35px;
      }
    @media (max-width: 768px) {
        margin: 0 0 20px;
        padding: 0px;
    }
    @media (max-width: 414px) {
        margin-bottom: 10px;
    }
`;

const StyleDividingType = styled.div`
    height: 1rem;
    background: url('${dots}') 50% 0 repeat-x;
    background-size: 30px 4px;
    display: flex;
    justify-content: center;
    margin: 3rem 0 4.5rem;
    @media (max-width: 414px) {
        margin: 2rem 0 30px;
    }  
`;

const StyleIconFleurWep = styled.div`
    width: 60px;
    height: 35px;
    background-color: #f3f2eb;
    display: flex;
    justify-content: center;
    position: relative;
    top: -1em;
`;

const StyleSeafoodImg = styled.div`
    width: 100%;
    height: 550px;
    background-image: url('${props => props.imgUrl}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media (max-width: 414px) {
        height: 200px;
    }
`;


class Food extends PureComponent<Props> {
    componentWillMount() {
        const {
            location: {
                state,
                pathname,
            },
            fetchNodeData,
            nodeData,
            allNodes,
        } = this.props;
        const mainpPath = pathname.split("/");
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    componentDidUpdate(preprops) {
        const {
            location: {
                pathname,
                state,
            },
            nodeData,
            langData,
            getFoodDetailData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if(!state) {
            if(nodeData[`/${mainpPath[2]}`]){
                if(!getFoodDetailData[langData.type]) {
                    this.fetchFoodDetailData(); 
                }
            } 
        } else { 
            if(nodeData[`/${mainpPath[2]}`]){
                if(!getFoodDetailData[langData.type]) {
                    this.fetchFoodDetailData(); 
                }
            }  
        }
    }

    fetchFoodDetailData() {
        const {
            location: {
                pathname,
            },
            nodeData,
            getFoodDetail,
            langData,
            getFoodDetailData,
        } = this.props;
        let selectList = [];
        const mainpPath = pathname.split("/");
        const responseData = nodeData[`/${mainpPath[2]}`] && nodeData[`/${mainpPath[2]}`].datas;
        responseData && responseData.filter(
            val => {
                if(val.datas.length) {
                    return val.datas.filter(
                        x => {
                            if(x.datas) {
                                return x.datas.filter(
                                    y => {
                                        if(y.url)
                                        {
                                            return selectList.push(y);
                                        }
                                    }
                                )
                            }
                        }
                    )      
                }  
            }
        );
        getFoodDetail(selectList, langData.type);
    }

    render() {
        const {
            nodeData,
            menuData,
            location: {
                state,
                pathname,
            },
            langData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[2]}`]) return null;
        const Datas = nodeData[`/${mainpPath[2]}`].datas;
        if(!Datas.length) return null;
        const reservations = menuData.find(val => val.route === '/reservations');
        const promotions = menuData.find(val => val.route === '/promotions');
        const responseData = Datas.filter(val => val.isDynamicArea === false);
        const dynamicData = Datas.filter(val => val.isDynamicArea === true);
        const steaksArticleData = responseData[0].datas.filter(val => val.isArticle === true);
        const steaksData = responseData[0].datas.filter(val => val.isArticle === false);
        const appetizersArticleData = responseData[2].datas.filter(val => val.isArticle === true);
        const appetizersData = responseData[2].datas.filter(val => val.isArticle === false);
        return (
            <div class="content-warpper">
                <div id={responseData[0].html || null} style={{ height: 1 }} />
                <div class="content-food-title-warpper">
                    {responseData[0].title && <div class="content-title">{responseData[0].title}</div>}
                    {responseData[0].summary && <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>{responseData[0].summary}</div>}
                </div>

                {steaksData[0] && <div class="content-inner-warpper food-content-attr">
                    <div class="food-content-left">
                    {steaksData[0].url && <StyleImg src={steaksData[0].url}/>}
                        <div class="content-sub-innner-warp">
                            <div class="gray-line-horizontal"></div>
                        </div>
                        {steaksData[1].datas.length && steaksData[1].datas.map(val => (
                        <div class="food-steak-list-item">
                            {val.url ? 
                            <StyleSubTitleRed  
                            isClick={val.url} 
                            to={{pathname: `/${langData.type}/${mainpPath[2]}${val.url}`, state: {...val}}}>
                                <span dangerouslySetInnerHTML={{ __html: val.title }} />
                                {val.url ? <span>
                                    <i class="icon-arrow-red" style={{transform: 'rotate(-90deg)'}}></i>
                                </span> : null}
                            </StyleSubTitleRed> :
                            <StyleSubTitle>
                                <span dangerouslySetInnerHTML={{ __html: val.title }} />
                            </StyleSubTitle>
                            }
                            <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{val.summary}</div>
                        </div>))}
                    </div>
                    <div class="food-content-right">
                        {steaksData[0].datas.length && steaksData[0].datas.map(val => (
                        <div class="food-steak-list-item">
                            {val.url ? 
                            <StyleSubTitleRed  
                            isClick={val.url} 
                            to={{pathname: `/${langData.type}/${mainpPath[2]}${val.url}`, state: {...val}}}>
                                <span dangerouslySetInnerHTML={{ __html: val.title }} />
                                {val.url ? <span>
                                    <i class="icon-arrow-red" style={{transform: 'rotate(-90deg)'}}></i>
                                </span> : null}
                            </StyleSubTitleRed> :
                            <StyleSubTitle>
                                <span dangerouslySetInnerHTML={{ __html: val.title }} />
                            </StyleSubTitle>
                            }
                            {val.summary && <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{val.summary}</div>}
                        </div>
                        ))}
                        <div class="content-sub-innner-warp">
                            <div class="gray-line-horizontal"></div>
                        </div>
                        {steaksData[1].url && <StyleImg className="food-steak-list-img" src={steaksData[1].url}/>}
                    </div>
                </div>}

                {steaksData[2] && steaksData[2].datas.length && <StyleContentFoodList>
                    {steaksData[2].datas.map(val => (
                        <StyleFoodItem>
                            {val.url ? 
                            <StyleTextTitleLink 
                                style={val.url ? null : {color:'#5d5d5d'}} 
                                to={val.url ? {pathname: `/${langData.type}/${mainpPath[2]}${val.url}`, state: {...val}}: {}}>
                                {/* to={val.url ? {pathname: `${val.route}`, state: {...val}} : null}> */}
                                <span dangerouslySetInnerHTML={{ __html: val.title }} />
                                <span>
                                    {val.url ? <i class='icon-arrow-red' style={{transform: 'rotate(-90deg)'}} /> : null}
                                </span>
                            </StyleTextTitleLink> : 
                            <StyleTextTitle
                                dangerouslySetInnerHTML={{ __html: val.title }} 
                                style={{color:'#5d5d5d'}}>
                            </StyleTextTitle>}
                            {val.summary && <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{val.summary}</div>}
                        </StyleFoodItem>
                    ))}
                </StyleContentFoodList>}

                {steaksData[2] && steaksData[2].url && <StyleContentImg imgUrl={steaksData[2].url}/>}

                {steaksArticleData.map(data => (
                <div>
                {data.datas.length ? <StyleDividingLine>
                    <StyleIconFleur />
                    <StyleDividingText>
                        <div class="dividing-inner-text">{data.title}</div>
                    </StyleDividingText>
                </StyleDividingLine> : null}
                <StyleContentSpecialtyList>
                    {data.datas.length ? data.datas.map(val => (
                        <StyleFoodItem>
                        {val.url ? 
                        <StyleTextTitleLink 
                            style={val.url ? null : {color:'#5d5d5d'}} 
                            to={val.url ? {pathname: `/${langData.type}/${mainpPath[2]}${val.url}`, state: {...val}}: {}}>
                            {/* to={val.url ? {pathname: `${val.route}`, state: {...val}} : null}> */}
                            <span dangerouslySetInnerHTML={{ __html: val.title }} />
                            <span>
                                {val.url ? <i class='icon-arrow-red' style={{transform: 'rotate(-90deg)'}} /> : null}
                            </span>
                        </StyleTextTitleLink> : 
                        <StyleTextTitle
                        dangerouslySetInnerHTML={{ __html: val.title }} 
                            style={{color:'#5d5d5d'}}>
                        </StyleTextTitle>}
                            {val.summary && <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{val.summary}</div>}
                        </StyleFoodItem>
                    )) :null}
                </StyleContentSpecialtyList>
                </div>))}
                
                {/* {responseData[0].datas[4] && responseData[0].datas[4].title &&
                <StyleDividingLine>
                    <StyleIconFleur />
                    <StyleDividingText>
                        <div class="dividing-inner-text">{responseData[0].datas[4].title}</div>
                    </StyleDividingText>
                </StyleDividingLine>}

                {responseData[0].datas[4] && <StyleContentSpecialtyList>
                    {responseData[0].datas[4].datas.length ? responseData[0].datas[4].datas.map(val => (
                        <StyleFoodItem>
                        {val.url ? 
                        <StyleTextTitleLink 
                            style={val.url ? null : {color:'#5d5d5d'}} 
                            // to={val.url ? { pathname: `${pathname}${val.url}`, state: {...val} } : null}>
                                to={val.url ? {pathname: `${val.route}`, state: {...val}} : null}>
                            <span>{val.title}</span>
                            <span>
                                {val.url ? <i class='icon-arrow-red' style={{transform: 'rotate(-90deg)'}} /> : null}
                            </span>
                        </StyleTextTitleLink> : 
                        <StyleTextTitle 
                            style={{color:'#5d5d5d'}}>
                            {val.title}
                        </StyleTextTitle>}
                            {val.summary && <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{val.summary}</div>}
                        </StyleFoodItem>
                    )) : null}
                </StyleContentSpecialtyList>} */}

                {/* PERFECTLY PRIME */}
                {steaksData[3] && 
                <div class="content-special-area" style={{backgroundImage: `url('${steaksData[3].url || null}')`}}>
                    <img class="header-icon" src={`${iconSteak}`} alt="icon"/>
                    {steaksData[3].title && <div class="content-sub-innner-warp">
                        <div class="content-sub-title">{steaksData[3].title}</div>
                    </div>}
                    {steaksData[3].summary && <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{steaksData[3].summary}</div>}
                    {steaksData[3].html && <Link class="content-food-bt" to={{ pathname: `/${langData.type}${reservations && reservations.route}`, state: {...reservations} }}>{steaksData[3].html}</Link>}
                </div>}
                {/* SEAFOOD & SPECIALITIES */}
                {responseData[1] && <div id={responseData[1].html || null}>
                <StyleDividingType>
                    <StyleIconFleurWep>
                        <StyleIconFleur />
                    </StyleIconFleurWep>
                </StyleDividingType>
                <div class="content-inner-warpper">
                    {responseData[1].title && <div class="content-title">{responseData[1].title}</div>}
                    {responseData[1].summary && <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>{responseData[1].summary}</div>}
                </div>
                {responseData[1].datas[0] && <StyleContentSpecialtyList>
                    {responseData[1].datas[0].datas.length && responseData[1].datas[0].datas.map(val => (
                        <StyleFoodItem>
                        {val.url ? 
                        <StyleTextTitleLink 
                            style={val.url ? null : {color:'#5d5d5d'}} 
                            to={val.url ? {pathname: `/${langData.type}/${mainpPath[2]}${val.url}`, state: {...val}}: {}}>
                                {/* to={val.url ? {pathname: `${val.route}`, state: {...val}} : null}> */}
                            <span dangerouslySetInnerHTML={{ __html: val.title }} />
                            <span>
                                {val.url ? <i class='icon-arrow-red' style={{transform: 'rotate(-90deg)'}} /> : null}
                            </span>
                        </StyleTextTitleLink> : 
                        <StyleTextTitle 
                            dangerouslySetInnerHTML={{ __html: val.title }}
                            style={{color:'#5d5d5d'}}>
                        </StyleTextTitle>}
                            {val.summary && <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{val.summary}</div>}
                        </StyleFoodItem>
                        ))}
                </StyleContentSpecialtyList>}
                {responseData[1].datas[0] && responseData[1].datas[0].url && <StyleSeafoodImg imgUrl={responseData[1].datas[0].url}/>}
                </div>}
                
                {/* APPETIZERS & SIDES */}
                {responseData[2] && <div id={responseData[2].html || null}>
                <StyleDividingType>
                    <StyleIconFleurWep>
                        <StyleIconFleur />
                    </StyleIconFleurWep>
                </StyleDividingType>  
                <div class="content-inner-warpper">
                    {responseData[2].title && <div class="content-title">{responseData[2].title}</div>}
                    {responseData[2].summary && <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>{responseData[2].summary}</div>}
                </div>
                {responseData[2].url && <StyleContentImg imgUrl={responseData[2].url}/>}
                {appetizersArticleData.map(data => (
                <div>
                    {data.title && <StyleDividingLine>
                        <StyleIconFleur />
                        <StyleDividingText>
                            <div class="dividing-inner-text">{data.title}</div>
                        </StyleDividingText>
                    </StyleDividingLine>}
                <StyleContentSpecialtyList>
                {data.datas.length && data.datas.map(val => (
                    <StyleSpecialtytItem>
                       {val.url ? 
                        <StyleTextTitleLink 
                            style={val.url ? null : {color:'#5d5d5d'}} 
                            to={val.url ? {pathname: `/${langData.type}/${mainpPath[2]}${val.url}`, state: {...val}}: {}}>
                            {/* to={val.url ? {pathname: `${val.route}`, state: {...val}} : null}> */}
                            <span dangerouslySetInnerHTML={{ __html: val.title }} />
                            <span>
                                {val.url ? <i class='icon-arrow-red' style={{transform: 'rotate(-90deg)'}} /> : null}
                            </span>
                        </StyleTextTitleLink> : 
                        <StyleTextTitle 
                            dangerouslySetInnerHTML={{ __html: val.title }}
                            style={{color:'#5d5d5d'}}>
                        </StyleTextTitle>}
                        {val.summary && <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{val.summary}</div>}
                    </StyleSpecialtytItem>
                ))}
                </StyleContentSpecialtyList>
                </div>))}
                {/* <StyleDividingLine>
                    <StyleIconFleur />
                    <StyleDividingText>
                        <div class="dividing-inner-text">{responseData[2].datas[1].title}</div>
                    </StyleDividingText>
                </StyleDividingLine>
                <StyleContentSpecialtyList>
                {responseData[2].datas[1].datas.map(val => (
                    <StyleSpecialtytItem>
                        {val.url ? 
                        <StyleTextTitleLink 
                            style={val.url ? null : {color:'#5d5d5d'}} 
                            // to={val.url ? { pathname: `${pathname}${val.url}`, state: {...val} } : null}>
                            to={val.url ? {pathname: `${val.route}`, state: {...val}} : null}>
                            <span>{val.title}</span>
                            <span>
                                {val.url ? <i class='icon-arrow-red' style={{transform: 'rotate(-90deg)'}} /> : null}
                            </span>
                        </StyleTextTitleLink> : 
                        <StyleTextTitle 
                            style={{color:'#5d5d5d'}}>
                            {val.title}
                        </StyleTextTitle>}
                    <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{val.summary}</div>
                    </StyleSpecialtytItem>
                    ))}
                </StyleContentSpecialtyList> */}
                {appetizersData[0] && appetizersData[0].url &&  <StyleSeafoodImg imgUrl={appetizersData[0].url}/>}
                </div>}

                {/* SOUPS & SALADS */}
                {responseData[3] && <div id={responseData[3].html || null}>
                <StyleDividingType>
                    <StyleIconFleurWep>
                        <StyleIconFleur />
                    </StyleIconFleurWep>
                </StyleDividingType>
                <div class="content-inner-warpper">
                    <div class="content-title">{responseData[3].title}</div>
                    <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>{responseData[3].summary}</div>
                </div>
                <StyleContentImg imgUrl={responseData[3].url}/>
                <StyleContentSpecialtyList>
                    {responseData[3].datas[0].datas.map(val => (
                        <StyleSpecialtytItem>
                            {val.url ? 
                        <StyleTextTitleLink 
                            style={val.url ? null : {color:'#5d5d5d'}} 
                            to={val.url ? {pathname: `/${langData.type}/${mainpPath[2]}${val.url}`, state: {...val}}: {}}>
                            {/* to={val.url ? {pathname: `${val.route}`, state: {...val}} : null}> */}
                            {/* <span>{val.title}</span> */}
                            <span dangerouslySetInnerHTML={{ __html: val.title }}/>
                            <span>
                                {val.url ? <i class='icon-arrow-red' style={{transform: 'rotate(-90deg)'}} /> : null}
                            </span>
                        </StyleTextTitleLink> : 
                        <StyleTextTitle 
                        dangerouslySetInnerHTML={{ __html: val.title }}
                            style={{color:'#5d5d5d'}}>
                            {/* {val.title} */}
                        </StyleTextTitle>}
                        <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{val.summary}</div>
                        </StyleSpecialtytItem>
                        ))}
                </StyleContentSpecialtyList>
                </div>}

                {/* DESSERTS */}
                {responseData[4] && <div id={responseData[4].html}>
                    <StyleDividingType>
                        <StyleIconFleurWep>
                            <StyleIconFleur />
                        </StyleIconFleurWep>
                    </StyleDividingType>
                    <div class="content-inner-warpper">
                        {responseData[4].title && <div class="content-title">{responseData[4].title}</div>}
                        {responseData[4].summary && <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>{responseData[4].summary}</div>}
                    </div>
                    {responseData[4].url && <StyleContentImg imgUrl={responseData[4].url}/>}
                    <StyleContentSpecialtyList>
                        {responseData[4].datas[0].datas.map(val => (
                            <StyleSpecialtytItem>
                                {val.url ? 
                                <StyleTextTitleLink 
                                    style={val.url ? null : {color:'#5d5d5d'}} 
                                    to={val.url ? {pathname: `/${langData.type}/${mainpPath[2]}${val.url}`, state: {...val}}: {}}>
                                    {/* to={val.url ? {pathname: `${val.route}`, state: {...val}} : null}> */}
                                    <span dangerouslySetInnerHTML={{ __html: val.title }} />
                                    <span>
                                        {val.url ? <i class='icon-arrow-red' style={{transform: 'rotate(-90deg)'}} /> : null}
                                    </span>
                                </StyleTextTitleLink> : 
                                <StyleTextTitle
                                    dangerouslySetInnerHTML={{ __html: val.title }} 
                                    style={{color:'#5d5d5d'}}>
                                </StyleTextTitle>}
                            <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{val.summary}</div>
                            </StyleSpecialtytItem>
                        ))}
                    </StyleContentSpecialtyList>
                </div>}

                {/* Dynamic Area */}
                {dynamicData.length ? dynamicData.map(val => (
                <div id={val.html || null}>
                     <StyleDividingType>
                        <StyleIconFleurWep>
                            <StyleIconFleur />
                        </StyleIconFleurWep>
                    </StyleDividingType>
                    <div class="content-inner-warpper">
                        {val.title ? <div class="content-title">{val.title}</div> : null}
                        {val.summary ? <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>{val.summary}</div> : null}
                    </div>
                    {val.url ? <StyleContentImg imgUrl={val.url}/> : null}
                    {val.datas.length ? val.datas.map(data => (
                    <div>
                    <StyleDividingLine>
                        <StyleIconFleur />
                        <StyleDividingText>
                            <div class="dividing-inner-text">{data.title}</div>
                        </StyleDividingText>
                    </StyleDividingLine>
                    <StyleContentSpecialtyList>
                        {data.datas ? data.datas.map(list => (
                            <StyleSpecialtytItem>
                                {list.url ? 
                                <StyleTextTitleLink 
                                    style={list.url ? null : {color:'#5d5d5d'}} 
                                    to={list.url ? {pathname: `/${langData.type}/${mainpPath[2]}${list.url}`, state: {...list}}: {}}>
                                        {/* to={list.url ? {pathname: `${list.route}`, state: {...list}} : null}> */}
                                    <span dangerouslySetInnerHTML={{ __html: list.title }} />
                                    <span>
                                        {list.url ? <i class='icon-arrow-red' style={{transform: 'rotate(-90deg)'}} /> : null}
                                    </span>
                                </StyleTextTitleLink> : 
                                <StyleTextTitle
                                    dangerouslySetInnerHTML={{ __html: list.title }} 
                                    style={{color:'#5d5d5d'}}>
                                </StyleTextTitle>}
                            {list.summary ? <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{list.summary}</div> : null}
                            </StyleSpecialtytItem>
                        )) : null}

                    </StyleContentSpecialtyList>
                    </div>)) : null}
                </div>
                )) : null}

                {/* SPECIALTY DIETS */}
                {/* {responseData[5] && <div id={responseData[5].html || null}>
                <StyleDividingType>
                    <StyleIconFleurWep>
                        <StyleIconFleur />
                    </StyleIconFleurWep>
                </StyleDividingType>
                    <div class="content-inner-warpper">
                        {responseData[5].title && <div class="content-title">{responseData[5].title}</div>}
                        {responseData[5].summary && <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>{responseData[5].summary}</div>}
                        {responseData[5].datas[0] && <div class="content-sub-innner-warp">
                            {responseData[5].datas[0].title && <Link class="content-food-bt" to={{ pathname: `${promotions && promotions.route}`, state: {...promotions} }}>{responseData[5].datas[0].title}</Link>}
                        </div>}
                    </div>
                    {responseData[5].html && <div class="content-inner-bottom-warpper">
                        <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>{responseData[5].html}</div>
                    </div>}
                </div>} */}
                {/* MORE */}
                {responseData[5] && <div id={responseData[5].html || null}>
                <StyleDividingType>
                    <StyleIconFleurWep>
                        <StyleIconFleur />
                    </StyleIconFleurWep>
                </StyleDividingType>
                    <div class="content-inner-warpper-bottom">
                        {responseData[5].summary && <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>{responseData[5].summary}</div>}
                        {responseData[5].html && <div class="content-sub-innner-warp">
                            {responseData[5].html && <a class="content-food-bt" href={responseData[5].url} target='_blank'>{responseData[5].html}</a>}
                        </div>}
                    </div>
                </div>}

            </div>
        );
    }
}

const reduxHook = connect(
    state => ({
        nodeData: state.Content.nodeData,
        menuData: state.Content.menuData,
        allNodes: state.Content.allNodes,
        langData: state.Content.lang,
        getFoodDetailData: state.Content.getFoodDetailData,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
  );

export default compose(
    withRouter,
    reduxHook,
)(Food);
