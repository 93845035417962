import React, { PureComponent } from 'react';
// import styled from 'styled-components';

class HeaderTop2 extends PureComponent<Props> {
    render() {
        return (
            <div class="home2-header-top-wrap">
                <div class="header-top-text">FIND YOUR RUTH'S CHRIS</div>
                <div class="header-top-arrow-icon">
                    <i class="icon-arrow-red" />
                </div>
            </div>
        );
    }
}
export default HeaderTop2;