import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import footerBorder from '../../static/footer-border.png';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import * as indexAction from '../../actions/index.js';

import fleurGray from '../../static/fleur-gray.svg';
import fb from '../../static/fb.svg';
import IG from '../../static/IG.svg';
import { compose } from 'redux';
import { connect } from 'react-redux';

const StyleWarpper = styled.div`
    width: 100%;
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    color: #f3f3eb;
    background-color: #5a5a5a;
    position: relative;
    padding: 30px 0;
`;

const StyleFooterBorder = styled.div`
    display: flex;
    width: 100%;
    height: 3.125rem;
    top: -1.5625rem;
    left: 0;
    position: absolute;
    background-size: 390px 100%;
    background: url('${footerBorder}') 50% 0 no-repeat;
    z-index: 1;
    @media (max-width: 414px) {
        width: 90%;
        height: 25px;
        top: -24px;
        left: 50%;
        transform: translate(-50%, 0);
        background-size: cover;
    }
`;

const StyleStoreWarp = styled.div`
    width: 1160px;
    display: flex;
    margin: 30px auto 20px;
    @media (max-width: 1366px) {
        width: 90%;
    }
    @media (max-width: 1023px) {
        flex-wrap: wrap;
        justify-content: space-around;
    }
`;

const StyleStore = styled.div`
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: .9rem;
    letter-spacing: 1px;
    > div {
        font-weight: 600;
        text-align: center;
        width: fit-content;
        margin: 0 auto 10px;
    } 
    > div > p {
        word-break: break-all;
        text-align: start;
    }
    > div > p >a {
        color: #f3f3eb;
    }
    @media (max-width: 1023px) {
        width: 45%;
    }
    @media (max-width: 414px) {
        width: 100%;
        > div > p {
            letter-spacing: 0px;
        }
    }
`;

class Footer extends PureComponent<Props> {
    // componentDidUpdate(preprops) {
    //     const {
    //         location: {
    //             state,
    //             pathname,
    //         },
    //         allNodes,
    //         fetchNodeData,
    //         nodeData,
    //         langData,
    //         menuData,
    //         footerData,
    //     } = this.props;
    //     const mainpPath = pathname.split("/");
    //     if(!state) {
    //         if(!nodeData['/reservations'] && allNodes.childNode) {
    //             // const reservations = allNodes.childNode[0].childNode.find(val => val.route === '/reservations');
    //             if((pathname === `/${langData.type}` || pathname === `/${langData.type}/`)) {
    //                 if(preprops.footerData !== footerData) {
    //                     if(!nodeData[`/${mainpPath[2]}`]) {
    //                         if(!allNodes.childNode) return null;
    //                         const thisNodeData = allNodes.childNode[0].childNode.find(val => val.route === `/${mainpPath[2]}`);
    //                         if(!thisNodeData) return null;
    //                         fetchNodeData(`/${thisNodeData.nodeId}`, thisNodeData.route);
    //                     } 
    //                 } 
    //             } else {
    //                 if(preprops.footerData !== footerData) {
    //                     if(!nodeData[`/${mainpPath[2]}`]) {
    //                         if(!allNodes.childNode) return null;
    //                         const thisNodeData = allNodes.childNode[0].childNode.find(val => val.route === `/${mainpPath[2]}`);
    //                         console.log('111',preprops);
    //                         fetchNodeData(`/${thisNodeData.nodeId}`, thisNodeData.route);
    //                     } 
    //                 } 
    //             }
    //         }
    //     } else {  
    //         if(menuData.length) {
    //             const reservations = menuData.find(val => val.route === '/reservations');
    //             if(!reservations) return null;
    //             if(!nodeData['/reservations']) { 
    //                 fetchNodeData(reservations.url, reservations.route);
    //             }
    //         }
    //     } 
    // }

    render() {
        const {
            footerData,
            langData,
            nodeData,
            location: {
                pathname,
                state,
            },
        } = this.props;

        const storeData = nodeData["/reservations"] && nodeData["/reservations"].datas;
        const mainpPath = pathname.split("/");
        
        return (
            <StyleWarpper>
                <StyleFooterBorder />
                <img class="footer-icon" src={`${fleurGray}`} alt='fleurGray'></img>
                <div class="footer-linkWep">
                    {footerData.map(val => ( val.route !== '/careers' ? 
                        (val.route !== '/member' ?
                        <Link 
                            key={val.route} 
                            class="nav-button nav-footer-button"
                            to={{ pathname: `/${langData.type}${val.route}`, state: {...val} }}>
                            {val.title}
                        </Link>
                        : <a 
                            key={val.route} 
                            class={langData.type === "zh-TW" ? "nav-button nav-footer-button" : "display-none"}
                            href="https://www.google.com.tw/?hl=zh_TW"
                            target="_blank">
                            {val.title} 
                        </a>)
                        : <a 
                            key={val.route} 
                            class={langData.type === "zh-TW" ? "nav-button nav-footer-button" : "display-none"}
                            href="https://www.104.com.tw/company/1a2x6bi0nm?page=2&pageSize=20&order=undefined&asc=undefined&jobsource=n104bank1"
                            target="_blank">
                           {val.title} 
                        </a>
                    ))}
                </div>
                <div class="footer-socialIconWarp">
                    {langData.fb && <a href={`${langData.fb}`} target="_blank"><img class="footer-social-icon" src={`${fb}`} alt='fb'></img></a>}
                    {langData.ig && <a href={`${langData.ig}`} target="_blank"><img class="footer-social-icon" src={`${IG}`} alt='IG'></img></a>}
                </div>
                <StyleStoreWarp>
                    {storeData && storeData.map(val => (
                        <StyleStore>
                            <div>{val.title}</div>
                            <div>
                                <p>T : <a href={`tel:${val.html}`} target="_blank">{val.html}</a></p>
                                    {/* <img class="footer-star-icon" src={`${starWhite}`} alt='starWhite'></img> */}
                                {val.subHtml.length > 0 ?
                                    <p>E : <a href={`mailto:${val.subHtml}`} target='_blank'>{val.subHtml}</a></p>
                                :
                                    <p></p>
                                }
                                {/* <img class="footer-star-icon" src={`${starWhite}`} alt='starWhite2'></img> */}
                            </div>
                        </StyleStore>
                    ))}
                </StyleStoreWarp>
                <div class="footer-copyright-text">© 2021 Ruth's Hospitality Group</div>
            </StyleWarpper>
        );
    }
}
const reduxHook = connect(
    state => ({
        langData: state.Content.lang,
        nodeData: state.Content.nodeData,
        footerData: state.Content.footerData,
        menuData: state.Content.menuData,
        footerStore: state.Content.footerStore,
        allNodes: state.Content.allNodes,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
);

export default compose(
    reduxHook,
    withRouter,
)(Footer);