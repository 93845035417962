import axios from 'axios';
import APPCONFIG from '../config/config.js';
import { store } from './Store.js';

let instance = axios.create({
    baseURL: APPCONFIG.BackEndWebApi,
    timeout: 1200000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json-patch+json',
    },
});
if(localStorage.getItem('Langurage') === "undefined") {
    localStorage.setItem('Langurage',"null");
}
instance.interceptors.request.use((config) => {
    config.url = config.url
        .replace('TopNodeID=', `TopNodeID=${APPCONFIG.countryID}`)
        .replace('Language=', `Language=${store.getState().Content.lang.type}`);
    return config;
});

instance.interceptors.response.use((response) => {
    return response;
}, function (error) {
    alert(error);
    return Promise.reject(error);
});

export default instance;