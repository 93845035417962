import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import buttonborderHover from '../static/button-border-deepGray.png';
import buttonborder from '../static/button-border-gray.png';
import store01 from '../static/store01.jpg';
import store02 from '../static/store02.jpg';
import store03 from '../static/store03.jpg';
import store04 from '../static/store04.jpg';
import { connect } from 'react-redux';
import {
  compose,
  bindActionCreators,
} from 'redux';
import { withRouter } from 'react-router';
import APPCONFIG from '../config/config.js';
import * as indexAction from '../actions/index.js';
import SpecialBlock from '../conponents/shared/SpecialBlock';

const StyleStoreIntro = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const StyleImgBlock = styled.div`
    position: relative;
    width: 55%;
    height: 280px;
    background-image: url("${props => props.imgUrl}");
    background-size: cover;
        background-position: center;
    :before {
        content: "";
        position: absolute;
        width: calc(100% - 30px);
        height: calc(280px - 30px);
        top: 15px;
        left: 15px;
        border: 1px solid #FFF;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
    @media (max-width: 414px) {
        :before {
            width: calc(100% - 20px);
            height: calc(280px - 20px);
            top: 10px;
            left: 10px;
        }
    }
`;

const StyleTextBlock = styled.div`
    width: 43%;
    font-size: 1.5rem;
    color: #5a5a5a;
    > h3 {
        font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
        letter-spacing: -1px;
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
    }
    > p {
        margin-bottom: 0.1rem;
        word-break: break-word;
    }
    > button {
        font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
        border-image: url(${buttonborder}) 18 18 stretch;
        padding: .1rem 2rem;
        border-width: 14px;
        background: unset;
        font-size: 1rem;
        color: #c34950;
        margin-top: .2rem;
        > a {
            color: #c34950;
        }
        &:hover {
            border-image: url(${buttonborderHover}) 18 18 stretch;
          }
    }
    > p > i {
        margin-right: 0.5rem;
        display: inline-block;
    }
    > p > span {
        border-bottom: 1px solid #c34950;
    }
    > p > a {
        color: #0000ee;
    }
    > p > a.zh-tw {
        font-weight: 500;
        color: #be2f37;
    }
    @media (max-width: 1023px) {
        > h3 {
            font-size: 2rem;
            margin-bottom: 1.2rem;
        }
        font-size: 1.2rem;
    }
    @media (max-width: 768px) {
        width: 90%;
        margin: 35px auto 0;
    }
    @media (max-width: 414px) {
        > h3 {
            font-size: 1.5rem;
            margin-bottom: 1.2rem;
        }
        font-size: 1rem;
        > button {
            padding: .1rem 2rem;
            align-self: center;
            display: flex;
            margin: 1.2rem auto 0;
            border-image: url(${buttonborder}) 18 18 stretch;
            border-width: 12px;
        }
    }
`;

const data1 = [{
        id: 1,
        img: store01,
        location: 'Taipei',
        address: '2F 135 Min Sheng East Road,  Section 3,',
        address1: 'Taipei,   Taiwan',
        phone: '886-2-2545-8888',
        email: 'ez-RuthsChris@elitezone.tech',
    }, {
        id: 2,
        img: store02,
        location: 'DaiZhi, Taipei',
        address: 'No. 301,  Lequn 3rd Rd., Zhongshan District,',
        address1: 'Taipei City ,  104 Taiwan',
        phone: '886-2-8501-5900',
        email: 'ez-RuthsChris@elitezone.tech',
    }, {
        id: 3,
        img: store03,
        location: 'Taichung',
        address: '26th Floor,  367 Chung Kang Road,',
        address1: 'Section 1,   Taiwan',
        phone: '886-4-2323-2872',
        email: 'ez-RuthsChris@elitezone.tech',
    }, {
        id: 4,
        img: store04,
        location: 'Kaohsiung',
        address: 'Zhongzheng 4th Road, No. 211, 25th floor-3  ,  Qianjin District,',
        address1: 'Kaohsiung,  801 Taiwan',
        phone: '886-7-241-8888',
        email: 'ez-RuthsChris@elitezone.tech',
}];

class Reservation extends PureComponent<Props> {
    componentWillMount() {
        const {
            location: {
                state,
                pathname,
            },
            fetchNodeData,
            nodeData,
            allNodes,
            changeLanguage,
        } = this.props;
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        // if (state && !nodeData[state.route]) {
        //     fetchNodeData(state.url, state.route);
        // } else {
        //     const mainpPath = pathname.split("/");
        //     const data = APPCONFIG.langurage.find(val => val.type === mainpPath[1]);
        //     changeLanguage(data);
        //     if (allNodes.childNode && !nodeData[`/${mainpPath[2]}`]) {
        //         const thisNodeData = allNodes.childNode[0].childNode.find(val => val.route === `/${mainpPath[2]}`);
        //         fetchNodeData(`/${thisNodeData && thisNodeData.nodeId}`, thisNodeData && thisNodeData.route);  
        //     }
        // }
    }
    componentDidUpdate(preprops) {
        const {
            location: {
                pathname,
              },
            allNodes,
            fetchNodeData,
            nodeData,
        } = this.props;
        // const mainpPath = pathname.split("/");
        // if (!nodeData[`/${mainpPath[2]}`]) {
        //     if(!preprops.allNodes.childNode || !allNodes.childNode) return null;
        //     console.log('111',allNodes)
        //      const thisNodeData = allNodes.childNode[0].childNode.find(val => val.route === `/${mainpPath[2]}`);
        //      fetchNodeData(`/${thisNodeData.nodeId}`, thisNodeData.route); 
        // }
    }
   
    render() {
        const {
            nodeData,
            location: {
                state,
                pathname,
            },
            langData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[2]}`] && (!state || !nodeData[state.route])) return null;
        const responseData = nodeData[`/${mainpPath[2]}`].datas || nodeData[state.route].datas;
        //console.log('Reservation responseData : ', responseData);
        //console.log('Reservation langData : ', langData);
        return (
            <div class="content-warpper">
                <div class="content-inner-title-warpper">
                    {responseData.map(data => 
                        <StyleStoreIntro key={data.id}>
                            <StyleImgBlock imgUrl={data.url}>
                            </StyleImgBlock>
                            <StyleTextBlock>
                                <h3>{data.title}</h3>
                                <p>
                                    <i class="icon-map-marker-gray" style={{width: '13px', height:'18px'}} alt="map-marker"/>
                                    <a href={data.question} class={langData.country === "tw" ? "zh-tw" : ""} target="_blank">{data.summary}</a>
                                </p>
                                <p>
                                    <i class="icon-phone-gray" style={{width: '17px', height:'17px'}} alt="phone" />
                                    <span style={{borderBottom: 'none', marginRight: '5px', fontWeight: '600'}}>T.</span>
                                    <a href={`tel:${data.html}`} class={langData.country === "tw" ? "zh-tw" : ""} target="_blank">{data.html}</a>
                                </p>
                                {data.subHtml.length > 0 ?
                                    <p style={{whiteSpace: 'nowrap'}}>
                                        <i class="icon-email-gray" style={{width: '19px', height:'14px'}} alt="email"/>
                                        <span style={{borderBottom: 'none', marginRight: '5px', fontWeight: '600'}}>M.</span>
                                        <a href={`mailto:${data.subHtml}`} class={langData.country === "tw" ? "zh-tw" : ""} target='_blank'>{data.subHtml}</a>
                                    </p>
                                :
                                    <p></p>
                                }
                                <button class={langData.country === "jp" ? "display-none" : (data.icon ==="true" ? "display-inline-block" : "display-none")}><a href={data.address} target="_blank">{data.achor}</a></button>
                            </StyleTextBlock>
                        </StyleStoreIntro>
                    )}
                </div>
                <SpecialBlock pathname={pathname} temp={3} />
            </div>
        );
    }
}

const reduxHook = connect(
    state => ({
        nodeData: state.Content.nodeData,
        allNodes: state.Content.allNodes,
        langData: state.Content.lang,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
  );

export default compose(
    withRouter,
    reduxHook,
)(Reservation);