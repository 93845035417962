// @flow

import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';

import Content from './Content.js';
import MapData from './MapData.js';

export default combineReducers({
  form: formReducer,
  routing: routerReducer,
  Content,
  MapData,
});
