// @flow

import React, { PureComponent } from 'react';

class Textarea extends PureComponent<Props> {  
    render() {
      const {
        input: {
          value,
          onChange,
        },
        placeholder,
      } = this.props;
  
      return (
        <textarea
            class="content-input-textarea"
            placeholder={placeholder}
            type="text"
            value={value}
            onChange={e => onChange(e)} />
      );
    }
  }
  
  
  export default Textarea;
  