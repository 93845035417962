import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Slider from "react-slick";
import SpecialBlock from '../conponents/shared/SpecialBlock.jsx';
import buttonborder from '../static/button-border-deepWhite.png';
import { connect } from 'react-redux';
import {
  compose,
  bindActionCreators,
} from 'redux';
import { withRouter } from 'react-router';
import APPCONFIG from '../config/config.js';
import * as indexAction from '../actions/index.js';


const StyleSliderWarp = styled.div`
    box-sizing: border-box;
    background: linear-gradient(to bottom, #f3f3eb 0%,#f3f3eb 20%,#e8e6da 20%,#e8e6da 80%,#f3f3eb 80%,#f3f3eb 100%);
    @media (max-width: 1023px) {
        background: linear-gradient(to bottom, #f3f3eb 0%,#f3f3eb 45%,#e8e6da 45%,#e8e6da 90%,#f3f3eb 90%,#f3f3eb 100%);
      }
`;

const StyleSlider = styled(Slider)`
    width: 1160px;
    box-sizing: border-box;
    padding: 20px 0 40px;
    margin: 0 auto;
    @media (max-width: 1366px) {
        width: 90%;
    }
`;

const StyleBanner = styled.div`
    position: relative;
    height: 550px;
    width: 90%;
    background-image: url("${props => props.imgUrl}");
    ${props => props.isMiddle && css`
        background-image: url("${props => props.imgUrl}");
    `}
    background-size: 140%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30px;
    margin: 0 auto;
    box-sizing: border-box;
    color: #FFF;
    outline: none;
    transition: all .3s ease-in;
    word-break: break-word;
    @media (max-width: 1280px) {
        padding: 30px 15px;
      }
    @media (max-width: 1023px) {
        width: 50%;
        height: 500px;
        margin: 20px auto;
        padding: 40px 15px;
      }
    @media (max-width: 768px) {
        width: 70%;
    }
    @media (max-width: 540px) {
        width: 98%;
        height: 350px;
        padding: 30px 15px;
    }
    :after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0;
        background-color: rgba(90,90,90,.9);
        ${props => props.isMiddle && css`
        background-color: rgba(190,47,55,.9);
        transition: all .3s ease-in;
    `}
    }
    :before {
        content: '';
        position: absolute;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        z-index: 2;
        top: 15px;
        left: 15px;
        border: 15px solid hsla(0,0%,100%,.5);
        border-image: url("${buttonborder}") 18 18 stretch;
        box-sizing: border-box;
        @media (max-width: 540px) {
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            top: 10px;
            left: 10px;
        }
    }
    > h3 {
        font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
        letter-spacing: -1px;
        position: relative;
        max-width: 85%;
        margin: 1.3rem auto .5em;
        letter-spacing: -1px;
        font-weight: 800;
        font-size: 2.2rem;
        text-align: center;
        z-index: 2;
        @media (max-width: 1280px) {
            font-size: 1.8rem;
          }
        @media (max-width: 450px) {
            font-size: 1.5rem;
        }
    }
    > p {
        position: relative;
        z-index: 2;
        max-width: 85%;
        font-size: 1.25rem;
        text-align: justify;
        text-align-last: center;
        margin: 0 auto;
        line-height: 1.8rem;
        letter-spacing: 1px;
        @media (max-width: 1400px) {
            font-size: 1.2rem;
            line-height: 1.6rem;
        }
        @media (max-width: 414px) {
            font-size: .9rem;
            line-height: 1.4rem;
        }
    }
    &:hover {
        background-size: 145%;
        transition: all .3s ease-in;
        :after {
            background-color: rgba(90,90,90,.4);
        ${props => props.isMiddle && css`
        background-color: rgba(90,90,90,.4);
        `}
        } 
    }
`;

const StyleBannerEn = styled.div`
    position: relative;
    height: 550px;
    width: 90%;
    background-image: url("${props => props.imgUrl}");
    ${props => props.isMiddle && css`
        background-image: url("${props => props.imgUrl}");
    `}
    background-size: 140%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30px;
    margin: 0 auto;
    box-sizing: border-box;
    color: #FFF;
    outline: none;
    transition: all .3s ease-in;
    word-break: break-word;
    @media (max-width: 1280px) {
        padding: 30px 15px;
      }
    @media (max-width: 1023px) {
        width: 50%;
        height: 500px;
        margin: 20px auto;
        padding: 40px 15px;
      }
    @media (max-width: 768px) {
        width: 70%;
    }
    @media (max-width: 540px) {
        width: 98%;
        height: 350px;
        padding: 30px 15px;
    }
    :after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0;
        background-color: rgba(90,90,90,.9);
        ${props => props.isMiddle && css`
        background-color: rgba(190,47,55,.9);
        transition: all .3s ease-in;
    `}
    }
    :before {
        content: '';
        position: absolute;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        z-index: 2;
        top: 15px;
        left: 15px;
        border: 15px solid hsla(0,0%,100%,.5);
        border-image: url("${buttonborder}") 18 18 stretch;
        box-sizing: border-box;
        @media (max-width: 540px) {
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            top: 10px;
            left: 10px;
        }
    }
    > h3 {
        font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
        letter-spacing: -1px;
        position: relative;
        max-width: 85%;
        margin: 1.3rem auto .5em;
        font-weight: 800;
        font-size: 1.8rem;
        text-align: center;
        z-index: 2;
        @media (max-width: 1400px) {
            font-size: 1.8rem;
          }
        @media (max-width: 450px) {
            font-size: 1.5rem;
        }
    }
    > p {
        position: relative;
        z-index: 2;
        max-width: 85%;
        font-size: 1.3rem;
        margin: 0 auto;
        line-height: 1.8rem;
        @media (max-width: 414px) {
            font-size: 1rem;
            line-height: 1.2rem;
        }
    }
    &:hover {
        background-size: 145%;
        transition: all .3s ease-in;
        :after {
            background-color: rgba(90,90,90,.4);
        ${props => props.isMiddle && css`
        background-color: rgba(90,90,90,.4);
        `}
        } 
    }
`;

const StyleLink = styled(Link)`
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    position: absolute;
    z-index: 2;
    color: #fff;
    text-align: center;
    left: 50%;
    bottom: 6rem;
    font-size: 1.25rem;
    text-transform: uppercase;
    text-decoration: none;
    transform: translateX(-50%);
    border-bottom: 1px solid;
    @media (max-width: 1280px) {
        font-size: 1.2rem;
    }
    @media (max-width: 450px) {
        bottom: 3rem;
    }
`;

const StyleControl = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8rem 0 4rem;
    position: unset;
    .slick-active > button {
        background: #b30013;
        color: #FFF;
    }
    button {
        background: unset;
        border: unset;
        color: #b30013;
        font-size: 1.5em;
        outline: 0;
        margin: 0 1.4rem;
        cursor: pointer;
    }
`;

const StyleDot = styled.div`
    > button {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #b30013;
        color: #b30013;
        font-size: 1.2em;
        margin: 0 0.6rem;
    }
    
`;

class Promotions extends PureComponent {
    constructor() {
        super();
        this.slider = React.createRef();
    }

    componentWillMount() {
        const {
            location: {
                state,
                pathname,
            },
            fetchNodeData,
            nodeData,
            allNodes,
        } = this.props;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        const mainpPath = pathname.split("/");
        if (state) {
            // if(!nodeData[state.route]) {
            //     fetchNodeData(state.url, state.route);            
            // }
        } else {
            // if (allNodes) {
            //     if(!nodeData[`/${mainpPath[2]}`]) {
            //         const thisNodeData = allNodes.childNode && allNodes.childNode[0].childNode.find(val => val.route === `/${mainpPath[2]}`);
            //         if(!thisNodeData) return null;
            //         fetchNodeData(`/${thisNodeData.nodeId}`, thisNodeData.route);
            //     }
            // }
        }
    }

    componentDidUpdate(preprops) {
        const {
            location: {
                pathname,
                state,
              },
            allNodes,
            fetchNodeData,
            setLastUrl,
            nodeData,
            footerData,
        } = this.props;
        const mainpPath = pathname.split("/");
        // setLastUrl(state ? state : pathname);
    }

    render() {
        const {
            nodeData,
            location: {
                state,
                pathname,
            },
            langData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[2]}`]) return null;
        const responseData = nodeData[`/${mainpPath[2]}`] || nodeData[state.route];
        return (
            <div class="content-warpper">
                <div>
                    <div class="promotions-title-warpper">
                        <div class="content-title">{responseData.title}</div>
                        <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>
                        {responseData.summary}
                        </div>
                    </div>
                    <StyleSliderWarp>
                        <StyleSlider
                            ref={this.slider}
                            dots={true}
                            appendDots={(dots) => {
                                return (
                                    <StyleControl>
                                        <button onClick={() => this.slider.current.slickPrev()}>{'＜'}</button>
                                        {dots.map((dot) => (
                                            <StyleDot
                                                {...dot.props}
                                                key={dot.key}
                                            >{dot.props.children}</StyleDot>
                                        ))}
                                        <button onClick={() => this.slider.current.slickNext()}>{'＞'}</button>
                                    </StyleControl>
                                );
                            }}
                            arrows={false}
                            infinite={true}
                            speed={500}
                            slidesToShow={responseData.datas.length >= 3 ? 3 : responseData.datas.length}
                            slidesToScroll={responseData.datas.length >= 3 ? 3 : responseData.datas.length}
                            responsive={[{
                                breakpoint: 1023,
                                settings: {
                                    slidesPerRow: 1,
                                    rows: 3,
                                    slidesToScroll: 1,
                                    slidesToShow: 1,
                                }
                            }]}>
                            {responseData.datas.map((data, index) => (
                                <div key={data.id}>
                                    {langData.type === "zh-TW" ? 
                                    <StyleBanner imgUrl={data.url} isMiddle={(index % 3) === 1}>
                                        <h3>{data.title}</h3>
                                        <p>{data.summary}</p>
                                        {/* <StyleLink to={{ pathname: `${data.route}`, state: {...data} }}>more</StyleLink> */}
                                        <StyleLink to={{ pathname: `/${langData.type}/${mainpPath[2]}/${data.nodeId}`, state: {...data} }}>{data.subHtml}</StyleLink>
                                    </StyleBanner> :
                                    <StyleBannerEn imgUrl={data.url} isMiddle={(index % 3) === 1}>
                                        <h3>{data.title}</h3>
                                        <p>{data.summary}</p>
                                        {/* <StyleLink to={{ pathname: `${data.route}`, state: {...data} }}>more</StyleLink> */}
                                        <StyleLink to={{ pathname: `/${langData.type}/${mainpPath[2]}/${data.nodeId}`, state: {...data} }}>{data.subHtml}</StyleLink>
                                    </StyleBannerEn>}
                                </div>
                            ))}
                        </StyleSlider>
                    </StyleSliderWarp>
                </div>
                <SpecialBlock pathname={pathname} temp={1} />
            </div>
        );
    }
}


const reduxHook = connect(
    state => ({
        nodeData: state.Content.nodeData,
        allNodes: state.Content.allNodes,
        langData: state.Content.lang,
        footerData: state.Content.footerData,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
  );

export default compose(
    withRouter,
    reduxHook,
)(Promotions);