import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as indexAction from '../actions/index.js';
import APPCONFIG from '../config/config.js';

import Logo from './../static/ruthschris_global_logo.png';
import userCircleWhite from './../static/user-circle-solid.png';
import HeaderTop from './HeaderTop.jsx';

class NavList3 extends PureComponent<Props> {
    constructor(props) {
        super(props);
        this.state = {
            menuVisible: false,
        };
    }


    render() {
        const {
            location: {
                pathname,
            },
            menuData,
            langData,
        } = this.props;

        const {
            menuVisible,
        } = this.state;

        const reservations = menuData.find(val => val.route === '/reservations');

        return (
            <div>
                {/* <HeaderTop menuVisible={menuVisible} /> */}
                <div class="home3-nav-list-wrap">
                    <Link alt="HOME" to={`/${langData.type}`} pathname={pathname}>
                        <img class="nav-logo" src={`${Logo}`} alt="logo" ></img>
                    </Link>
                    <div class={`nav ${menuVisible ? 'is-active' : ''}`}>
                        {menuData.map(val => val.route !== '/reservations' ? (
                        <Link 
                            class="home3-nav-button" 
                            key={val.route} 
                            onClick={() => {
                                this.setState({
                                    menuVisible: !menuVisible,
                                })}} 
                            to={{ pathname: `/${langData.type}${val.route}`, state: {...val} }}>
                                {val.title}
                        </Link>
                        ) : null)}

                        <Link class="home3-nav-reservations" alt="RESERVATIONS" to={{ pathname: `/${langData.type}${reservations && reservations.route}`, state: {...reservations} }}>
                            <div class="icon-dateIcon-white"/>
                            <span>{langData.bookingName}</span>
                        </Link>
                    </div>
                    <Link class="nav-mobile-reservations" alt="RESERVATIONS" to={{ pathname: `/${langData.type}${reservations && reservations.route}`, state: {...reservations} }}>{langData.bookingName}</Link>
                    <div className={`hamburger ${menuVisible ? 'is-active' : ''}`}
                        onClick={() => {
                            this.setState({
                                menuVisible: !menuVisible,
                            })
                        }}>
                        <div className="hamburger-box">
                            <div className="hamburger-inner" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const reduxHook = connect(
    state => ({
        menuData: state.Content.menuData,
        langData: state.Content.lang,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
);
export default compose(
    withRouter,
    reduxHook,
)(NavList3);