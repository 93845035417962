import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import {
  compose,
  bindActionCreators,
} from 'redux';
import * as indexAction from '../actions/index.js';
import APPCONFIG from '../config/config.js';
import { withRouter } from 'react-router';



class AboutUs extends PureComponent<Props> {
    componentWillMount() {
        const {
            location: {
                state,
                pathname,
            },
            fetchNodeData,
            nodeData,
            allNodes,
        } = this.props;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        // if (state) {
        //     if(!nodeData[state.route]) {
        //         fetchNodeData(state.url, state.route);
        //     } 
        // } else {
        //     const mainpPath = pathname.split("/");
        //     if (allNodes && !nodeData[`/${mainpPath[2]}`]) {
        //         const thisNodeData = allNodes.childNode && allNodes.childNode[0].childNode.find(val => val.route === `/${mainpPath[2]}`);
        //         if(!thisNodeData) return null;
        //         fetchNodeData(`/${thisNodeData.nodeId}`, thisNodeData.route);  
        //     }
        // }
    }

    render() {
        const {
            nodeData,
            location: {
                state,
                pathname,
            },
        } = this.props;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[2]}`]) return null;
        const responseData = nodeData[`/${mainpPath[2]}`] || nodeData[state.route];
        return (
            <div>
                <div dangerouslySetInnerHTML={{ __html: responseData.html }} /> 
                <ReactPlayer
                    class="about-us-video-size"
                    width="100%"
                    height="56vw"
                    url={responseData.url}
                    loop={true}
                    controls={true}
                /> 
            </div>
        );
    }
}

const reduxHook = connect(
    state => ({
        nodeData: state.Content.nodeData,
        allNodes: state.Content.allNodes,
        langData: state.Content.lang,
        footerData: state.Content.footerData,
        menuData: state.Content.menuData,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
  );

export default compose(
    withRouter,
    reduxHook,
)(AboutUs);