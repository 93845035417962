import React, { PureComponent } from 'react';
// import styled from 'styled-components';

class HeaderTop extends PureComponent<Props> {
    render() {
        const { menuVisible } = this.props;
        return (
            <div class={`header-top-wrap ${menuVisible ? 'is-active' : ''}`}>
                <div class="header-top-text">FIND YOUR RUTH'S CHRIS</div>
                <div class="header-top-arrow-icon">
                    <i class="icon-arrow-red" />
                </div>
            </div>
        );
    }
}
export default HeaderTop;