import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux';
import { bindActionCreators, compose, combineReducers } from 'redux';
import * as IndexAction from '../actions/index';
import APPCONFIG from '../config/config.js';

import SpecialBlock from '../conponents/shared/SpecialBlock.jsx';

import food_detail01 from '../static/food-detail-01.png';
import food_detail02 from '../static/food-detail-02.jpg';
import food_detail03 from '../static/food-detail-03.svg';
import cow from '../static/food-detail-03.svg';
import { stat } from 'fs';

class FoodMenu1 extends PureComponent<Props> {
    componentWillMount() {
        const {
            match: {
                params: {
                    mainId,
                    subId,
                }            
            },
            location: {
                state,
                pathname,
            },
            fetchNodeData,
            nodeData,
            history,
            getFoodDetailData,
            foodDetailHtmlData,
            foodDetailHtml,
            getFoodDetailHtml,
            langData,
            changeLanguage,
            allNodes,
        } = this.props;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera            
        if (!state) {
            const mainpPath = pathname.split("/");
            if (!nodeData[`/${mainpPath[2]}`]){
                // if(allNodes) {
                //     const thisNodeData = allNodes.childNode && allNodes.childNode[0].childNode.find(
                //         val => val.route === '/food-drink'
                //        );
                //     if(!thisNodeData) return null;
                //     const mainNodeData = thisNodeData.childNode.find(val => val.route === `/${mainpPath[2]}`);
                //     fetchNodeData(`/${mainNodeData.nodeId}`, mainNodeData.route);
                // }
            } else {
                if(foodDetailHtmlData && getFoodDetailData[`${mainpPath[1]}`]) {
                    const isFoodDetailData = getFoodDetailData[`${mainpPath[1]}`].find(val => val.url === `/${mainId}/${subId}`);
                    if(isFoodDetailData) {
                        const matches = document.getElementsByClassName('html');
                        const isTagName = matches[0];
                        getFoodDetailHtml(isTagName ,isFoodDetailData.title);
                    } else {
                        history.replace(`/${mainpPath[1]}/${mainpPath[2]}`);
                    } 
                }
            }
        } else {
            const matches = document.getElementsByClassName('html');
            const isTagName = matches[0];
            getFoodDetailHtml(isTagName ,state.title);
        } 
    }

    componentDidUpdate(preprops) {
        const {
            match: {
                params: {
                    mainId,
                    subId,
                }            
            },
            location: {
                state,
                pathname,
            },
            foodDetailHtmlData,
            getFoodDetailHtml,
            langData,
            getFoodDetailData,
            nodeData,
            getDetailListNum,
            detailListNum,
            history,
        } = this.props;
        const mainpPath = pathname.split("/");
        if(state) {
            if(!getFoodDetailData[langData.type]) {
                if(nodeData[`/${mainpPath[2]}`]) {
                    this.fetchFoodDetailData();
                }
            } else {
                const isFoodDetailData = getFoodDetailData[`${mainpPath[1]}`].find(val => val.url === `/${mainId}/${subId}`);
                const matches = document.getElementsByClassName('html');
                const isTagName = matches[0];
                if(!foodDetailHtmlData[isFoodDetailData.title]) {
                    getFoodDetailHtml(isTagName , isFoodDetailData.title);
                }
                if(detailListNum === null) {
                    const isNum = getFoodDetailData[langData.type].indexOf(getFoodDetailData[langData.type].find(val => val.url === `/${mainId}/${subId}`));
                    getDetailListNum(isNum);
                } else {
                    const isNum = getFoodDetailData[langData.type].indexOf(getFoodDetailData[langData.type].find(val => val.url === `/${mainId}/${subId}`));
                    getDetailListNum(isNum);
                }
            }
            
        } else {
            if(!getFoodDetailData[langData.type]) {
                if(nodeData[`/${mainpPath[2]}`]) {
                    this.fetchFoodDetailData();
                }
            } else {
                if(detailListNum === null) {
                    const isNum = getFoodDetailData[langData.type].indexOf(getFoodDetailData[langData.type].find(val => val.url === `/${mainId}/${subId}`));
                    if(isNum === -1) {
                        history.replace(`/${mainpPath[1]}/${mainpPath[2]}`);
                    } else {
                        getDetailListNum(isNum);
                    }  
                } else {
                    if((langData !== preprops.langData ) || (preprops.nodeData !== nodeData)){
                        if(getFoodDetailData[langData.type][detailListNum]) {
                            const isUrl = getFoodDetailData[langData.type][detailListNum].url;
                            if(isUrl) {
                                history.replace(`/${mainpPath[1]}/${mainpPath[2]}${isUrl}`);
                            } 
                        } else {
                            history.replace(`/${mainpPath[1]}/${mainpPath[2]}`);
                        }
                    }
                }
                const isFoodDetailData = getFoodDetailData[`${mainpPath[1]}`].find(val => val.url === `/${mainId}/${subId}`);
                const matches = document.getElementsByClassName('html');
                const isTagName = matches[0];
                if(!isFoodDetailData) return null;
                if(!foodDetailHtmlData[isFoodDetailData.title]) {
                    getFoodDetailHtml(isTagName , isFoodDetailData.title);
                }
            }
        };
    }

    fetchFoodDetailData() {
        const {
            location: {
                pathname,
            },
            nodeData,
            getFoodDetail,
            langData,
        } = this.props;
        let selectList = [];
        const mainpPath = pathname.split("/");
        const responseData = nodeData[`/${mainpPath[2]}`] && nodeData[`/${mainpPath[2]}`].datas;
        responseData && responseData.filter(
            val => {
                if(val.datas.length) {
                    return val.datas.filter(
                        x => {
                            if(x.datas) {
                                return x.datas.filter(
                                    y => {
                                        if(y.url)
                                        {
                                            return selectList.push(y);
                                        }
                                    }
                                )
                            }
                        }
                    )      
                }  
            }
        );
        getFoodDetail(selectList, langData.type);
    }

    goTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera  
    }
    
    render() {
        const {
            match: {
                params: {
                    mainId,
                    subId,
                }            
            },
            getFoodDetailData,
            history,
            location: {
                pathname,
                state,
            },
            langData,
            foodDetailHtmlData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if (!getFoodDetailData[langData.type]) return null;
        const isFoodDetailData = getFoodDetailData[`${mainpPath[1]}`].find(val => val.url === `/${mainId}/${subId}`);
        if (!isFoodDetailData) return null;
        const foodDetailData = getFoodDetailData[langData.type].find(data => data.url === `/${mainId}/${subId}`);
        const foodDetailHtml = foodDetailData && foodDetailData.html.split("</a>");
        const main = getFoodDetailData[langData.type].indexOf(foodDetailData);
        const Previous = main - 1 < 0 ? getFoodDetailData[langData.type][getFoodDetailData[langData.type].length - 1] : getFoodDetailData[langData.type][main - 1];
        const next = (main + 1 === getFoodDetailData[langData.type].length) ? getFoodDetailData[langData.type][0] : getFoodDetailData[langData.type][main + 1];
        const tagNameA = foodDetailHtmlData && foodDetailHtmlData[isFoodDetailData.title] && foodDetailHtmlData[isFoodDetailData.title].getElementsByTagName('a');
        const isTagNameA = tagNameA && Object.values(tagNameA).filter(x => x.className === "");
        if(isTagNameA) {
            if(isTagNameA[0]) {isTagNameA[0].style = "display:none";}
            if(isTagNameA[1]) {isTagNameA[1].style = "display:none";}
        } 
        return (
            <div class="content-warpper">
                <Link
                onClick={() => this.goTop()}  
                class="food-backTop-prevpage" 
                to={{ pathname: `/${langData.type}/${mainpPath[2]}`}}>
                    <span>
                        <i class='icon-arrow-red'></i>
                    </span>
                    {langData.goBackFood}
                </Link>
                    
                <div class="gray-line food-detail-line"></div>
                <div class={langData.type === "zh-TW" ? "food-detail-warpper" : "en-food-detail-warpper"}>
                    {foodDetailHtml && foodDetailHtml.length ? 
                    <span class='html'>
                        <div dangerouslySetInnerHTML={{ __html: foodDetailHtml[0] }} />
                        {(isTagNameA && isTagNameA.length && isTagNameA[0]) ? <Link class="content-food-bt" to={`/${langData.type}${isTagNameA[0].pathname}${isTagNameA[0].hash}`}>{isTagNameA[0].innerHTML}</Link> : null}
                        <div dangerouslySetInnerHTML={{ __html: foodDetailHtml[1] }} />
                        {(isTagNameA && isTagNameA.length && isTagNameA[1]) ? <Link class="content-food-bt" to={`/${langData.type}${isTagNameA[1].pathname}${isTagNameA[1].hash}`}>{isTagNameA[1].innerHTML}</Link> : null}
                        <div dangerouslySetInnerHTML={{ __html: foodDetailHtml[2] }} />
                    </span>
                     : null}
                </div>
                {foodDetailData && (foodDetailData.question || foodDetailData.answer) && <div class="foodDetail-special-area">
                    <img class="foodDetail-icon" src={`${cow}`} alt="icon"/>
                    <div class="content-sub-innner-warp">
                        {foodDetailData.question && <h1 style={{color: '#5a5a5a'}}>{foodDetailData.question}</h1>}
                    </div>
                    {foodDetailData.answer && <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"} style={{color: '#8f8f8f'}}>{foodDetailData.answer}</div>}
                </div>}
                <div class="content-inner-warpper food-inner-site">
                    <div class="food-selection-left">
                        <div className="food-detail-text-italic">{langData.previous}</div>
                        {/* <Link to={{ pathname: `/food${Previous.url}`, state: {...Previous} }}> */}
                        {Previous && 
                        <Link 
                        onClick={() => this.goTop()} 
                        to={{ pathname: `/${langData.type}/${mainpPath[2]}${Previous.url}`, state: {...Previous} }}>
                            <span>
                                <i className='icon-arrow-red'></i>
                            </span>
                            <span dangerouslySetInnerHTML={{ __html: Previous.title }} />
                        </Link>  }
                    </div>
                    <div class="gray-line display-food-none"></div>
                    <div class="food-block-style"></div>
                    <div class="food-selection-right">
                        <div class="food-detail-text-italic">{langData.next}</div>
                        {/* <Link to={{ pathname: `/food${next.url}`, state: {...next} }}>  */}
                        {next && 
                        <Link
                        onClick={() => this.goTop()} 
                        to={{ pathname: `/${langData.type}/${mainpPath[2]}${next.url}`, state: {...next} }}> 
                            <span dangerouslySetInnerHTML={{ __html: next.title }} /> 
                            <span>
                                <i class='icon-arrow-red'></i>
                            </span>
                        </Link>}
                    </div>
                </div> 
                <SpecialBlock pathname={pathname} temp={1} /> 
            </div>
        );
    }
}

const reduxHook = connect(
    state => ({
        nodeData: state.Content.nodeData,
        getFoodDetailData: state.Content.getFoodDetailData,
        allNodes: state.Content.allNodes,
        langData: state.Content.lang,
        foodDetailHtmlData: state.Content.foodDetailHtmlData,
        detailListNum: state.Content.detailListNum,
    }),
    dispatch => bindActionCreators({
        ...IndexAction,
    }, dispatch),
);

export default compose(
    withRouter,
    reduxHook,
)(FoodMenu1);