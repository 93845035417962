import React, { PureComponent } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  compose,
  bindActionCreators,
} from 'redux';
import { withRouter } from 'react-router';
import * as indexAction from '../actions/index.js';

const StyleWarpper = styled.div`
    width: 1160px;
    margin: 80px auto 100px;
    @media (max-width: 1366px) {
        width: 90%;
    }
    @media (max-width: 414px) {
        margin: 60px auto;
    }
`;

const StyleItem = styled.div`
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1.45px solid #d2d2d2;
`;

const StyleTitle = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    cursor: pointer;
    letter-spacing: 1px;
    font-size: 1.5rem;
    font-weight: 700;
    > i {
        width: 26px;
        height: 20px;
        transition: all .3s ease-in;
        transform: rotate(-180deg);
    }
    ${props => props.theme.isShow && css`
        > i {
            transform: rotate(0deg);
        }
    `}
    @media (max-width: 1280px) {
        font-size: 1.2rem;
    }
    @media (max-width: 414px) {
        font-size: 1rem;
        line-height: 1.2rem;
        padding-bottom: 10px;
        padding-right: 10px;
        > i {
            width: 16px;
            height: 12px;
        }
    }
`;

const StyleTitleEn = styled.div`
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    cursor: pointer;
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 700;
    > i {
        width: 26px;
        height: 20px;
        transition: all .3s ease-in;
        transform: rotate(-180deg);
    }
    ${props => props.theme.isShow && css`
        > i {
            transform: rotate(0deg);
        }
    `}
    @media (max-width: 414px) {
        padding-bottom: 10px;
        > i {
            width: 16px;
            height: 12px;
        }
    }
    @media (max-width: 414px) {
        font-size: 1rem;
        line-height: 1.2rem;
    }
`;

const StyleQName = styled.div`
    width: 100%;
    color: #5a5a5a;
    @media (max-width: 414px) {
        padding-right: 5px;
    }
`;

const StyleContent = styled.div`
    height: 0;
    transition: height .3s ease-out .2s, opacity .2s ease-in;
    padding: 0 90px 40px;
    color: #7f7f7d;
    font-size: 1.4rem;
    text-align: justify;
    overflow: hidden;
    opacity: 0;
    letter-spacing: 1px;
    ${props => props.theme.isShow && css`
        height: ${props => props.theme.isShow.height}px;
        padding: 0 90px 40px;
        opacity: 1;
        transition: height .3s ease-out, opacity .2s ease-in .3s;
  `}
  a {
      color: #be2f37;
      margin-top: 20px;
      display: block;
      &:hover {
        text-decoration: underline; 
      }
  }
  @media (max-width: 1280px) {
    font-size: 1.2rem;
    padding: 0 50px 40px;
    }
  @media (max-width: 414px) {
    line-height: 1rem;
    padding: 0 20px 24px;
    font-size: .8rem;
    }
`;

const StyleContentEn = styled.div`
    height: 0;
    transition: height .3s ease-out .2s, opacity .2s ease-in;
    padding: 0 90px 40px;
    color: #7f7f7d;
    font-size: 1.3rem;
    line-height: 1.8rem;
    overflow: hidden;
    opacity: 0;
    ${props => props.theme.isShow && css`
        height: ${props => props.theme.isShow.height}px;
        padding: 0 90px 40px;
        opacity: 1;
        transition: height .3s ease-out, opacity .2s ease-in .3s;
  `}
  a {
      color: #be2f37;
      margin-top: 20px;
      display: block;
      &:hover {
        text-decoration: underline; 
      }
  }
  @media (max-width: 1280px) {
    padding: 0 50px 40px;
    }
  @media (max-width: 414px) {
    line-height: 1rem;
    padding: 0 20px 24px;
    font-size: 1rem;
    line-height: 1.2rem;
    }
`;


class FAQ extends PureComponent<Props> {
    constructor(props) {
        super(props);
        this.state = {
            showAns: [],
        };
    }

    componentWillMount() {
        const {
            location: {
                state,
                pathname,
            },
            fetchNodeData,
            nodeData,
            allNodes,
        } = this.props;
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    changeShowAns(isShow, id) {
        const { showAns } = this.state;
        if (isShow) {
            this.setState({
                showAns: [
                    ...showAns.filter(data => data.id !== id),
                ],
            });
        } else {
            const ansDiv = document.getElementById(`Ans_${id}`);
            ansDiv.style.height = 'unset';
            const height = ansDiv.offsetHeight;
            ansDiv.removeAttribute('style');
            setTimeout(() => {
                this.setState({
                    showAns: [
                        ...showAns,
                        {
                            id,
                            height,
                        },
                    ]
                });
            }, 50);
        }
    }

    render() {
        const {
            nodeData,
            footerData,
            location: {
                state,
                pathname,
            },
            langData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[2]}`]) return null;
        const responseData = nodeData[`/${mainpPath[2]}`] || nodeData[state.route];
        const {
            showAns,
        } = this.state;
        const contact = footerData.find(val => val.route === '/contact-us');
        return (
            <StyleWarpper>
                {responseData.datas.map(data => {
                    const isShow = showAns.find(ans => ans.id === data.id);
                    const theme = {
                        isShow,
                    }
                    return (
                        <ThemeProvider key={data.id} theme={theme}>
                            <StyleItem>
                                {langData.type === "zh-TW" ? 
                                <StyleTitle onClick={() => this.changeShowAns(isShow, data.id)}>
                                    <StyleQName>{data.title}</StyleQName>
                                    <i className="icon-arrow-red"  />
                                </StyleTitle> 
                                : 
                                <StyleTitleEn onClick={() => this.changeShowAns(isShow, data.id)}>
                                <StyleQName>{data.title}</StyleQName>
                                <i className="icon-arrow-red"  />
                            </StyleTitleEn>
                            }
                                {langData.type === "zh-TW" ? 
                                <StyleContent
                                    id={`Ans_${data.id}`}>
                                        <div dangerouslySetInnerHTML={{ __html: data.summary}} />
                                        {data.url && (
                                            <strong>
                                                <a href={`${data.url}`} target="_blank">
                                                {data.html}
                                                </a>
                                            </strong>
                                        )}
                                </StyleContent> 
                                :
                                <StyleContentEn
                                    id={`Ans_${data.id}`}>
                                        <div dangerouslySetInnerHTML={{ __html: data.summary}} />
                                        {data.url && (
                                            <strong>
                                                <a href={`${data.url}`} target="_blank">
                                                {data.html}
                                                </a>
                                            </strong>
                                        )}
                                </StyleContentEn>}
                            </StyleItem>
                        </ThemeProvider>
                    );
                })}
                 <div class="content-inner-bottom-warpper">
                    <Link 
                        to={{ pathname: `/${langData.type}${contact && contact.route}`, state: {...contact} }}
                        class="content-gray-bt" >
                            {responseData.summary}
                    </Link>
                </div>
            </StyleWarpper>
        );
    }
}

const reduxHook = connect(
    state => ({
        nodeData: state.Content.nodeData,
        footerData: state.Content.footerData,
        allNodes: state.Content.allNodes,
        langData: state.Content.lang,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
  );

export default compose(
    withRouter,
    reduxHook,
)(FAQ);