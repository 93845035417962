import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import {
  compose,
  bindActionCreators,
} from 'redux';
import * as indexAction from '../actions/index.js';
import Slider from "react-slick";
import icon1 from '../static/icon1.svg';
import icon2 from '../static/icon2.svg';
import icon3 from '../static/icon3.svg';
import icon4 from '../static/icon4.svg';
import './../static/css/banner.css';

const StyleBanner = styled.div`
    position: relative;
    width: 100%;
    background-image: url('${props => props.imgUrl}');
    height: 700px;
    background-size: cover;
    background-position: center;
    @media (max-width: 1400px) {
        height: 550px;
    }
    @media (max-width: 768px) {
        height: 336px;
    }
    @media (max-width: 414px) {
        height: 200px;
    }
`;
// const StyleBanner = styled.div`
//     position: relative;
//     width: 100%;
// `;

const StyleBannerImg = styled.img`
    width: 100%;
`;


class Header extends Component<Props> {
    getHeaderIcon(number) {
        switch (number) {
            case "1":
                // 雕花
                return <img class="header-icon" src={`${icon1}`} alt="icon"/>;
            case "2":
                // 禮物盒
                return <img class="header-icon" src={`${icon2}`} alt="icon"/>;
            case "3":
                // 牛排
                return <img class="header-icon" src={`${icon3}`} alt="icon"/>;
            case "4":
                // 酒杯
                return <img class="header-icon" src={`${icon4}`} alt="icon"/>;
            default:
                // 雕花
                return null;
        }
    }


    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        const {
            match: {
                params: {
                    mainId,
                    subId,
                }            
            },
            match,
            nodeData,
            location: {
                pathname,
                state,
            },
            location,
            getFoodDetailData,
            foodDetailUrl,
            langData,
        } = this.props;
        const mainpPath = pathname.split("/");
        // if (!`/${mainpPath[2]}`) return null;
        
        let responseData = nodeData[`/${mainpPath[2]}`];
        if (mainpPath[0] === '' && !mainpPath[2]) {
            responseData = nodeData[`/${langData.type}`];
        }
        if (mainId && !subId) {
            responseData = nodeData[`/${mainpPath[2]}`] && nodeData[`/${mainpPath[2]}`].datas.find(val => val.nodeId === `${mainId}`);
        }
        // if ((state && (state.route === "/food-detail")) || (pathname === `/food${foodDetailUrl}` && pathname !== "/food")) {
        if (mainId && subId) {
            // responseData = getFoodDetailData.length && getFoodDetailData.find(val => val.url === (foodDetailUrl || state.url));
            responseData = getFoodDetailData[langData.type] && getFoodDetailData[langData.type].length && getFoodDetailData[langData.type].find(val => val.url === `/${mainId}/${subId}`);
        }
        if (!responseData) return null;
        return (
            responseData.route !== '/online-booking' ?
            <div class="header-warpper">
                {responseData.isVideo ?
                    <Slider {...settings}>
                        {responseData.banners.map(data => (
                            <div class="header-video-wrap">
                                <div class="overlay"></div>
                                    <div class="header-container">
                                        {this.getHeaderIcon(data.icon)}
                                        {data.title && <div class="header-title">{data.title}</div>}
                                        {data.summary && <div class="header-inner-text" dangerouslySetInnerHTML={{ __html: data.summary }}/>}
                                    </div>
                                    <ReactPlayer
                                    // class="video-size"
                                    width="100%"
                                    height="56vw"
                                    url={data.url}
                                    // url={'https://www.youtube.com/watch?v=LTx8ZE8NuQ8'}
                                    loop={true}
                                    playing={true}
                                    muted={true}
                                    config={{
                                        youtube: {
                                          playerVars: { modestbranding: 1 }
                                        }
                                      }}
                                    //youtubeConfig={{ playerVars: { showinfo: 1,modestbranding: 1,autoplay: 1, } }}
                                    />
                                    {/* <video class="video-size" loop="true" autoplay="autoplay" controls={false} id="vid" muted>
                                    <source type="video/mp4" src={data.url}></source>  
                                    </video>  */}
                            </div>
                        ))}
                         
                    </Slider>
                :
                    <Slider {...settings}>
                        {responseData.banners.map(data => (
                            <StyleBanner key={data} imgUrl={data.url}>
                                <div class="overlay"></div>
                                <div class="header-container">
                                    {this.getHeaderIcon(data.icon)}
                                    {data.title && <div class="header-title">{data.title}</div>}
                                    {data.summary && <div class="header-inner-text" dangerouslySetInnerHTML={{ __html: data.summary }}/>}
                                </div>
                                {/* <StyleBannerImg src={data.url}></StyleBannerImg> */}
                                {/* <StyleBannerImg src={data.url}></StyleBannerImg> */}
                            </StyleBanner> 
                        ))}
                    </Slider>  
                 } 
            </div>
            :
            <div></div>
        );
    }
}

const reduxHook = connect(
    state => ({
        nodeData: state.Content.nodeData,
        menuData: state.Content.menuData,
        langData: state.Content.lang,
        getFoodDetailData: state.Content.getFoodDetailData,
        foodDetailUrl: state.Content.foodDetailUrl,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
  );

export default compose(
    reduxHook,
)(Header);