 import thunk from 'redux-thunk';
import {
  createStore,
  compose,
  applyMiddleware,
} from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import fetchMiddleware from 'redux-middleware-fetch';
import reducers from '../reducers/index.js';

export const history = createBrowserHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose ;
const storeComposeArray = [applyMiddleware(
  thunk,
  routerMiddleware(history),
  fetchMiddleware,
)];

export const store = createStore(
  reducers,
  composeEnhancers(...storeComposeArray),
);