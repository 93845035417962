import React, { PureComponent, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import SpecialBlock from '../conponents/shared/SpecialBlock';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import APPCONFIG from '../config/config.js';
import * as IndexAction from '../actions/index';


class PromotionDetail extends PureComponent<Props> {
    componentWillMount() {
        const {
            match: {
                params: {
                    mainId,
                }            
            },
            location: {
                state,
                pathname,
            },
            history,
            fetchNodeData,
            nodeData,
            changeLanguage,
            allNodes,
            getDetailListNum,
        } = this.props;
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            const mainpPath = pathname.split("/");
        if (!state) {
            // if (allNodes) {
            //     if(!nodeData[`/${mainpPath[2]}`]) {
            //         const thisNodeData = allNodes.childNode && allNodes.childNode[0].childNode.find(val => val.route === `/${mainpPath[2]}`);
            //         if(!thisNodeData) return null;
            //         fetchNodeData(`/${thisNodeData.nodeId}`, thisNodeData.route);
            //     }
            // }
        } else {
            if(!nodeData[`/${mainpPath[2]}`]) {
                // if (allNodes) {
                //     const thisNodeData = allNodes.childNode && allNodes.childNode[0].childNode.find(val => val.route === `/${mainpPath[2]}`);
                //     if(!thisNodeData) return null;
                //     fetchNodeData(`/${thisNodeData.nodeId}`, thisNodeData.route);
                // }            
            } else {
                const isThisData = nodeData[`/${mainpPath[2]}`].datas.indexOf(nodeData[`/${mainpPath[2]}`].datas.find(val => val.nodeId === `${mainId}`));
                getDetailListNum(isThisData);
            }
        }  
    }

    componentDidUpdate(preprops) {
        const {
            match: {
                params: {
                    mainId,
                }            
            },
            location: {
                state,
                pathname,
            },
            history,
            fetchNodeData,
            nodeData,
            changeLanguage,
            allNodes,
            getDetailListNum,
            detailListNum,
            langData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if(!state) {
            if(nodeData[`/${mainpPath[2]}`]) {
                const isThisData = nodeData[`/${mainpPath[2]}`].datas.indexOf(nodeData[`/${mainpPath[2]}`].datas.find(val => val.nodeId === `${mainId}`));
                if(detailListNum === null) {
                    if(isThisData === -1) {
                        history.replace(`/${mainpPath[1]}/${mainpPath[2]}`);
                    } else {
                        getDetailListNum(isThisData);
                    }  
                } else {
                    if(preprops.nodeData !== nodeData) {
                        if(nodeData[`/${mainpPath[2]}`].datas[detailListNum]) {
                            const isUrl = nodeData[`/${mainpPath[2]}`].datas[detailListNum].nodeId;
                            if(isUrl) {
                                history.replace(`/${mainpPath[1]}/${mainpPath[2]}/${isUrl}`);
                            } 
                        } else {
                            history.replace(`/${mainpPath[1]}/${mainpPath[2]}`);
                        }
                        
                    }
                } 
            }    

        } else {
            if(nodeData[`/${mainpPath[2]}`]) {
                const isThisData = nodeData[`/${mainpPath[2]}`].datas.indexOf(nodeData[`/${mainpPath[2]}`].datas.find(val => val.nodeId === `${mainId}`));
                if(detailListNum === null || detailListNum !== -1) {
                    if(isThisData === -1) return null;
                    getDetailListNum(isThisData);
                }
            }   
            
        };
    }

    render() {
        const {
            match: {
                params: {
                    mainId,
                }            
            },
            nodeData,
            location: {
                state,
                pathname,
            },
            history,
            langData,
            detailListNum,
        } = this.props;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[2]}`] || (nodeData[`/${mainpPath[2]}`] && !nodeData[`/${mainpPath[2]}`].datas)) return null;
        const responseData = nodeData[`/${mainpPath[2]}`].datas.find(val => val.nodeId === `${mainId}`);
        responseData.html = responseData.html.replace("src=\"upload/", "src=\"/upload/");
        return (
            <Fragment>
                <div class="promotion-inner-backbtn">
                    <Link to={{ pathname: `/${langData.type}/${mainpPath[2]}`, state: {...state} }}>
                        <span>
                            <i class="icon-arrow-red"></i>
                        </span>
                        {langData.goBack}
                    </Link>
                </div>
                <div class={langData.type === "zh-TW" ? "promotion-detail-warpper" : "en-promotion-detail-warpper"}>
                    <div dangerouslySetInnerHTML={{ __html: responseData && responseData.html }} />
                </div>
                <SpecialBlock pathname={pathname} temp={1} />
            </Fragment>
        );
    }
}


const reduxHook = connect(
    state => ({
        nodeData: state.Content.nodeData,
        langData: state.Content.lang,
        allNodes: state.Content.allNodes,
        detailListNum: state.Content.detailListNum,
    }),
    dispatch => bindActionCreators({
        ...IndexAction,
    }, dispatch),
);

export default compose(
    withRouter,
    reduxHook,
)(PromotionDetail);