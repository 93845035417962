import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux';
import {
  compose,
  bindActionCreators,
} from 'redux';
import { withRouter } from 'react-router';
import APPCONFIG from '../config/config.js';
import * as indexAction from '../actions/index.js'

const StyleImg = styled.div`
    width: 65%;
    height: 550px;
    background-image: url('${props => props.imgUrl}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 15%;
    box-shadow: ${props => props.imgRight ? "20px -20px #dad8c7" : "-20px -20px #dad8c7"};
    margin-bottom: ${props => (props.getLength > 8 ? (props.getLength-8)*70 : 0)}px;
    @media (max-width: 1023px) {
        width: 100%;
        height: 450px;
        box-shadow: none;
        margin-bottom: ${props => props.getLength*70}px;
    }
    @media (max-width: 414px) {
        height: 350px;
        margin-bottom: ${props => props.getLength*26+63}px;
    }
`;

const StyleMenu = styled.div`
    position: absolute;
    width: 45%;
    background-color: #fff;
    right: 0;
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    @media (max-width: 1023px) {
        width: 90%;
        left: 50%;
        top: 440px;
        transform: translate(-50%,0);
    }
    @media (max-width: 414px) {
        top: 320px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const StyleMenuLeft = styled.div`
    position: absolute;
    width: 45%;
    background-color: #fff;
    left: 0;
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    @media (max-width: 1023px) {
        width: 90%;
        left: 50%;
        top: 440px;
        transform: translate(-50%, 0);
    }
    @media (max-width: 414px) {
        top: 320px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const StyleMenuList = styled.div`
    width: 100%;
    border: 1px solid #d2d2d2;
    padding: 2em 0;
    @media (max-width: 768px) {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    @media (max-width: 414px) {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 0;
        padding-right: 0;
    }
`;

const StyleMenuItemWep = styled.div`
    margin: 0 auto;
    width: fit-content;
    @media (max-width: 414px) {
        display: flex; 
        flex-direction: column;
    }
`;

const StyleMenuItem = styled(Link)`
    width: fit-content;
    display: flex;
    font-size: 1.5rem;
    align-items: center;
    color: #be2f37;
    margin: 1.2rem 0;
    scroll-behavior: smooth;
    text-decoration: none;
    >span {
        position: relative;
        display: block;
    }
    >span >i {
        margin-left: 20px;
        width: 20px;
        height: 16px;
        position: absolute;
        transition: left .5s;
        left: 0rem;
        top: 0;
        transform: translate(-50%, -50%) rotate(-90deg);
    }
    &:hover {
        >span >i {
            left: .3rem;
        }
    }
    @media (max-width: 768px) {
        font-size: 1.5rem; 
        >span >i {
            margin-left: 15px;
            width: 16px;
            height: 12px;
            top: 55%;
        }
      &:hover {
        >span >i {
            left: .3rem;
        }
    }
    @media (max-width: 414px) {
        letter-spacing: 0;
        font-size: 1.2rem;
        margin: .5rem 30px 0 0;
        text-align: start;
        >span {
            height: 18px;
        }
        >span >i {
            margin-left: 15px;
            width: 13px;
            height: 10px;
            top: 40%;
        }
    }
`;

const StyleLink = styled(Link)`
    text-decoration: none;
    display: block;
`;

const StyleInnerBlockL = styled.div`
    width: 100%;
    height: 100px;
`;


class FoodAndDrink extends PureComponent<Props> {
    constructor(props) {
        super(props);
        this.state = {
            bottomHeight: 350,
        };
    }
    componentWillMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    getSummary(name) {
        const {
            allNodes,
        } = this.props;
        let selectList = [];
        const foodNodeId = allNodes.childNode && allNodes.childNode.filter(
                data => data.childNode.filter(
                x => x.childNode.filter(
                    y => {
                        
                        if(y.pageTitle === name)
                        {
                            return selectList.push(y);
                        }
                    }
                )
            )
        );
        if (selectList.length) {
            return selectList[0].nodeId
        }
    }
    componentWillUnmount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    render() {
        const {
            nodeData,
            location: {
                state,
                pathname,
            },
            langData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[2]}`] || (state && !nodeData[state.route])) return null;
        // if (!nodeData[pathname] && (!state || !nodeData[state.route])) return null;
        const responseData = nodeData[`/${mainpPath[2]}`].datas || nodeData[state.route].datas;
        return (
            <div class="content-warpper">
                
                <div class="content-sub-warpper">
                    {
                    responseData.map((val, index) => (
                        <Fragment>
                            <div class="food-drink-title-warpper">
                                <div class="content-title">{val.title}</div>
                                <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>
                                    {val.html}
                                </div>
                            </div>
                            <div
                                id="food-6"
                                class={index % 2 ? "content-sub-innner-warp food-drink-content-attr-end" : "content-sub-innner-warp food-drink-content-attr"}>
                                <StyleImg imgUrl={val.url} imgRight getLength={val.datas.length}/>
                                {index % 2 ? (
                                    <StyleMenuLeft>
                                        <StyleMenuList>
                                            
                                            <StyleMenuItemWep>
                                            <StyleLink 
                                            to={{ 
                                                pathname:`/${langData.type}${val.route}`, 
                                                state: 
                                                    {
                                                        ...val,  
                                                        url:`${this.getSummary(val.summary)}`
                                                            
                                                    } 
                                                }}
                                            className="food-drink-menuList-title">
                                            {val.summary}
                                            </StyleLink>
                                                {val.datas.map(data => (
                                                <StyleMenuItem 
                                                    to={{ pathname: `/${langData.type}${data.route}`, state: {...data}, hash:`#${data.achor}`}}>
                                                    <span class="food-drink-menuList-red">{data.title}</span>
                                                    <span>
                                                        <i class="icon-arrow-red"></i>
                                                    </span>
                                                </StyleMenuItem>
                                                ))}
                                            </StyleMenuItemWep>
                                        </StyleMenuList>
                                    </StyleMenuLeft>
                                ) : (
                                    // food
                                    <StyleMenu>
                                        <StyleMenuList>
                                            
                                            <StyleMenuItemWep>
                                            <StyleLink 
                                            to={{ 
                                                pathname:`/${langData.type}${val.route}`, 
                                                state: 
                                                    {
                                                        ...val, 
                                                        url:`${this.getSummary(val.summary)}`
                                                            
                                                    } 
                                                }}
                                            className="food-drink-menuList-title">
                                            {val.summary}
                                            </StyleLink>
                                                {val.datas.map(data => (
                                                <StyleMenuItem to={{ pathname: `/${langData.type}${data.route}`, state: {...data}, hash:`#${data.achor}`}}>
                                                    <span class="food-drink-menuList-red">{data.title}</span>
                                                    <span>
                                                        <i class="icon-arrow-red"></i>
                                                    </span>
                                                </StyleMenuItem>
                                                ))}
                                            </StyleMenuItemWep>
                                        </StyleMenuList>
                                    </StyleMenu>
                                )}
                            </div>
                        </Fragment>
                    ))}
                </div>
                <StyleInnerBlockL />
            </div>
        );
    }
}

const reduxHook = connect(
    state => ({
        nodeData: state.Content.nodeData,
        allNodes: state.Content.allNodes,
        menuData: state.Content.menuData,
        langData: state.Content.lang,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
  );

export default compose(
    withRouter,
    reduxHook,
)(FoodAndDrink);