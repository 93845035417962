import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  compose,
  bindActionCreators,
} from 'redux';
import { withRouter } from 'react-router';
import * as indexAction from '../actions/index.js';

import BarCocktailsLeft from '../static/Bar_Cocktails-left.jpg';
import BarCocktailsRight from '../static/Bar_Cocktails-right.jpg';
import iconFleur from '../static/fleur-gray.svg';
import dividerCenter from '../static/divider-center.png';
import dividerLeft from '../static/divider-left.png';
import dividerRight from '../static/divider-right.png';
import dots from '../static/dots.png';
import wineRed from '../static/wine-red.svg';


const StyleContentBg = styled.div`
    width: 100%;
    min-height: 500px;
    background: url('${BarCocktailsLeft}') 0 3em no-repeat,
                url('${BarCocktailsRight}') 100% 3em no-repeat;
    background-size: 20em 30em,20em 30em;
    display: flex;
    align-items: center;
    @media (max-width: 1023px) {
        background: none;
        min-height: auto;
      }
`;

const StyleContent = styled.div`
    display: flex;
    width: 1160px;
    margin: 90px auto 130px;
    justify-content: space-between;
    @media (max-width: 1366px) {
        width: 90%;
      }
    @media (max-width: 1023px) {
        flex-direction: column;
        margin: 70px auto;
      }
`;

const StyleDividingLine = styled.div`
    width: 1160px;
    margin: 0 auto 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: 1366px) {
        width: 90%;
      }
    @media (max-width: 414px) {
        margin: 0 auto 35px;
    }
`;

const StyleIconFleur = styled.div`
    width: 35px;
    height: 38px;
    margin-bottom: 10px;
    background-image: url('${iconFleur}');
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 414px) {
        width: 25px;
        height: 26px;
    }
`;

const StyleDividingText = styled.div`
    width: 100%;
    background: url('${dividerLeft}') 0 .5rem/1.9375rem .375rem no-repeat,
                url('${dividerRight}') 100% .5rem/1.9375rem .375rem no-repeat,
                url('${dividerCenter}') 0 .5rem/.0625rem .375rem repeat-x;
    background-position: 0 .75rem,100% .75rem,0 .75rem;
    background-size: 4rem .75rem,4rem .75rem,.0625rem .75rem;
    @media (max-width: 768px) {
        background: url('${dividerLeft}') 0 .5em/2.5em .6em no-repeat,
        url('${dividerRight}') 100% .5em/2.5em .6em no-repeat,
        url('${dividerCenter}') 0 .5em/1em .6em repeat-x;
    }
`;

const StyleLeft = styled.div`
    width: 46%;
    display: flex;
    flex-direction: column;
    @media (max-width: 1023px) {
        width: 100%;
      }
`;

const StyleImg = styled.div`
    width: 100%;
    height: 730px;
    background-image: url('${props => props.src}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: 1366px) {
        height: 550px;
      }
    @media (max-width: 1280px) {
        height: 500px;
      }
    @media (max-width: 1023px) {
        width: 100%;
        box-shadow: none;
        height: 530px;
      }
      @media (max-width: 768px) {
        height: 450px;
      }
`;

const StyleImgRight = styled.div`
    width: 100%;
    height: 730px;
    background-image: url('${props => props.src}'); // rositaFeatured
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media (max-width: 1366px) {
        height: 550px;
      }
    @media (max-width: 1280px) {
        height: 500px;
      }
    @media (max-width: 1023px) {
        width: 100%;
        height: 530px;
        box-shadow: none;
      }
      @media (max-width: 768px) {
        height: 350px;
      }
      @media (max-width: 414px) {
        height: 450px;
      }
`;

const StyleRight = styled.div`
    width: 46%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1023px) {
        width: 100%;
      }
`;

const StyleTextItalic = styled.div`
    font-style: italic;
    font-weight: 400;
    font-size: 1.2rem;
    @media (max-width: 768px) {
        padding-bottom: 2px;
        font-size: 1rem;
      }
    @media (max-width: 414px) {
        font-size: .8rem;
      }
`;

const StyleTextTitle = styled.div`
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    letter-spacing: -1px;
    font-size: 1.8rem;
    line-height: 1.8rem;
    text-transform: uppercase;
    padding: .1rem 0 .4rem;
    @media (max-width: 768px) {
        font-size: 1.5rem;
        line-height: 1.5rem;
        padding: 0;
      }
    @media (max-width: 414px) {
        font-size: 1.2rem;
        line-height: 1.2rem;
      }
`;

const StyleContentImg = styled.div`
    width: 1160px;
    height: 600px;
    margin: 0 auto 120px;
    background-image: url('${props => props.src}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media (max-width: 1366px) {
        width: 90%;
    }
    @media (max-width: 1023px) {
        height: 550px;
        margin: 0 auto 70px;
    }
    @media (max-width: 414px) {
        height: 450px;
        margin: 0 auto 35px;
      }
`;

const StyleDessertContent = styled.div`
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    width: 1160px;
    margin: 0 auto 35px;
    @media (max-width: 1366px) {
        width: 90%;
      }
`;

const StyleDessertItem = styled.div`
    width: 25%;
    padding: 25px;
    margin-top: 35px;
    color: #5d5d5d;
    @media (max-width: 1023px) {
        width: 100%;
        text-align: start;
        padding: 0;
    }
    @media (max-width: 768px) {
        margin-top: 25px;
    }
`;

const StyleDividingType = styled.div`
    height: 1rem;
    background: url('${dots}') 50% 0 repeat-x;
    background-size: 30px 4px;
    display: flex;
    justify-content: center;
    margin: 4rem 0 4.5rem;
    @media (max-width: 414px) {
        margin: 4rem 0 30px;
    }  
`;

const StyleIconFleurWep = styled.div`
    width: 60px;
    height: 35px;
    background-color: #f3f2eb;
    display: flex;
    justify-content: center;
    position: relative;
    top: -1em;
`;

const StyleImgRedWine = styled.div`
    width: 80%;
    height: 25vw;
    margin: 0 auto;
    background-image: url('${props => props.src}');
    background-repeat: no-repeat;
    background-size: cover;
`;

const StyleRedWineItem = styled.div`
    margin: 15px 0;
    color: #5d5d5d;
    @media (max-width: 414px) {
        margin: 0 0 15px;
    }
`;

const StyleRedWineTextInner = styled.div`
    font-weight: 600;
    font-size: 1.3rem;
    @media (max-width: 1023px) {
        font-size: 1rem;
        font-weight: 500;
      }
      @media (max-width: 414px) {
        font-size: .9rem;
        line-height: 1rem;
      }
`;

const StyleWineOpenImg = styled.div`
    width: 100%;
    height: 820px;
    background-image: url('${props => props.src}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0 auto;
    @media (max-width: 414px) {
        height: 450px;
        margin-bottom: 15px;
    }
`;

const StyleRightDown = styled.div`
    width: 100%;
`;

const StyleWhiteWineImg = styled.div`
    width: 100%;
    height: 720px;
    background-image: url('${props => props.src}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: 414px) {
        height: 450px;
        margin-bottom: 15px;
    }
`;

const StyleWineEnd = styled.div`
    width: 100%;
    height: 600px;
    margin-top: -50px; 
    background-image: url('${props => props.src}'); // wineEnd
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: 1400px) {
        height: 480px;
      }
    @media (max-width: 1023px) {
        height: 350px;
      }
    @media (max-width: 414px) {
        height: 200px;
    }
`;

const StyleImgChampagne = styled.div`
    width: 80%;
    height: 25vw;
    margin: 0 auto;
    background-image: url('${props => props.src}'); // champagneBottle
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: 1023px) {
        width: 90%;
    }
`;

const StyleSparklingItem = styled.div`
    width: 25%;
    padding: 25px;
    margin-top: 35px;
    color: #5d5d5d;
    @media (max-width: 1023px) {
        width: 90%;
        padding: 0;
    }
    @media (max-width: 414px) {
        margin: 0 0 15px;
    }
`;

const StyleContentSpecialtyList = styled.div`
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    @media (max-width: 1400px) {
        width: 90%;
      }
`;

class Drinks extends Component<Props> {
    componentWillMount() {
        const {
            location: {
                state,
                pathname,
            },
            fetchNodeData,
            nodeData,
            allNodes,
        } = this.props;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera 
    }

    render() {
        const {
            nodeData,
            location: {
                state,
                pathname,
            },
            langData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[2]}`]) return null;
        const Datas = nodeData[`/${mainpPath[2]}`].datas;
        if(!Datas.length) return null;
        const responseData = Datas.filter(val => val.title !== 'Dynamic Area');
        const dynamicData = Datas.filter(val => val.title === 'Dynamic Area');
        return (
            <div class="content-warpper">
                <div id={responseData[0].achor || null } /> 
                <StyleContentBg>
                    <div class="drink-inner-title-warpper" >
                        {responseData[0].html && <div class="content-title" style={{margin: '0 auto'}}>{responseData[0].html}</div>}
                        {responseData[0].summary && <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>{responseData[0].summary}</div>}
                    </div>
                </StyleContentBg>    


                {/* // 雞尾酒 */}  
                {responseData[0].datas[0] && responseData[0].datas[0].title && 
                <StyleDividingLine>
                    <StyleIconFleur />
                    <StyleDividingText>
                        <div class="dividing-inner-text">{responseData[0].datas[0].title}</div>
                    </StyleDividingText>
                </StyleDividingLine>}
                {responseData[0].datas[1] && <div class="content-inner-warpper food-content-attr">
                    <div class="food-content-left">
                        {responseData[0].datas[1].url && <StyleImg src={responseData[0].datas[1].url} />}
                        <div class="content-sub-innner-warp">
                            <div class="gray-line-horizontal"></div>
                        </div>
                        {responseData[0].datas[2].datas.length && responseData[0].datas[2].datas.map(val => 
                            <div class="food-steak-list-item">
                                {val.title && <div class="drinks-menu-text-italic">{val.title}</div>}
                                {val.summary && <div class="content-subTitle-gray">{val.summary}</div>}
                                {val.html && <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{val.html}</div>}
                            </div>
                        )}
                    </div>
                    <div class="food-content-right">
                        {responseData[0].datas[1].datas.length && responseData[0].datas[1].datas.map(val => 
                        <div class="food-steak-list-item">
                            {val.title && <div class="drinks-menu-text-italic">{val.title}</div>}
                            {val.summary && <div class="content-subTitle-gray">{val.summary}</div>}
                            {val.html && <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{val.html}</div>}
                        </div>
                        )}
                        <div class="content-sub-innner-warp">
                            <div class="gray-line-horizontal"></div>
                        </div>
                        {responseData[0].datas[2].url && 
                        <StyleImgRight className="food-steak-list-img" src={responseData[0].datas[2].url} />}  
                    </div>          
                </div>}



                {responseData[0].datas[3] && responseData[0].datas[3].datas.length && <StyleDessertContent>
                    {responseData[0].datas[3].datas.map(data => (
                    <StyleDessertItem>
                        <div class="drinks-menu-text-italic">{data.title}</div>
                        <StyleTextTitle>{data.summary}</StyleTextTitle>
                        <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{data.html}</div>
                    </StyleDessertItem>
                    ))}
                </StyleDessertContent>}

                {responseData[0].datas[3] && responseData[0].datas[3].url && <StyleContentImg src={responseData[0].datas[3].url} />}

                {/* 使用IsDynamicArea變數儲存節點管理是否顯示設定 */} 
                {responseData[0].datas[4].isDynamicArea && responseData[0].datas[4] && responseData[0].datas[4].title && <StyleDividingLine>
                    <StyleIconFleur />
                    <StyleDividingText>
                        <div className="dividing-inner-text">{responseData[0].datas[4].title}</div>
                    </StyleDividingText>
                </StyleDividingLine>}

                {responseData[0].datas[4].isDynamicArea && responseData[0].datas[4] && responseData[0].datas[4].datas.length && <StyleDessertContent>
                    {responseData[0].datas[4].datas.map(data => (
                    <StyleDessertItem>
                        <div class="drinks-menu-text-italic">{data.title}</div>
                        <StyleTextTitle>{data.summary}</StyleTextTitle>
                        <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{data.html}</div>
                    </StyleDessertItem>
                    ))}
                </StyleDessertContent>}

                {responseData[0].datas[5] && <div class="content-special-area" style={{backgroundImage: `url('${responseData[0].datas[5].url || null}')`}}>
                    <img class="header-icon" src={`${wineRed}`} alt="icon"/>
                    {responseData[0].datas[5].title && <div class="content-sub-innner-warp">
                        <div class="content-sub-title">{responseData[0].datas[5].title}</div>
                    </div>}
                    {responseData[0].datas[5].summary && <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{responseData[0].datas[5].summary}</div>}
                </div>}

                {/* 紅酒 */}
               {responseData[1] && <div id={responseData[1].achor || null}>
                <StyleDividingType>
                    <StyleIconFleurWep>
                        <StyleIconFleur />
                    </StyleIconFleurWep>
                </StyleDividingType>
                <div class="content-inner-warpper">
                    {responseData[1].title && <div class="content-title">{responseData[1].title}</div>}
                    {responseData[1].summary && <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>{responseData[1].summary}</div>}
                </div>
                {responseData[1].url && <StyleImgRedWine src={responseData[1].url} />}

                {responseData[1].datas[2] && responseData[1].datas[2].title && <div class="content-sub-innner-warp">
                    <a class="content-food-bt" href={responseData[1].datas[2].url} target='_blank'>{responseData[1].datas[2].title}</a>
                </div>}

                {responseData[1].datas[0] && responseData[1].datas[0].title && <StyleDividingLine>
                    <StyleIconFleur />
                    <StyleDividingText>
                        <div className="dividing-inner-text">{responseData[1].datas[0].title}</div>
                    </StyleDividingText>
                </StyleDividingLine>}
                
                {responseData[1].datas[0] && <StyleContent>
                    {responseData[1].datas[0].datas[1] && responseData[1].datas[0].datas[1].datas.length &&
                    <StyleLeft>
                        {responseData[1].datas[0].datas[1].datas.map(data => (
                        <StyleRedWineItem>
                           {data.title && <div class="drinks-menu-text-italic">{data.title}</div>}
                            {data.summary && <StyleTextTitle>{data.summary}</StyleTextTitle>}
                            {data.html && <StyleRedWineTextInner>{data.html}</StyleRedWineTextInner>}
                        </StyleRedWineItem>
                        ))}
                    </StyleLeft>}

                    <div className="gray-line display-none"></div>

                    <StyleRight>
                        {responseData[1].datas[0].datas[0] && responseData[1].datas[0].datas[0].url &&
                        <StyleWineOpenImg src={responseData[1].datas[0].datas[0].url} />}
                        <div className="gray-line-horizontal display-none" style={{margin: '40px auto 25px'}}/>

                        {responseData[1].datas[0].datas[2] && responseData[1].datas[0].datas[2].datas.length && 
                        <StyleRightDown>
                        {responseData[1].datas[0].datas[2].datas.map(data => (
                        <StyleRedWineItem>
                             {data.title && <div class="drinks-menu-text-italic">{data.title}</div>}
                            {data.summary && <StyleTextTitle>{data.summary}</StyleTextTitle>}
                            {data.html && <StyleRedWineTextInner>{data.html}</StyleRedWineTextInner>}
                        </StyleRedWineItem>
                        ))}
                        </StyleRightDown>}
                    </StyleRight>
                </StyleContent>}

                {responseData[1].datas[1] && <div class="content-special-area" style={{backgroundImage: `url('${responseData[1].datas[1].url || null}')`}}>
                    <img class="header-icon" src={`${wineRed}`} alt="icon"/>
                    {responseData[1].datas[1].title && <div class="content-sub-innner-warp">
                        <div class="content-sub-title">{responseData[1].datas[1].title}</div>
                    </div>}
                    {responseData[1].datas[1].summary && <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{responseData[1].datas[1].summary}</div>}
                </div>}
                </div>}

                {/* // 白酒 */}  
                {responseData[2] && <div id={responseData[2].achor|| null}>
                <StyleDividingType>
                    <StyleIconFleurWep>
                        <StyleIconFleur />
                    </StyleIconFleurWep>
                </StyleDividingType>
                <div class="content-inner-warpper">
                    {responseData[2].title && <div class="content-title">{responseData[2].title}</div>}
                    {responseData[2].summary && <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>{responseData[2].summary}</div>}
                </div>
                {responseData[2].url && <StyleImgRedWine src={responseData[2].url} />}
                <div class="content-sub-innner-warp">
                    <a class="content-food-bt" href={responseData[2].datas[2] && responseData[2].datas[2].url} target="_blank">{responseData[2].datas[2] && responseData[2].datas[2].title}</a>
                </div>
                <StyleDividingLine>
                    <StyleIconFleur />
                    <StyleDividingText>
                        <div class="dividing-inner-text">{responseData[2].datas[0].title}</div>
                    </StyleDividingText>
                </StyleDividingLine>
                <StyleContent>
                    <StyleRight>
                        <StyleWhiteWineImg src={responseData[2].datas[0].datas[0] ? responseData[2].datas[0].datas[0].url : ''} />
                        <div className="gray-line-horizontal" style={{margin: '40px auto 25px'}}/>
                        <StyleRightDown>
                            {responseData[2].datas[0].datas[2] && responseData[2].datas[0].datas[2].datas.map(data =>(
                            <StyleRedWineItem>
                                <StyleTextItalic style={{color:'#868686'}}>{data.title}</StyleTextItalic>
                                <StyleTextTitle>{data.summary}</StyleTextTitle>
                                <StyleRedWineTextInner>{data.html}</StyleRedWineTextInner>
                            </StyleRedWineItem>
                            ))}
                        </StyleRightDown>
                    </StyleRight>
                    <div className="gray-line display-none"></div>
                    <StyleLeft>
                        {responseData[2].datas[0].datas[1] && responseData[2].datas[0].datas[1].datas.map(data =>(
                            <StyleRedWineItem>
                                <StyleTextItalic style={{color:'#868686'}}>{data.title}</StyleTextItalic>
                                <StyleTextTitle>{data.summary}</StyleTextTitle>
                                <StyleRedWineTextInner>{data.html}</StyleRedWineTextInner>
                            </StyleRedWineItem>
                            ))}
                    </StyleLeft>
                </StyleContent>
                <StyleWineEnd src={responseData[2].datas[1].url} />
                </div>}

                {/* // 氣泡酒 */}  
                {responseData[3] && <div id={responseData[3].achor}>
                <StyleDividingType>
                    <StyleIconFleurWep>
                        <StyleIconFleur />
                    </StyleIconFleurWep>
                </StyleDividingType>

                <div class="content-inner-warpper">
                    {responseData[3].html && <div class="content-title">{responseData[3].html}</div>}
                    {responseData[3].summary && <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>{responseData[3].summary}</div>}
                </div>
                {responseData[3].url && <StyleImgChampagne src={responseData[3].url} />}
                {responseData[3].datas[1] && <div class="content-sub-innner-warp">
                    {responseData[3].datas[1].title && <a class="content-food-bt" href={responseData[3].datas[1].url || null} target='_blank'>{responseData[3].datas[1].title}</a>}
                </div>}
                <StyleDividingLine>
                    <StyleIconFleur />
                    <StyleDividingText>
                        <div className="dividing-inner-text">{responseData[3].datas[0].title}</div>
                    </StyleDividingText>
                </StyleDividingLine>
                <div class="content-inner-bottom-warpper drink-wrap">
                    {responseData[3].datas[0].datas.length && responseData[3].datas[0].datas.map(val => 
                    <StyleSparklingItem>
                        <StyleTextItalic style={{color:'#868686'}}>{val.title}</StyleTextItalic>
                        <StyleTextTitle>{val.summary}</StyleTextTitle>
                        <StyleRedWineTextInner>{val.html}</StyleRedWineTextInner>
                    </StyleSparklingItem>
                    )}
                </div>
                </div>}

                {/* Dynamic Area */}
                {dynamicData.length ? dynamicData.map(val => (
                <div id={val.achor ? val.achor : null}>
                     <StyleDividingType>
                        <StyleIconFleurWep>
                            <StyleIconFleur />
                        </StyleIconFleurWep>
                    </StyleDividingType>
                    <div class="content-inner-warpper">
                        {val.html && <div class="content-title">{val.html}</div>}
                        {val.summary && <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>{val.summary}</div>}
                    </div>
                    {val.url ? <StyleImgChampagne imgUrl={val.url}/> : null}
                    {val.datas.length ? val.datas.map(data => (
                    <div>
                    <StyleDividingLine>
                        <StyleIconFleur />
                        <StyleDividingText>
                            <div class="dividing-inner-text">{data.title}</div>
                        </StyleDividingText>
                    </StyleDividingLine>
                    <StyleContentSpecialtyList>
                        {data.datas.length ? data.datas.map(list => (
                            <StyleSparklingItem>
                                {list.title ? <StyleTextItalic style={{color:'#868686'}}>{list.title}</StyleTextItalic> : null}
                                {list.summary ? <StyleTextTitle>{list.summary}</StyleTextTitle> : null}
                                {list.html ? <StyleRedWineTextInner>{list.html}</StyleRedWineTextInner> : null}
                            </StyleSparklingItem>
                        )) : null}
                    </StyleContentSpecialtyList>
                    </div>)) : null}
                </div>
                )) : null}
            </div>
        );
    }
}


const reduxHook = connect(
    state => ({
        nodeData: state.Content.nodeData,
        allNodes: state.Content.allNodes,
        langData: state.Content.lang,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
  );

export default compose(
    withRouter,
    reduxHook,
)(Drinks);