import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

const StyleWarpper = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 80px;
    height: 80px;
    color: #5a5a5a;
    background-color: #fff;
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    text-transform: uppercase;
    font-weight: bolder;
    z-index: 1;
    right: 50px;
    padding: 0 5px;
    letter-spacing: .02em;
    bottom: -6.25rem;
    opacity: 0;
    transition: bottom .5s ease-out, opacity .5s ease-in;
    cursor: pointer;
    > i {
        width: 17px;
        height: 14px;
        margin: 10px auto 0;
    }
    ${props => props.visible && css`
        bottom: 0;
        opacity: 1;
    `}
    @media (max-width: 414px) {
        font-size: 12px;
        width: 65px;
        height: 60px;
        padding: 10px 5px;
        right: 10px;
        bottom: 10px;
        border-radius: 5px;
    }
`;
 
class BackTopBtn extends PureComponent<Props> {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
        this.beforeScrollTop = 0;
        this.ShowBtnHeight = this.getShowBtnHeight();
        this.winScroll = this.winScroll.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll', this.winScroll);
    }

    componentDidUpdate(prevProps) {
        const { pathname } = this.props;
        if (prevProps.pathname !== pathname) this.ShowBtnHeight = this.getShowBtnHeight();
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.winScroll);
    }

    winScroll() {
        const afterScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const delta = afterScrollTop - this.beforeScrollTop;
        const { visible } = this.state;

        if (delta < 0 && afterScrollTop > this.ShowBtnHeight){
            if (!visible) this.setState({ visible: true });
        } else if (visible) this.setState({ visible: false });

        this.beforeScrollTop = afterScrollTop;
    }

    getShowBtnHeight() {
        const totalHeight = document.documentElement.offsetHeight || document.body.offsetHeight;
        const overHeight = totalHeight * 0.2;
        return (overHeight > 1000) ? 1000 : overHeight;
    }

    render() {
        const {
            visible,
        } = this.state;
        return (
            <StyleWarpper
                onClick={() => { window.scrollTo(0, 0); }}
                visible={visible}>
                GO TOP
                <i className="icon-arrow-block" />
            </StyleWarpper>
        );
    }
}

export default (BackTopBtn);