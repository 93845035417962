import React from 'react';
import { MemoryRouter, Router } from 'react-router';
import {
  Switch,
  Route,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader';
import { store, history } from './shared/Store.js';
import Home from './containers/Home.jsx';
import MainBoard from './containers/MainBoard.jsx';
import './static/sass/style.scss';

function App() {
  return (
    <Provider store={store}>
      <MemoryRouter>
          <Router history={history}>
            <Switch>
              {/* <Route exact path="/" render={() => <Home store={store} />} /> */}
              <Route path="/" render={() => <MainBoard store={store} />} />
            </Switch>
          </Router>
      </MemoryRouter>
    </Provider>
  );
}

export default hot(module)(App);
