import React, { PureComponent } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import MetaTags from 'react-meta-tags';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import * as indexAction from '../actions/index.js';
import Loading from '../conponents/Loading/loading';
import AboutUs from './AboutUs.jsx';
import FoodAndDrink from './FoodAndDrink.jsx';
import Food from './Food.jsx';
import Drinks from './Drinks.jsx';
import Lunch from './Lunch.jsx';
import HappyHour from './HappyHour.jsx';
import GiftCards from './GiftCards.jsx';
import PrivateEvents from './PrivateEvents.jsx';
import Promotions from './Promotions.jsx';
import PromotionDetail from './PromotionDetail.jsx';
import Member from './Member.jsx';
import Reservation from './Reservation.jsx';
import ContactUs from './ContactUs.jsx';
import FAQ from './FAQ.jsx';
import YourPrivacy from './YourPrivacy.jsx';
import FoodMenu1 from './FoodMenu1.jsx';
import HomeContent from './HomeContent.jsx';
import HomeContent2 from './HomeContent2.jsx';
import HomeContent3 from './HomeContent3.jsx';
import OnlineBooking from './OnlineBooking.jsx';

import NavList from '../conponents/NavList.jsx';
import NavList2 from '../conponents/NavList2.jsx';
import NavList3 from '../conponents/NavList3.jsx';
import Header from '../conponents/Header.jsx';
import Header2 from '../conponents/Header2.jsx';
import Header3 from '../conponents/Header3.jsx';
import Footer from '../conponents/shared/Footer.jsx';
import BackTopBtn from '../conponents/shared/BackTopBtn.jsx';
import APPCONFIG from '../config/config';
import { declaredPredicate } from '@babel/types';

const theme = {
    backgroundColor: '#f3f3eb',
    color: '#333',
}

const StyleWarpper = styled.div`
    // font-family: 'Open Sans',serif;
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    background-color: ${props => props.theme.backgroundColor};
    -webkit-font-smoothing: antialiased;
    @media (max-width: 1280px) {
        font-size: 16px;
    }
`;

const StyleContent = styled.div`
    width: 100%;
`;


class MainBoard extends PureComponent<Props> {
    componentDidMount() {
        const {
            location: {
                pathname,
              },
            getMenu,
            getFooter,
            getAllNodes,
            fetchNodeData,
            langData,
            history,
            changeLanguage,
            nodeData,
        } = this.props;
        const mainpPath = pathname.split("/");
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        if(pathname.indexOf('/hk/') >= 0 || pathname.indexOf('/sg/') >= 0 || pathname.indexOf('/ph/') >= 0) {
            history.replace(`/${ APPCONFIG.langurage[0].type}`); // default to en-us
        } else if(pathname === '/') {
            if(langData) {
                history.replace(`/${ langData.type}`);
            } else {
                history.replace(`/${ APPCONFIG.langurage[0].type}`);
            }
        } else {
            const data = APPCONFIG.langurage.find(val => val.type === mainpPath[1]);
            if(data) {
                changeLanguage(data);
            } else {
                if(langData) {
                    history.replace(`/${ langData.type}`);
                } else {
                    history.replace(`/${ APPCONFIG.langurage[0].type}`);
                }
            } 
        }
        getMenu('');
        getAllNodes();
        getFooter('');
        
    }

    componentDidUpdate(preprops) {
        const {
            location: {
                pathname,
                state,
              },
            getFoodDetailHtml,
            foodDetailHtmlData,
            nodeData,
            langData,
            fetchNodeData,
            menuData,
            footerData,
            allNodes,
            history,
        } = this.props;
        const mainpPath = pathname.split("/");
        if(state) {
            if(footerData.length && menuData.length && allNodes.childNode) {
                const reservations = menuData.find(val => val.route === '/reservations'); 
                if(nodeData[`/${mainpPath[2]}`]) {
                    if(!nodeData['/reservations']) {
                        fetchNodeData(reservations.url, reservations.route);
                    }
                } else {
                    const thisNodeData = menuData.find(val => val.route === `/${mainpPath[2]}`);
                    const thatNodeData = footerData.find(val => val.route === `/${mainpPath[2]}`);
                    const onlineBookingNodeData = menuData.find(val => val.route === `/${mainpPath[2]}`);
                    if(thisNodeData) {
                        fetchNodeData(thisNodeData.url, thisNodeData.route);
                    } else if (thatNodeData) {
                        fetchNodeData(thatNodeData.url, thatNodeData.route);
                    } else if (onlineBookingNodeData) {
                        fetchNodeData(onlineBookingNodeData.url, onlineBookingNodeData.route);
                    } else {
                        if(!allNodes.childNode) return null;
                        const thisNodeData = allNodes.childNode[0].childNode.find(
                            val => val.route === '/food-drink'
                            );
                        const mainNodeData = thisNodeData.childNode.find(val => val.route === `/${mainpPath[2]}`);
                        fetchNodeData(`/${mainNodeData.nodeId}`, mainNodeData.route); 
                    }
                }
            }
        } else {
            if((pathname === `/${langData.type}` || pathname === `/${langData.type}/`)) {
                if(footerData.length && menuData.length && allNodes.childNode) {
                    const reservations = menuData.find(val => val.route === '/reservations');  
                    if(!nodeData[`/${mainpPath[1]}`]) {
                        fetchNodeData('', `/${langData.type}`);
                    } else {
                        if(!nodeData['/reservations']) {
                            fetchNodeData(reservations.url, reservations.route);
                        }
                    } 
                }
            } else {
                if(footerData.length && menuData.length && allNodes.childNode) {
                    const reservations = menuData.find(val => val.route === '/reservations'); 
                    const thisNodeData = menuData.find(val => val.route === `/${mainpPath[2]}`);
                    const thatNodeData = footerData.find(val => val.route === `/${mainpPath[2]}`);
                    if(!nodeData[`/${mainpPath[2]}`]) {
                        if(thisNodeData) {
                            fetchNodeData(thisNodeData.url, thisNodeData.route);
                        } else if (thatNodeData) {
                            fetchNodeData(thatNodeData.url, thatNodeData.route);
                        } else {
                            if(!allNodes.childNode) return null;
                            const thisNodeData = allNodes.childNode[0].childNode.find(
                                val => val.route === '/food-drink'
                                );
                            const mainNodeData = thisNodeData.childNode.find(val => val.route === `/${mainpPath[2]}`);
                            if(mainNodeData) {
                                fetchNodeData(`/${mainNodeData.nodeId}`, mainNodeData.route); 
                            } else {
                                if(langData) {
                                    history.replace(`/${ langData.type}`);
                                } else {
                                    history.replace(`/${ APPCONFIG.langurage[0].type}`);
                                }
                            }  
                        }
                    } else {
                        if(!nodeData['/reservations']) {
                            fetchNodeData(reservations.url, reservations.route);
                        }
                    } 
                }
            }
        }      
    }

    home() {
        const {
            location: {
                pathname,
              },
            nodeData,
            langData,
        } = this.props;
        switch(nodeData[`/${langData.type}`] && nodeData[`/${langData.type}`].title) {
            case '1':
                return  <Route path={`/${langData.type}/`} component={HomeContent2} pathname={pathname}/>;

            case '2':
                
                return  <Route path={`/${langData.type}/`} component={HomeContent3} pathname={pathname}/>;
            case '3':
                   
                return  <Route path={`/${langData.type}/`} component={HomeContent} pathname={pathname}/>;

            default:
                return;
        }
    }

    navList() {
        const {
            location: {
                pathname,
              },
            nodeData,
            langData,
        } = this.props;
        switch(nodeData[`/${langData.type}`] && nodeData[`/${langData.type}`].title) {
            case '1':
                return  <Route path={`/${langData.type}/`} component={NavList2} pathname={pathname}/>;

            case '2':
                
                return  <Route path={`/${langData.type}/`} component={NavList3} pathname={pathname}/>;
            case '3':
  
                return  <Route path={`/${langData.type}/`} component={NavList} pathname={pathname}/>;

            default:
                return;
        }
    }

    header() {
        const {
            location: {
                pathname,
              },
            nodeData,
            langData,
        } = this.props;
        switch(nodeData[`/${langData.type}`] && nodeData[`/${langData.type}`].title) {
            case '1':
                return  <Route path={`/${langData.type}/`} component={Header2} pathname={pathname}/>;

            case '2':
                
                return  <Route path={`/${langData.type}/`} component={Header3} pathname={pathname}/>;

            case '3':
  
                return  <Route path={`/${langData.type}/`} component={Header} pathname={pathname}/>;

            default:
                return;
        }
    }

    render() {
        const {
            location: {
              pathname,
            },
            langData,
        } = this.props;
        const mainpPath = pathname.split("/");
        const data = APPCONFIG.langurage.find(val => val.type === mainpPath[1]);
        return (
            <ThemeProvider theme={theme}>
                <StyleWarpper>
                <Loading />
                    <Switch>
                        {(pathname === `/${langData.type}` || pathname === `/${langData.type}/`) &&  this.navList()}
                        <Route path={`/${langData.type}/food/:mainId/:subId`} component={NavList}/>
                        <Route path={`/${langData.type}/promotions/:mainId`} component={NavList}/>
                        <Route path="/" component={NavList}/>
                    </Switch>
                    <Switch>
                        {(pathname === `/${langData.type}` || pathname === `/${langData.type}/`) && this.header()}
                        <Route path={`/${langData.type}/food/:mainId/:subId`} component={Header}/>
                        <Route path={`/${langData.type}/promotions/:mainId`} component={Header}/>
                        <Route path="/" component={Header}/>
                    </Switch>
                    <StyleContent>
                        <Switch> 
                            <Route path={`/${langData.type}/about-us`} component={AboutUs} />
                            <Route path={`/${langData.type}/food-drink`} component={FoodAndDrink} />
                            <Route path={`/${langData.type}/food/:mainId/:subId`} component={FoodMenu1} /> 
                            <Route path={`/${langData.type}/food`} component={Food} />
                            <Route path={`/${langData.type}/drink`} component={Drinks} />
                            <Route path={`/${langData.type}/lunch`} component={Lunch} />
                            <Route path={`/${langData.type}/happy-hour`} component={HappyHour} />
                            <Route path={`/${langData.type}/giftcards`} component={GiftCards} /> 
                            <Route path={`/${langData.type}/private-events`} component={PrivateEvents} />
                            <Route path={`/${langData.type}/promotions/:mainId`} component={PromotionDetail} />
                            <Route path={`/${langData.type}/promotions`} component={Promotions} />
                            <Route path={`/${langData.type}/member`} component={Member} />
                            <Route path={`/${langData.type}/reservations`} component={Reservation} />
                            <Route path={`/${langData.type}/contact-us`} component={ContactUs} />
                            <Route path={`/${langData.type}/faq`} component={FAQ} />
                            <Route path={`/${langData.type}/privacy`} component={YourPrivacy} /> 
                            <Route path={`/${langData.type}/online-booking`} component={OnlineBooking} /> 
                            {this.home()}
                        </Switch>
                    </StyleContent>
                    <Footer />
                    <BackTopBtn pathname={pathname} />
                </StyleWarpper>
            </ThemeProvider>
        );
    }
}

const reduxHook = connect(
    state => ({
        nodeData: state.Content.nodeData,
        menuData: state.Content.menuData,
        langData: state.Content.lang,
        footerData: state.Content.footerData,
        allNodes: state.Content.allNodes,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
);
export default reduxHook(withRouter(MainBoard));