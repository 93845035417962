import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import Logo from './../static/ruthschris_global_logo.png';
import userCircleWhite from './../static/user-circle-white.png';
import HeaderTop from './HeaderTop.jsx';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as indexAction from '../actions/index.js';
import APPCONFIG from '../config/config.js';

class NavList extends PureComponent<Props> {
    constructor(props) {
        super(props);
        this.state = {
            menuVisible: false,
        };
    }

    setLang(data) {
        const {
            location: {
                pathname,
            },
            match: {
                params: {
                    mainId,
                    subId,
                }            
            },
            langData,
            changeLanguage,
            getMenu,
            getFooter,
            getAllNodes,
            clearData,
            history,
        } = this.props;
        if (langData.type !== data.type) {
            const mainpPath = pathname.split("/");
            if(mainpPath[2]) {
                if(mainId && subId) {
                    history.replace(`/${data.type}/${mainpPath[2]}/${mainId}/${subId}`);
                } else if (mainId && !subId) {
                    history.replace(`/${data.type}/${mainpPath[2]}/${mainId}`);
                } else {
                    history.replace(`/${data.type}/${mainpPath[2]}`);
                } 
            } else {
                history.replace(`/${data.type}`);
            } 
            changeLanguage(data);
            clearData();
            getMenu('');
            getAllNodes();
            getFooter(''); 
        }
    }

    render() {
        const {
            location: {
                pathname,
            },
            history,
            menuData,
            langData,
            footerData,
            allNodes,
            match,
        } = this.props;

        const {
            menuVisible,
        } = this.state;
        if (!menuData && !footerData) return null;
        const reservations = menuData.find(val => val.route === '/reservations');
        const onlinebooking = menuData.find(val => val.route === '/online-booking');
        const member = footerData.find(val => val.route === '/member');
        let excludeRoute = '/reservations';
        let hasonlinebooking = false;
        if (onlinebooking){
            hasonlinebooking = true;
            excludeRoute = '/online-booking';
        };
        //console.log('navlist.js - APPCONFIG.langurage', APPCONFIG.langurage);
        //console.log('navlist.js - langData', langData);
        
        return (
            <div>
                <div class="nav-list-wrap">
                    <div class="nav-list-bar">
                        <Link 
                        alt="HOME" 
                        to={`/${langData.type}`}
                        pathname={pathname}
                        onClick={() => {
                            if (menuVisible)
                            this.setState({
                                menuVisible: !menuVisible,
                            })}}>
                            <img class="nav-logo" src={`${Logo}`} alt='Logo'></img>
                        </Link>
                        <div class={`nav ${menuVisible ? 'is-active' : ''}`}>
                            {menuData.map(val =>val.route !== excludeRoute ? (
                                <Link 
                                    key={val.route} 
                                    class={langData.type === "zh-TW" ? "nav-button" : "en-nav-button"}
                                    onClick={() => {
                                        this.setState({
                                            menuVisible: !menuVisible,
                                        })}} 
                                    to={{ pathname: `/${langData.type}${val.route}`, state: {...val} }}>
                                        {val.title}
                                </Link>
                            ) : null)}
                            <div class="nav-control">
                                <div class={APPCONFIG.langurage.length > 1 ? "nav-change-lang" : "display-none"}>
                                    {APPCONFIG.langurage.filter(x => x.type !== langData.type).map(data => (
                                        <div
                                            key={data.name} 
                                            class="nav-change-lang-bt">
                                            <span onClick={() =>{
                                                this.setLang(data);
                                                this.setState({
                                                        menuVisible: !menuVisible,
                                                    })}}
                                                >
                                                {APPCONFIG.langurage.find(x => x.type !== langData.type).name}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                                <Link 
                                    class="nav-member-icon" 
                                    to={member && { pathname: `/${langData.type}${member.route}`, state: {...member} }}
                                    onClick={() => {
                                        this.setState({
                                            menuVisible: !menuVisible,
                                        })}}>
                                    <img src={`${userCircleWhite}`} alt="member"></img>
                                </Link>
                            </div>
                            { hasonlinebooking ?
                            <button class="nav-reservations" >
                            <Link 
                                alt="ONLINEBOOKING" 
                                to={onlinebooking && { pathname: `/${langData.type}${onlinebooking.route}`, state: {...onlinebooking} }}
                                onClick={() => {
                                    this.setState({
                                        menuVisible: !menuVisible,
                                    })
                                    }}>
                                {langData.onlinebookingName}
                            </Link>
                            </button>
                            :
                            <button class="nav-reservations" >
                            <Link 
                                alt="RESERVATIONS" 
                                to={reservations && { pathname: `/${langData.type}${reservations.route}`, state: {...reservations} }}
                                onClick={() => {
                                    this.setState({
                                        menuVisible: !menuVisible,
                                    })
                                    }}>
                                {langData.bookingName}
                            </Link>
                            </button>
                            }
                        </div>
                    </div>

                    { hasonlinebooking ?
                    <Link 
                        class="nav-mobile-reservations" 
                        alt="ONLINEBOOKING" 
                        to={onlinebooking && { pathname: `/${langData.type}${onlinebooking && onlinebooking.route}`, state: {...onlinebooking} }}
                        onClick={() => {
                            this.setState({
                                menuVisible: !menuVisible,
                            })}}>
                        {langData.onlinebookingName}
                    </Link>
                    :
                    <Link 
                        class="nav-mobile-reservations" 
                        alt="RESERVATIONS" 
                        to={reservations && { pathname: `/${langData.type}${reservations && reservations.route}`, state: {...reservations} }}
                        onClick={() => {
                            this.setState({
                                menuVisible: !menuVisible,
                            })}}>
                        {langData.bookingName}
                    </Link>
                    }
                    <div className={`hamburger ${menuVisible ? 'is-active' : ''}`}
                        onClick={() => {
                            this.setState({
                                menuVisible: !menuVisible,
                            })
                        }}>
                        <div className="hamburger-box">
                            <div className="hamburger-inner" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const reduxHook = connect(
    state => ({
        menuData: state.Content.menuData,
        langData: state.Content.lang,
        footerData: state.Content.footerData,
        allNodes: state.Content.allNodes,
        foodDetailUrl: state.Content.foodDetailUrl,
        getFoodDetailData: state.Content.getFoodDetailData,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
);
export default compose(
    withRouter,
    reduxHook,
)(NavList);