// @flow

import {
    FETCH_NODEDATA_SUCCESS,
    GET_MENU,
    GET_FOOTER,
    CHANGE_LANGUAGE_DATA,
    SEND_EMAIL,
    SET_LOADING,
    CLEAR_DATA,
    SEND_MEMBER,
    GET_FOOD_DETAIL,
    FETCH_SPECIAL_BLOCK,
    GET_ALL_NODES,
    GET_FOOTER_STORE,
    CLEAR_PATHNAME,
    GET_FOOD_DETAIL_URL,
    // CLEAR_DETAIL_DATA,
    SEND_CONTACT_US,
    GET_FOOD_DETAIL_HTML,
    GET_DETAIL_LIST_NUM,
} from '../actions/index.js';
import APPCONFIG from '../config/config.js';
  
export default (state = {
    menuData: [],
    footerData: [],
    //lang: JSON.parse(localStorage.getItem('Langurage')) || APPCONFIG.langurage[0],
    lang: APPCONFIG.langurage[0],
    nodeData: {},
    loading: false,
    getFoodDetailData: {},
    specialBlock: {},
    allNodes: {},
    footerStore: [],
    foodDetailUrl: null,
    sendMemberData: null,
    sendEmailData: null,
    sendContactUsData: null,
    foodDetailHtmlData: {},
    detailListNum: null,
}, action) => {
    switch (action.type) {
        case GET_DETAIL_LIST_NUM:
            return {
                ...state,
                detailListNum: action.num,
            };
        case GET_FOOD_DETAIL_HTML:
            return {
                ...state,
                foodDetailHtmlData: { 
                    // ...state.foodDetailHtmlData,
                    [action.name]: action.data,
                },
            };
        case FETCH_NODEDATA_SUCCESS:
            return {
                ...state,
                nodeData: { 
                    ...state.nodeData,
                    [action.route]: action.data,
                },
            };
        case GET_MENU:
            return {
                ...state,
                menuData: action.data.datas,
            };

        case GET_FOOTER:
            return {
                ...state,
                footerData: action.data.datas,
            };
        
        case CHANGE_LANGUAGE_DATA:
            localStorage.setItem('Langurage', JSON.stringify(action.data));
            console.log('content.js - ', JSON.stringify(action.data));
            return {
                ...state,
                lang: action.data,
            }

        case SEND_EMAIL:
            return {
                ...state,
                sendEmailData: action.data,
            }
        case SEND_MEMBER:
            return {
                ...state,
                sendMemberData: action.data,
            }
        case SEND_CONTACT_US:
            return {
                ...state,
                sendContactUsData: action.data,
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.boo,
            }
        
        case CLEAR_DATA:
            return {
                ...state,
                nodeData: {},
                allNodes: {},
                menuData: [],
                footerData: [],
            }
        
        case CLEAR_PATHNAME:
            return {
                ...state,
                nodeData: {
                    ...state.nodeData,
                    [action.pathname]: null,
                },
            }

        case GET_FOOD_DETAIL:
            return {
                ...state,
                getFoodDetailData: {
                    ...state.getFoodDetailData,
                    [action.lang]: action.data,
                },
            }

        case FETCH_SPECIAL_BLOCK:
            return {
                ...state,
                specialBlock: action.data,
            };

        case GET_ALL_NODES:
            return {
                ...state,
                allNodes: action.data,
            };

        case GET_FOOTER_STORE:
            return {
                ...state,
                footerStore: action.data,
            };

        case GET_FOOD_DETAIL_URL:
            return {
                ...state,
                foodDetailUrl: action.url,
            };

        default:
            return state;
    }
};