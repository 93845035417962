import {
    CALCULATION_MAPDATA_DISTANCE,
} from '../actions/Map.js';

const rad = function (x) {
    return x * Math.PI / 180;
};
const getDistance = function (p1, p2) {
    const R = 6378137; // Earth’s mean radius in meter
    const dLat = rad(p2[0] - p1[0]);
    const dLong = rad(p2[1] - p1[1]);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1[0])) * Math.cos(rad(p2[0])) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d; // returns the distance in meter
};

export default (state = {
    "type": "FeatureCollection",
    "features": [
        {
            "geometry": {
                "type": "Point",
                "coordinates": [
                    25.058239,
                    121.548441
                ]
            },
            "type": "Feature",
            "properties": {
                "title": "台北市",
                "city": "台北市松山區",
                "address": "民生東路三段135號",
                "city_en": "Taipei,   Taiwan",
                "phone": "886-2-2545-8888"
            }
        },
        {
            "geometry": {
                "type": "Point",
                "coordinates": [
                    25.083143,
                    121.559481
                ]
            },
            "type": "Feature",
            "properties": {
                "title": "大直, 台北市",
                "city": "台北市中山區",
                "address": "樂群三路301號",
                "city_en": "Taipei City ,  104 Taiwan",
                "phone": "886-2-8501-5900"
            }
        },
        {
            "geometry": {
                "type": "Point",
                "coordinates": [
                    24.156440,
                    120.659602
                ]
            },
            "type": "Feature",
            "properties": {
                "title": "台中",
                "city": "台中市",
                "address": "台灣大道二段501號26樓",
                "city_en": "Section 1,   Taiwan",
                "phone": "886-4-2323-2872"
            }
        },
        {
            "geometry": {
                "type": "Point",
                "coordinates": [
                    22.628268,
                    120.293048
                ]
            },
            "type": "Feature",
            "properties": {
                "title": "高雄",
                "city": "高雄市前金區",
                "address": "中正四路211號25樓之三",
                "city_en": "Kaohsiung,  801 Taiwan",
                "phone": "886-7-241-8888"
            }
        }, {
            "geometry": {
                "type": "Point",
                "coordinates": [
                    35.670710, 139.746634
                ]
            },
            "type": "Feature",
            "properties": {
                "title": "TOKYO - CHIYODA-KU",
                "city": "Chiyoda-ku,Japan",
                "address": "3 -2-6 Kasumigaseki,Tokyo Club Building,101",
                "city_en": "Kaohsiung,  801 Taiwan",
                "phone": "81-3-3501-0822"
            }
        },
    ],
}, action) => {
    switch (action.type) {
        case CALCULATION_MAPDATA_DISTANCE:
            return {
                ...state,
                features: state.features.map(data => ({
                    ...data,
                    distance: getDistance(action.place, data.geometry.coordinates),
                })).sort((a, b) => a.distance - b.distance),
            }

        default:
            return state;
    }
};