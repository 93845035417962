import React, { PureComponent } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form'; 
import Textinput, { regexCheck } from '../conponents/shared/Textinput';
import Textarea from '../conponents/shared/Textarea';
import Radioinput from '../conponents/shared/Radioinput';
import Dropdown from '../conponents/shared/Dropdown';
import { GIFTCARD } from '../shared/form';
import * as indexAction from '../actions/index';
import APPCONFIG from '../config/config.js';
import SpecialBlock from '../conponents/shared/SpecialBlock.jsx';

class GiftCards extends PureComponent<Props> {
    constructor(props) {
        super(props);
        this.state = {
            isMan: false,
            errorMsg: {
                name: '',
                phone: '',
                email: '',
            },
        };
        this.setHintMsg = this.setHintMsg.bind(this);
    }

    componentWillMount() {
        const {
            location: {
                state,
                pathname,
            },
            fetchNodeData,
            nodeData,
            allNodes,
        } = this.props;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    componentDidUpdate(preprops) {
        const {
            location: {
                pathname,
                state,
              },
            fetchNodeData,
            menuData,
            nodeData,
            allNodes,
            footerData,
            sendEmailData,
            sendEmailSuccess,
        } = this.props;
        const contactUs = footerData.find(val => val.route === '/contact-us');
        const mainpPath = pathname.split("/");
        if (state) {
            if(nodeData[`/${mainpPath[2]}`] && nodeData['/reservations'] && !nodeData['/contact-us']) {
                if(!contactUs) return null;
                fetchNodeData(contactUs.url, contactUs.route);
            }
        } else {
            if(footerData.length && menuData.length && allNodes.childNode) {
                if(nodeData[`/${mainpPath[2]}`] && nodeData['/reservations'] && !nodeData['/contact-us']) {
                    fetchNodeData(contactUs.url, contactUs.route);
                } 
                 
            } 
        }
        if(sendEmailData && sendEmailData.statusCode === 0) {
            this.props.reset(GIFTCARD);
            sendEmailSuccess(null);
        }
    }

    postData() {
        const {
            LastName,
            FirstName,
            Phone,
            Email,
            Stores,
            Comments,
            sendEmail,
            nodeData,
            langData,
        } = this.props;
        const lastNameMsg = regexCheck('name', LastName || '');
        const firstNameMsg = regexCheck('name', FirstName || '');
        const phoneMsg = regexCheck('phone', Phone || '');
        const emailMsg = regexCheck('email', Email || '');
        const storeMail = nodeData['/reservations'].datas.find(val => val.title === Stores) || nodeData['/reservations'].datas[0];
        const storeName = Stores || storeMail.title;
        if (lastNameMsg || firstNameMsg || phoneMsg || emailMsg) {
            this.setState({
                errorMsg: {
                    lastName: lastNameMsg,
                    firstName: firstNameMsg,
                    phone: phoneMsg,
                    email: emailMsg,
                },
            })
        } else {
            const post = {
                email: Email,
                store: {
                    name: storeName,
                    email: storeMail.subHtml,
                  },
                comments: Comments,
                name: LastName + ' ' + FirstName,
                phone: Phone,
                sex: '',
                head: langData.emailHead['giftcards'],
            };
            sendEmail(post);
        }
    }

    setHintMsg(name, value) {
        const {
            errorMsg,
        } = this.state;
        this.setState({
            errorMsg: {
                ...errorMsg,
                [name]: value,
            },
        });
    }


    render() {
        const {
            nodeData,
            location: {
                state,
                pathname,
            },
            sendEmailData,
            langData,
        } = this.props;
        const {
            errorMsg,
        } = this.state;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[2]}`] || !nodeData['/reservations'] || !nodeData['/contact-us']) return null;
        const data = nodeData['/reservations'].datas;
        const responseData = nodeData[`/${mainpPath[2]}`];
        const contactPlaceholder = nodeData['/contact-us'].datas[1].datas;
        return (
            <form class="content-warpper" onSubmit={() => this.postData()}>
                <div class="content-sub-warpper">
                    <div class="menber-title" 
                         dangerouslySetInnerHTML={{ __html: responseData && responseData.title }} />
                    <div class="content-input-warpper">
                        <Field
                            regex="name"
                            placeholder={contactPlaceholder[0].title}
                            name="LastName"
                            hintMsg={errorMsg.lastName}
                            setHintMsg={this.setHintMsg}
                            component={Textinput} />
                        <Field
                            regex="name"
                            placeholder={contactPlaceholder[10].title}
                            name="FirstName"
                            hintMsg={errorMsg.firstName}
                            setHintMsg={this.setHintMsg}
                            component={Textinput} />
                        <Field
                            regex="phone"
                            placeholder={contactPlaceholder[3].title}
                            name="Phone"
                            setHintMsg={this.setHintMsg}
                            hintMsg={errorMsg.phone}
                            component={Textinput} />
                        <Field
                            regex="email"
                            placeholder={contactPlaceholder[4].title}
                            name="Email"
                            setHintMsg={this.setHintMsg}
                            hintMsg={errorMsg.email}
                            component={Textinput} />
                        <Field
                            isFullWidth
                            placeholder={contactPlaceholder[6].title}
                            name="Stores"
                            data={data}
                            optiontype="store"
                            component={Dropdown} />
                        <Field
                            placeholder={contactPlaceholder[8].title}
                            name="Comments"
                            component={Textarea} />
                    </div>
                    <div class="content-sub-innner-warp">
                        <div class="content-gray-bt" onClick={() => this.postData()}>{contactPlaceholder[9].title}</div>
                    </div>  
                </div>
                <SpecialBlock pathname={pathname} temp={2} />
            </form>
        );
    }
}

const selector = formValueSelector(GIFTCARD);

const reduxHook = connect(
    state => ({
        LastName: selector(state, 'LastName'),
        FirstName: selector(state, 'FirstName'),
        Phone: selector(state, 'Phone'),
        Email: selector(state, 'Email'),
        Stores: selector(state, 'Stores'),
        Comments: selector(state, 'Comments'),
        Sex: selector(state, 'Sex'),
        nodeData: state.Content.nodeData,
        menuData: state.Content.menuData,
        footerData: state.Content.footerData,
        allNodes: state.Content.allNodes,
        sendEmailData: state.Content.sendEmailData,
        langData: state.Content.lang,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch)
);

const formHook = reduxForm({
    form: GIFTCARD,
    initialValues: {
        LastName: '',
        FirstName: '',
        Phone: '',
        Sex: 'Male',
        Email: '',
        Stores: '',
        Comments: '',
      },
});

export default compose(
    reduxHook,
    formHook,
)(GiftCards);