import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as indexAction from '../../actions/index';

class LET_STAY_IN_TOUCH extends Component {
    render() {
        const {
            specialBlock,
            footerData,
            langData,
        } = this.props;
        const member = footerData.find(val => val.route === '/member');
        if (!member) return null;
        return (
            <div class="content-prev-footer">
                <div class="content-prev-footer-block">
                    <div className="content-sub-title">{specialBlock.title}</div>
                </div>
                <div class="content-prev-footer-blockR">
                    <div class={langData.type === "zh-TW" ? "footer-blockR-inner" : "en-footer-blockR-inner"}>{specialBlock.summary}</div>
                    {/* <Link class="content-prev-footer-bt" alt="member" to={{ pathname: `/${langData.type}${member.route}`, state: {...member} }}>{specialBlock.subHtml}</Link> */}
                    <a class="content-prev-footer-bt" alt="member" href="https://www.google.com.tw/?hl=zh_TW">{specialBlock.subHtml}</a>
                </div>
            </div>
        );
    }
}


class If_you_need extends Component {
    render() {
        const {
            specialBlock,
            langData
        } = this.props;
        return (
            <div class="content-giftcards-prev-footer">
                <div class={langData.type === "zh-TW" ? "content-giftcards-inner" : "en-content-inner"}>
                    {specialBlock.summary}
                </div>
                <div class="content-sub-innner-warp">
                <a class="content-gray-bt" alt="member" href={specialBlock.url} target="_blank">{specialBlock.subHtml}</a>
                </div>
            </div>
        );
    }
}

class Search_all_store extends Component {
    render() {
        const {
            specialBlock,
        } = this.props;
        return (
            <div class="content-reservation-prev-footer">
                <div class="content-sub-title">
                {specialBlock.title}
                </div>
                <div class="content-sub-innner-warp">
                    <a class="content-gray-bt" href={specialBlock.url} target='_blank'>
                        <i class="icon-search-red"></i>
                        <span>{specialBlock.subHtml}</span> 
                    </a>
                </div>
            </div>
        );
    }
}


class SpecialBlock extends Component {

    componentWillMount() {
        const {
            temp,
            fetchSpecialBlock,
        } = this.props;
        fetchSpecialBlock(temp);

    }

    componentDidUpdate(preprops) {
        const {
            pathname,
            temp,
            fetchSpecialBlock,
            nodeData,
            langData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if(preprops.langData !== langData) {
            fetchSpecialBlock(temp);
        }
    }

    content() {
        const {
            temp,
            specialBlock,
            footerData,
            langData,
        } = this.props;
        switch (temp) {
            case 1:
                return (
                    <LET_STAY_IN_TOUCH
                        specialBlock={specialBlock}
                        footerData={footerData}
                        langData={langData} />
                );
            case 2:
                return (
                    <If_you_need 
                        specialBlock={specialBlock}
                        langData={langData} />
                );

            case 3:
                return (
                    <Search_all_store specialBlock={specialBlock} />
                );
        
            default:
                break;
        }
    }

    render() {
        return (
            <Fragment>
                {this.content()}
            </Fragment>
        );
    }
}

const reduxHook = connect(
    state => ({
        specialBlock: state.Content.specialBlock,
        footerData: state.Content.footerData,
        langData: state.Content.lang,
        nodeData: state.Content.nodeData,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch)
);

export default compose(
    reduxHook,
)(SpecialBlock);