import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  compose,
} from 'redux';
import './load.css';

class Loading extends PureComponent<Props> {
    render() {
        const {
            loading,
        } = this.props;
        if (!loading) return null;
        return (
            <div class="lds-spinner-outer">
                <div class="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>

        );
    }
}

const reduxHook = connect(
    state => ({
        loading: state.Content.loading,
    }),
  );

export default compose(
    reduxHook,
)(Loading);