import React, { PureComponent } from 'react';
import '../static/css/html.css';
import { connect } from 'react-redux';
import {
  compose,
  bindActionCreators,
} from 'redux';
import { withRouter } from 'react-router';
import APPCONFIG from '../config/config.js';
import * as indexAction from '../actions/index.js';
import SpecialBlock from '../conponents/shared/SpecialBlock';

class YourPrivacy extends PureComponent<Props> {

    componentWillMount() {
        const {
            location: {
                state,
                pathname,
            },
            fetchNodeData,
            nodeData,
            changeLanguage,
            allNodes,
        } = this.props;
        const mainpPath = pathname.split("/");
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        // if (state) {
        //     if(!nodeData[state.route]) {
        //         fetchNodeData(state.url, state.route);
        //     }
        // } else {
        //     if (allNodes) {
        //         const thisNodeData = allNodes.childNode && allNodes.childNode[0].childNode.find(val => val.route === `/${mainpPath[2]}`);
        //         if(!nodeData[`/${mainpPath[2]}`]) {
        //             if(!thisNodeData) return null;
        //             fetchNodeData(`/${thisNodeData.nodeId}`, thisNodeData.route);
        //         }
                  
        //     }
        // }
    }

    // componentDidUpdate(preprops) {
    //     const {
    //         location: {
    //             pathname,
    //           },
    //         allNodes,
    //         fetchNodeData,
    //         nodeData,
    //     } = this.props;
    //     const mainpPath = pathname.split("/");
    //     if (!nodeData[`/${mainpPath[2]}`] || preprops.allNodes !== allNodes) {
    //         if(!allNodes.childNode) return null;
    //         const thisNodeData = allNodes.childNode[0].childNode.find(
    //              val => val.pageTitle === 'FOOTER'
    //             );
    //         const mainNodeData = thisNodeData.childNode.find(val => val.route === `/${mainpPath[2]}`);
    //          fetchNodeData(`/${mainNodeData.nodeId}`, mainNodeData.route);
    //     }
    // }

    render() {
        const {
            nodeData,
            location: {
                pathname,
            },
            langData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[2]}`]) return null;
        const responseData = nodeData[`/${mainpPath[2]}`];
        return (
            <div class="content-warpper">
                <div class="content-sub-warpper">
                    <div class={langData.type === "zh-TW" ? "privacy-inner" : "en-privacy-inner"}
                        dangerouslySetInnerHTML={{ __html: responseData.html }}
                    />
                 </div>
                <SpecialBlock pathname={pathname} temp={1} />
            </div>
        );
    }
}

const reduxHook = connect(
    state => ({
        nodeData: state.Content.nodeData,
        allNodes: state.Content.allNodes,
        langData: state.Content.lang,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
  );

export default compose(
    withRouter,
    reduxHook,
)(YourPrivacy);