import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  compose,
  bindActionCreators,
} from 'redux';
import * as indexAction from '../actions/index.js';
import { withRouter } from 'react-router';

class HomeContent3 extends PureComponent<Props> {
    componentWillMount() {
        const {
            fetchSpecialBlock,
        } = this.props;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        fetchSpecialBlock(1);
    }

    
    render() {
        const {
            location: {
                pathname,
              },
            nodeData,
            menuData,
            specialBlock,
            langData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[1]}`]) return null;
        const responseData = nodeData[`/${mainpPath[1]}`].datas;
        const reservations = menuData.find(val => val.route === '/reservations');
        return (
            <div class="content-warpper home3-background-color">
                <div class="home3-conetent1-background-color">
                    <div class="home2-inner-title-warpper">
                        <div class="content-title">{responseData[0].datas[0].title}</div>
                    </div>
                    {/* Technique */}
                    <div class="content-inner-warpper home3-conetent1-wrap">
                        <div class="home3-conetent1" style={{backgroundImage: `url(${responseData[0].datas[0].datas[3] ? responseData[0].datas[0].datas[3].url : ''})`}}>
                            <div class="home3-conetent1-text">
                                <div class="home3-conetent1-italic">{responseData[0].datas[0].datas[0].summary}</div>
                                <div class="home3-conetent1-title">{responseData[0].datas[0].datas[0].title}</div>
                                <div class="home3-conetent1-inner">{responseData[0].datas[0].datas[0].html}</div>
                            </div>
                        </div>
                    {/* Ingredients */}
                        <div class="home3-conetent1" style={{backgroundImage: `url(${responseData[0].datas[0].datas[0].url})`}}>
                            <div class="home3-conetent1-text">
                                <div class="home3-conetent1-italic">{responseData[0].datas[0].datas[1] ? responseData[0].datas[0].datas[1].summary : ''}</div>
                                <div class="home3-conetent1-title">{responseData[0].datas[0].datas[1] ? responseData[0].datas[0].datas[1].title : ''}</div>
                                <div class="home3-conetent1-inner">{responseData[0].datas[0].datas[1] ? responseData[0].datas[0].datas[1].html : ''}</div>
                            </div>
                        </div>
                    {/* Experience */}
                        <div class="home3-conetent1" style={{backgroundImage: `url(${responseData[0].datas[1].url})`}}>
                            <div class="home3-conetent1-text">
                                <div class="home3-conetent1-italic">{responseData[0].datas[1].datas[0].summary}</div>
                                <div class="home3-conetent1-title">{responseData[0].datas[1].datas[0].title}</div>
                                <div class="home3-conetent1-inner">{responseData[0].datas[1].datas[0].html}</div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* THE PERFECT PAIRING */}
                <div class="content-inner-warpper">
                    <div class="content-title">{responseData[0].datas[1].title}</div>
                </div>
                {/* TASTING NOTES */}
                <div class="content-sub-innner-warp home3-conetent2-wrap">
                    <div class="home3-conetent2-left">
                        <div class="home3-conetent2-left-box">
                            <div class="home3-conetent2-italic">{responseData[0].datas[2].datas[1] ? responseData[0].datas[2].datas[1].summary : ''}</div>
                            <div class="home3-conetent2-title">{responseData[0].datas[2].datas[1] ? responseData[0].datas[2].datas[1].title : ''}</div>
                            <div class="home3-conetent2-text">
                            {responseData[0].datas[2].datas[1] ? responseData[0].datas[2].datas[1].html : ''}
                            </div>
                        </div>
                    </div>
                    {/* HUNEEUS FAMILY WINERY */}
                    <div class="home3-conetent2-middle" style={{backgroundImage: `url(${responseData[0].datas[2].datas[1] ? responseData[0].datas[2].datas[1].url : ''})`}}></div>
                    <div class="home3-conetent2-right">
                        <div class="home3-conetent2-italic" style={{color: "#8f8f8f"}}>{responseData[0].datas[2].datas[1] ? responseData[0].datas[2].datas[0].summary : ''}</div>
                        <div class="home3-conetent2-title" style={{paddingBottom: '10px'}}>{responseData[0].datas[2].datas[0] ? responseData[0].datas[2].datas[0].title : ''}</div>
                        <div class="home3-conetent2-right-img" style={{backgroundImage: `url(${responseData[0].datas[2].datas[0] ? responseData[0].datas[2].datas[0].url : ''})`}}></div>
                    </div>
                </div>
                {/* STAGS' LEAP WINE DINNER */}
                <div class="content-sub-innner-warp home3-conetent3-wrap" style={{backgroundImage: `url(${responseData[0].datas[3].datas[0] && responseData[0].datas[3].datas[0].url})`}}>
                    <div class="overlay" />
                    <div class="home3-conetent3-box" />
                    <div class="home3-conetent3-inner-wrap">
                        <div class="home3-conetent3-title">{responseData[0].datas[3].title}</div>
                        
                            <button class="home3-conetent3-button">
                            <Link to={{ pathname: `/${langData.type}/drink`, hash:'#Cocktails'}}>
                                {responseData[0].datas[3].achor}
                            </Link>
                            </button>
                        
                    </div>
                </div>

                <div class="content-inner-bottom-warpper home3-conetent4-wrap">
                    <div class="home3-conetent4-title">{specialBlock.title}</div>
                    <div class="home3-conetent2-text">{specialBlock.summary}</div>
                    <Link><i class="icon-arrow-red" /></Link>
                </div>
            </div>
        );
    }
}
const reduxHook = connect(
    state => ({
        nodeData: state.Content.nodeData,
        menuData: state.Content.menuData,
        specialBlock: state.Content.specialBlock,
        footerData: state.Content.footerData,
        langData: state.Content.lang,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
  );

export default compose(
    withRouter,
    reduxHook,
)(HomeContent3);