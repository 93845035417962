import React, { PureComponent } from 'react';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import {
  compose,
  bindActionCreators,
} from 'redux';
import * as indexAction from '../actions/index.js';
import { withRouter } from 'react-router';
import SpecialBlock from '../conponents/shared/SpecialBlock.jsx';
import feature_lobster from '../static/feature_lobster.jpg';
import feature_steak from '../static/feature_steak.jpg';
import tile_diagram from '../static/tile_diagram.png';
import food_detail03 from '../static/food-detail-03.svg';
import huneeus_small from '../static/huneeus-small.jpg';
import feature_faust from '../static/feature_faust.jpg';
import contentBanner from '../static/stagsleaphero.jpg';
import Loading from '../conponents/Loading/loading';


class HomeContent extends PureComponent<Props> {
    componentWillMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    
    render() {
        const {
            location: {
                pathname,
              },
            nodeData,
            menuData,
            langData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[1]}`]) return null;
        
        const responseData = nodeData[`/${langData.type}`].datas;
        const foodData = menuData.find(val => val.route === '/food-drink');
        return (
            <div class="content-warpper">
                <div class="content-inner-title-warpper">
                    <div class="content-title">{responseData[0].datas[0].title}</div>
                </div>
                <div class="content-inner-warpper home-content-warp">
                    <div class="home-content-left-warp">
                        <div class="home-left-innerWarp">
                        <div class="home-inner-text-italic">{responseData[0].datas[0].datas[0].summary}</div>
                        <div class="content-home-sub-title">{responseData[0].datas[0].datas[0].title}</div>
                        <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{responseData[0].datas[0].datas[0].html}</div>
                        <div class="content-sub-innner-warp">
                            <Link class="content-home-bt" to={{ pathname: `/${langData.type}/food`, hash:'#STEAKS'}}>{responseData[0].datas[0].datas[0].achor}</Link>
                        </div>
                        </div>
                        {/* <div class="content-sub-innner-warp  display-block">
                            <div class="gray-line"></div>
                        </div> */}
                        <div class="home-content-img" style={{ backgroundImage: `url(${responseData[0].datas[0].datas[3] ? responseData[0].datas[0].datas[3].url : ''})`,backgroundSize: 'cover'}}></div>
                    </div>
                   
                        {/* <div class="gray-line"></div> */}
                    
                    <div class="home-content-center">
                        <div class="content-subTitle-red">{responseData[0].datas[0].datas[2] ? responseData[0].datas[0].datas[2].title : ''}</div>
                        {/* <div class="gray-line-horizontal"></div> */}
                        <div class="home-block-style"></div>
                        <div class="content-subTitle-red-center">{responseData[0].datas[0].datas[3] ? responseData[0].datas[0].datas[3].title : ''}</div>
                        {/* <div class="gray-line-horizontal"></div> */}
                        <div class="home-block-style"></div>
                        <div class="content-subTitle-red">{responseData[0].datas[0].summary}</div>
                    </div>

                        {/* <div class="gray-line"></div> */}

                    <div class="home-content-right-warp">
                        <div class="home-content-img" style={{ backgroundImage: `url(${responseData[0].datas[0].datas[2] ? responseData[0].datas[0].datas[2].url : ''})`,backgroundSize: 'cover'}}></div>
                        <div class="home-content-img-cow" style={{ backgroundImage: `url(${food_detail03})`}}></div>
                        <div class="home-inner-text-italic">{responseData[0].datas[0].datas[0].summary}</div>
                        <div class="content-home-sub-title">{responseData[0].datas[0].datas[1] ? responseData[0].datas[0].datas[1].title : ''}</div>
                        <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{responseData[0].datas[0].datas[1].html}</div>
                    </div>
                </div>

                <div class="content-inner-title-warpper">
                    <div class="content-title">{responseData[0].datas[1].title}</div>
                </div> 

                <div class="content-inner-warpper home-content-warp">
                    <div class="home-content-left-warp">
                        <div class="home-content-img home-feature-faust" style={{ backgroundImage: `url(${responseData[0].datas[2].url})`}}></div>   
                        <div class="content-sub-innner-warp display-block">
                            <div class="gray-line"></div>
                        </div>
                        <div class="home-inner-text-italic">{responseData[0].datas[2].datas[1] ? responseData[0].datas[2].datas[1].summary : ''}</div>
                        <div class="content-home-sub-title">{responseData[0].datas[2].datas[1] ? responseData[0].datas[2].datas[1].title : ''}</div>
                        <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{responseData[0].datas[2].datas[1] ? responseData[0].datas[2].datas[1].html : ''}</div>
                    </div>
                    {/* <div class="gray-line display-none"></div> */}
                    <div class="content-sub-innner-warp display-block">
                        <div class="gray-line"></div>
                    </div>
                    <div class="home-content-rightup-warp">
                        <div class="home-rightup-innerWarp">
                            <div class="home-inner-text-italic">{responseData[0].datas[1].datas[0].summary}</div>
                            <div class="content-home-sub-title">{responseData[0].datas[1].datas[0].title}</div>
                            <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{responseData[0].datas[1].datas[0].html}</div>
                            <div class="content-sub-innner-warp">
                                <Link class="content-home-bt" to={`/${langData.type}/drink#Cocktails`}>{responseData[0].datas[1].datas[0].achor}</Link>
                            </div>
                        </div>
                        {/* <div class="content-sub-innner-warp display-block">
                            <div class="gray-line"></div>
                        </div> */}
                        <div class="home-inner-text-italic">{responseData[0].datas[2].datas[0] ? responseData[0].datas[2].datas[0].summary : ''}</div>
                        <div class="content-home-sub-title">{responseData[0].datas[2].datas[0] ? responseData[0].datas[2].datas[0].title : ''}</div>
                        <div class="home-content-img home-huneeus-small" style={{ backgroundImage: `url(${responseData[0].datas[2].datas[0] ? responseData[0].datas[2].datas[0].url : ''})`}}></div> 
                    </div>
                </div>
                <div class="home-content-banner">
                    <div class="home-banner-blackCurtain"></div>
                    <div class="home-banner-cut">
                        <div class="home-banner-cutSide"></div>
                        <div class="home-banner-cutMiddle"></div>
                        <div class="home-banner-cutSide"></div>
                    </div>
                    <div class="home-banner-img" style={{backgroundImage: `url(${responseData[0].datas[3].url})`}}></div>
                    <div class="home-banner-container">
                        <div class="content-sub-innner-warp">
                            <div class="home-content-sub-title">{responseData[0].datas[3].title}</div> 
                        </div>
                        <div class="home-content-inner-text">{responseData[0].datas[3].summary}</div>
                        <div class="content-sub-innner-warp">
                            <Link class="content-home-white-bt" to={`/${langData.type}${responseData[0].datas[3].address}`}>{responseData[0].datas[3].achor}</Link>
                        </div>
                    </div>
                </div>
                <SpecialBlock pathname={pathname} temp={1} />
            </div>
        );
    }
}

const reduxHook = connect(
    state => ({
        nodeData: state.Content.nodeData,
        menuData: state.Content.menuData,
        allNodes: state.Content.allNodes,
        langData: state.Content.lang,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
  );

export default compose(
    withRouter,
    reduxHook,
)(HomeContent);