import Axios from '../shared/Api.js';

export const FETCH_NODEDATA_SUCCESS = 'FETCH_NODEDATA_SUCCESS';
export const GET_MENU = 'MENU/GET_MENU';
export const GET_FOOTER = 'FOOTER/GET_FOOTER';
export const CHANGE_LANGUAGE_DATA = 'CHANGE_LANGUAGE_DATA';
export const SEND_EMAIL = 'SEND_EMAIL';
export const SET_LOADING = 'SET_LOADING';
export const CLEAR_DATA = 'CLEAR_DATA';
export const SEND_MEMBER = 'SEND_MEMBER';
export const GET_FOOD_DETAIL = 'GET_FOOD_DETAIL';
export const FETCH_SPECIAL_BLOCK = 'FETCH_SPECIAL_BLOCK';
export const GET_ALL_NODES = 'GET_ALL_NODES';
export const GET_FOOTER_STORE = 'GET_FOOTER_STORE';
export const CLEAR_PATHNAME = 'CLEAR_PATHNAME';
export const GET_FOOD_DETAIL_URL = 'GET_FOOD_DETAIL_URL';
export const SEND_CONTACT_US = 'SEND_CONTACT_US';
export const GET_FOOD_DETAIL_HTML = 'GET_FOOD_DETAIL_HTML';
export const GET_DETAIL_LIST_NUM = 'GET_DETAIL_LIST_NUM';

export function getDetailListNum(num) {
    return { type: GET_DETAIL_LIST_NUM , num }
}

export function setLoading(boo) {
    return { type: SET_LOADING , boo }
}

export function clearData() {
    return { type: CLEAR_DATA }
}

export function clearPathname(pathname) {
    return { type: CLEAR_PATHNAME ,pathname }
}

export function getFoodDetailUrl(url) {
    return { type: GET_FOOD_DETAIL_URL ,url }
}

export function getFoodDetailHtml(data,name) {
    return { type: GET_FOOD_DETAIL_HTML ,data,name }
}

// /api/Node/GetNodeData 取得節點內容
export function fetchNodeData(data, route) {
    const APIURL = `/api/Node/GetNodeData?TopNodeID=&Language=&Url=${data}`;
    return (dispatch) => {
        dispatch(setLoading(true));
        Axios.post(APIURL).then(res => {
            if (res.data.statusCode === 0) { 
                dispatch(fetchNodeDataSuccess(res, route));
                dispatch(setLoading(false));
            }
        })
        .catch(err => {
            dispatch(setLoading(false));
        })
    }
}
export function fetchNodeDataSuccess(res, route) {
    return { type: FETCH_NODEDATA_SUCCESS , ...res.data, route }
}


export function getFooter(data) {
    const APIURL = `/api/Node/GetFooter?TopNodeID=&Language=&Url=${data}`;
    return (dispatch) => {
        dispatch(setLoading(true));
        Axios.post(APIURL, data).then(res => {
            if (res.data.statusCode === 0) dispatch(getFooterSuccess(res));
            dispatch(setLoading(false));
        })
        .catch(err => {
            dispatch(setLoading(false));
        })
    }
}
export function getFooterSuccess(res) {
    return { type: GET_FOOTER , ...res.data }
}


export function getMenu(data) {
    const APIURL = `/api/Node/GetMenu?TopNodeID=&Language=&Url=${data}`;
    return (dispatch) => {
        dispatch(setLoading(true));
        Axios.post(APIURL, data).then(res => {
            if (res.data.statusCode === 0) dispatch(getMenuSuccess(res));
            dispatch(setLoading(false));
        })
        .catch(err => {
            dispatch(setLoading(false));
        })
    }
}
export function getMenuSuccess(res) {
    return { type: GET_MENU , ...res.data }
}

export function changeLanguage(data) {
    return { type: CHANGE_LANGUAGE_DATA , data }
}

export function sendEmail(data) {
    const APIURL = '/api/Email/Send';
    return (dispatch) => {
        dispatch(setLoading(true));
        Axios.post(APIURL, data).then(res => {
            if (res.data.statusCode === 0) {
                dispatch(sendEmailSuccess(res.data));
                alert(res.data.data);
                dispatch(setLoading(false));
            }
        })
        .catch(err => {
            dispatch(setLoading(false));
        })
    }
}
export function sendEmailSuccess(data) {
    return { type: SEND_EMAIL , data }
}

export function sendMember(data) {
    const APIURL = '/api/Email/SendMember';
    return (dispatch) => {
        dispatch(setLoading(true));
        Axios.post(APIURL, data).then(res => {
            if (res.data.statusCode === 0) {
                dispatch(sendMemberSuccess(res.data));
                alert(res.data.data);
                dispatch(setLoading(false));
            }
        })
        .catch(err => {
            dispatch(setLoading(false));
        })
    }
}
export function sendMemberSuccess(data) {
    return { type: SEND_MEMBER , data }
}

export function sendContactUs(data) {
    const APIURL = '/api/Email/SendContactUs';
    return (dispatch) => {
        dispatch(setLoading(true));
        Axios.post(APIURL, data).then(res => {
            if (res.data.statusCode === 0) {
                dispatch(sendContactUsSuccess(res.data));
                alert(res.data.data);
                dispatch(setLoading(false));
            }
        })
        .catch(err => {
            dispatch(setLoading(false));
        })
    }
}
export function sendContactUsSuccess(data) {
    return { type: SEND_CONTACT_US , data }
}

export function getFoodDetail(data, lang) {
    return { type: GET_FOOD_DETAIL, data , lang}
}

export function fetchSpecialBlock(data) {
    const APIURL = `/api/Node/GetOtherArea?TopNodeID=&Language=&Type=${data}`;
    return (dispatch) => {
        dispatch(setLoading(true));
        Axios.post(APIURL, data).then(res => {
            if (res.data.statusCode === 0) { 
                dispatch(fetchSpecialBlockSuccess(res));
                dispatch(setLoading(false));
            }
        })
        .catch(err => {
            dispatch(setLoading(false));
        })
    }
}
export function fetchSpecialBlockSuccess(res) {
    return { type: FETCH_SPECIAL_BLOCK , ...res.data }
}

export function getAllNodes() {
    const APIURL = '/api/Node/GetAllNodes?TopNodeID=&Language=';
    return (dispatch) => {
        dispatch(setLoading(true));
        Axios.get(APIURL).then(res => {
            if (res.data.statusCode === 0) { 
                dispatch(getAllNodesSuccess(res));
                dispatch(setLoading(false));
            }
        })
        .catch(err => {
            dispatch(setLoading(false));
        })
    }
}

export function getAllNodesSuccess(res) {
    return { type: GET_ALL_NODES , ...res.data }
}

export function getFooterStore(data) {
    return { type: GET_FOOTER_STORE, data}
}