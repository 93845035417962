import React, { PureComponent } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import NavList from '../conponents/NavList.jsx';
import HeaderTop from '../conponents/HeaderTop.jsx';
import IndexContent from './HomeContent.jsx';
import Header from '../conponents/Header.jsx';
import Footer from '../conponents/shared/Footer.jsx';
import '../static/sass/style.scss';

const theme = {
    backgroundColor: '#f3f3eb',
}

const StyleWarpper = styled.div`
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    background-color: ${props => props.theme.backgroundColor};
    font-family: 'Trade-Gothic-Bold-Font' , '微軟正黑體', sans-serif;
    -webkit-font-smoothing: antialiased;
`;

const StyleGoTop = styled.div`
    position: fixed;
    bottom: 0;
    right: 30px;
    color: #333;
    font-weight: bold;
    letter-spacing: .02em;
    font-size: 1em;
    cursor: pointer;
    background-color: #fff;
    padding: 20px 15px;
    text-align: center;
`;


class Home extends PureComponent<Props> {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <StyleWarpper>
                    <HeaderTop />
                    <NavList />  
                    <Header />
                    <IndexContent />
                    <Footer />
                    <StyleGoTop>
                        <p>BACK</p>
                        <p>TO TOP</p>
                    </StyleGoTop>
                </StyleWarpper>
            </ThemeProvider>
        );
    }
}

export default Home;