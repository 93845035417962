import React, { Component } from 'react';
import styled from 'styled-components';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as indexAction from '../actions/index.js';
import APPCONFIG from '../config/config.js';
import ReactPlayer from 'react-player';

import fb from '../static/fb-gray.svg';
import IG from '../static/IG-gray.svg';
import userGray from '../static/icon_account_hover-01.svg';
import Logo from '../static/ruthschris_global_logo.png';

import icon1 from '../static/icon1.svg';
import icon2 from '../static/icon2.svg';
import icon3 from '../static/icon3.svg';
import icon4 from '../static/icon4.svg';

import './../static/css/banner.css';

const StyleBanner = styled.div`
    position: relative;
    width: 100%;
    background-image: url('${props => props.imgUrl}');
    height: 700px;
    background-size: cover;
    background-position: center;
    @media (max-width: 1400px) {
        height: 550px;
    }
    @media (max-width: 768px) {
        height: 336px;
    }
    @media (max-width: 414px) {
        height: 200px;
    }
`;

class Header3 extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    setLang(data) {
        const {
            location: {
                pathname,
            },
            match: {
                params: {
                    mainId,
                    subId,
                }            
            },
            langData,
            changeLanguage,
            getMenu,
            getFooter,
            getAllNodes,
            clearData,
            history,
        } = this.props;
        if (langData.type !== data.type) {
            const mainpPath = pathname.split("/");
            if(mainpPath[2]) {
                if(mainId && subId) {
                    history.replace(`/${data.type}/${mainpPath[2]}/${mainId}/${subId}`);
                } else if (mainId && !subId) {
                    history.replace(`/${data.type}/${mainpPath[2]}/${mainId}`);
                } else {
                    history.replace(`/${data.type}/${mainpPath[2]}`);
                } 
            } else {
                history.replace(`/${data.type}`);
            } 
            changeLanguage(data);
            clearData();
            getMenu('');
            getAllNodes();
            getFooter(''); 
        }
    }

    // componentDidUpdate(prevProps) {
    //     const {
    //         getMenu,
    //         getFooter,
    //         langData,
    //     } = this.props;
    //     if (prevProps.langData !== langData) {
    //         getMenu('');
    //         getFooter('');
    //     }
    // }

    getHeaderIcon(number) {
        switch (number) {
            case "1":
                // 雕花
                return <img class="header-icon" src={`${icon1}`} alt="icon"/>;
            case "2":
                // 禮物盒
                return <img class="header-icon" src={`${icon2}`} alt="icon"/>;
            case "3":
                // 牛排
                return <img class="header-icon" src={`${icon3}`} alt="icon"/>;
            case "4":
                // 酒杯
                return <img class="header-icon" src={`${icon4}`} alt="icon"/>;
            default:
                // 雕花
                return null;
        }
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        const {
            langData,
            nodeData,
            location: {
                pathname,
                state,
            },
            footerData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[1]}`]) return null;
        const responseData = nodeData[`/${mainpPath[1]}`];
        if (!responseData) return null;
        const member = footerData.find(val => val.route === '/member');
        return (
            <div class="header3-warpper">
                {/* <div class="overlay"></div>
                {responseData.map(data => (
                <div class="header-container">
                    {data.icon ? <img class="header-icon" src={`${this.getHeaderIcon(data.icon)}`} alt="icon"/> : null}
                    <div class="header-title">{data.title}</div>
                    <div class="header-inner-text">{data.summary}</div>
                </div>))}
                <Slider {...settings}>
                    {responseData.map(data => (
                        <StyleBanner key={data} imgUrl={data.url} />
                    ))}
                </Slider> */}
                {responseData.isVideo ?
                    <Slider {...settings}>
                        {responseData.banners.map(data => (
                            <div class="header-video-wrap">
                                <div class="overlay"></div>
                                    <div class="header-container">
                                        {this.getHeaderIcon(data.icon)}
                                        {data.title && <div class="header-title">{data.title}</div>}
                                        {data.summary && <div class="header-inner-text" dangerouslySetInnerHTML={{ __html: data.summary }}/>}
                                    </div>
                                    <ReactPlayer
                                    width="100%"
                                    height="56.3vw"
                                    url={data.url}
                                    loop={true}
                                    playing={true}
                                    muted={true}
                                    config={{
                                        youtube: {
                                          playerVars: { modestbranding: 1 }
                                        }
                                      }}
                                    />
                                    {/* <video class="video-size" loop="true" autoplay="autoplay" controls={false} id="vid" muted>
                                    <source type="video/mp4" src={data.url}></source>  
                                    </video>  */}
                            </div>
                        ))}
                         
                    </Slider>
                :
                    <Slider {...settings}>
                        {responseData.banners.map(data => (
                            <StyleBanner key={data} imgUrl={data.url}>
                                <div class="overlay"></div>
                                <div class="header-container">
                                    {this.getHeaderIcon(data.icon)}
                                    {data.title && <div class="header-title">{data.title}</div>}
                                    {data.summary && <div class="header-inner-text" dangerouslySetInnerHTML={{ __html: data.summary }}/>}
                                </div>
                            </StyleBanner> 
                        ))}
                    </Slider>  
                 } 

                {/* <div class="header2-booking">
                    <div class="header2-booking-img" style={{ backgroundImage: `url(${bookingImg})`}}/>
                    <div class="header2-booking-text">RESERVATIONS</div>
                </div> */}
                <div class="header2-right-nav">
                    <Link to={{ pathname: `/${langData.type}${member && member.route}`, state: {...member} }}><img class="header2-icon" src={`${userGray}`} alt="member"/></Link>
                    <a href={langData.fb} target='_blank'><img class="header2-icon" src={`${fb}`} alt="fb"/></a>
                    <a href={langData.ig} target='_blank'><img class="header2-icon" src={`${IG}`} alt="IG"/></a>
                    {APPCONFIG.langurage.filter(data => data.type !== langData.type).map(data => (
                        <div class="header3-icon-text" onClick={() => this.setLang(data)}>{data.name}</div>
                    ))}
                </div>
            </div>
        );
    }
}

const reduxHook = connect(
    state => ({
        langData: state.Content.lang,
        nodeData: state.Content.nodeData,
        footerData: state.Content.footerData,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
);
export default reduxHook(Header3);