import React, { PureComponent } from 'react';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux';
import {
  compose,
  bindActionCreators,
} from 'redux';
import { withRouter } from 'react-router';
import * as indexAction from '../actions/index.js';

import iconFleur from '../static/fleur-gray.svg';
import dividerCenter from '../static/divider-center.png';
import dividerLeft from '../static/divider-left.png';
import dividerRight from '../static/divider-right.png';
import dots from '../static/dots.png';



const StyleHappyHourImg = styled.div`
    width: 80%;
    height: 550px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: 1023px) {
        width: 90%;
        height: 350px;
    }
`;

const StyleHappyHourImgFull = styled.div`
    width: 100%;
    height: 550px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: 1023px) {
        height: 350px;
    }
`;

const StyleDividingLine = styled.div`
    width: 1160px;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: 1366px) {
        width: 90%;
      }
    @media (max-width: 414px) {
        margin: 35px auto;
    }
`;

const StyleDividingText = styled.div`
    width: 100%;
    background: url('${dividerLeft}') 0 .5rem/1.9375rem .375rem no-repeat,
                url('${dividerRight}') 100% .5rem/1.9375rem .375rem no-repeat,
                url('${dividerCenter}') 0 .5rem/.0625rem .375rem repeat-x;
    background-position: 0 .75rem,100% .75rem,0 .75rem;
    background-size: 4rem .75rem,4rem .75rem,.0625rem .75rem;
    @media (max-width: 768px) {
        background: url('${dividerLeft}') 0 .5em/2.5em .6em no-repeat,
        url('${dividerRight}') 100% .5em/2.5em .6em no-repeat,
        url('${dividerCenter}') 0 .5em/1em .6em repeat-x;
    }
`;

const StyleIconFleur = styled.div`
    width: 35px;
    height: 38px;
    margin-bottom: 10px;
    background-image: url('${iconFleur}');
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 414px) {
        width: 25px;
        height: 26px;
    }
`;

const StyleContentLunchList = styled.div`
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    width: 1160px;
    margin: 50px auto 0;
    @media (max-width: 1366px) {
        width: 90%;
      }
`;

const StyleLunchItem = styled.div`
    width: ${props => props.length > 3 ? "20%" : "28%"};
    margin: 1% 2.5% 4%;
    color: #5d5d5d;
    text-align: center;
    color: #868686;
    @media (max-width: 1023px) {
        width: 100%;
        text-align: start;
        margin: 0 0 35px;
      }
    @media (max-width: 768px) {
        margin-bottom: 15px;
    }
`;

const StyleLunchItemTitle = styled.div`
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    letter-spacing: -1px;
    font-size: 1.8rem;
    line-height: 1.8rem;
    display: block;
    color: #5d5d5d;
    text-align: center; 
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: .5rem;
    @media (max-width: 1023px) {
        text-align: start; 
      }
    @media (max-width: 768px) {
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin-bottom: 0;
    }
    @media (max-width: 414px) {
        font-size: 1.2rem;
        font-size: 1.2rem;
      }
`;

const StyleHappyHourItemTitle = styled.div`
    font-family: 'Trade Gothic',"Microsoft JhengHei","微軟正黑體";
    letter-spacing: -1px;
    font-size: 1.8rem;
    line-height: 1.8rem;
    display: block;
    color: #5d5d5d;
    text-transform: uppercase;
    text-decoration: none;
    @media (max-width: 1023px) {
        text-align: start; 
      }
      @media (max-width: 768px) {
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin-bottom: 0;
    }
    @media (max-width: 414px) {
        font-size: 1.2rem;
        font-size: 1.2rem;
      }
`;

const StyleImg = styled.div`
    width: 100%;
    height: 550px;
    margin: 0 auto 120px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: 1366px) {
        width: 100%;
        margin: 0 auto 70px;
      }
    @media (max-width: 768px) {
        height: 350px;
    }
    @media (max-width: 414px) {
        margin: 0 auto 35px;
    }
`;

const StyleSwirlItem = styled.div`
    margin: 0 0 30px;
    color: #5d5d5d;
    @media (max-width: 414px) {
        margin: 0 0 15px;
      }
`;

const StyleDividingType = styled.div`
    height: 1em;
    background: url('${dots}') 50% 0 repeat-x;
    background-size: 30px 4px;
    display: flex;
    justify-content: center;
    margin: 3rem 0px 4.5rem;
    @media (max-width: 414px) {
        margin: 2rem 0px 30px;
    }
`;

const StyleIconFleurWep = styled.div`
    width: 60px;
    height: 35px;
    background-color: #f3f2eb;
    display: flex;
    justify-content: center;
    position: relative;
    top: -1em;
`;

const StyleContentImg = styled.div`
    width: 1160px;
    height: 550px;
    margin: 0 auto 120px;
    background-image: url('${props => props.imgUrl}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: 1366px) {
        width: 90%;
        margin: 0 auto 70px;
      }
    @media (max-width: 414px) {
        margin: 0 auto 35px;
        height: 350px;
    }
`;

const StyleContentSpecialtyList = styled.div`
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    width: 1160px;
    margin: 0 auto 40px;
    @media (max-width: 1366px) {
        width: 90%;
      }
`;

const StyleSpecialtytItem = styled.div`
    width: 22%;
    margin: 1% 3% 4% 0;
    padding: 25px 0;
    color: #5d5d5d;
    color: #868686;
    @media (max-width: 1023px) {
        width: 100%;
        text-align: start;
        margin: 0 0 35px;
      }
    @media (max-width: 768px) {
        margin: 0 0 20px;
        padding: 0px;
    }
    @media (max-width: 414px) {
        margin-bottom: 10px;
    }
`;

const StyleTextTitleLink = styled(Link)`
    display: block;
    color: #be2f37;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: -1px;
    margin-bottom: .5rem;
    >span >i {
        width: 20px;
        height: 16px;
        position: absolute;
        transition: left .5s;
        left: .7rem;
        top: 20%;
        transform: translate(-50%, -50%) rotate(-90deg);
    }
    >span {
        position: relative;
    }
    &:hover {
        >span >i {
            left: 1rem; 
        }
    }
    @media (max-width: 1366px) {
        font-size: 1.5rem;
        >span >i {
            width: 18px;
            height: 14px;
            top: 25%;
        }
      }
      @media (max-width: 1280px) {
        font-size: 1.3rem;
      }
    @media (max-width: 1023px) {
        text-align: start; 
      }
    @media (max-width: 768px) {
        font-size: 1.5rem; 
        >span >i {
            width: 20px;
            height: 16px;
            top: 15%;
        }
      }
      @media (max-width: 414px) {
        letter-spacing: 0;
        font-size: 1rem;
        margin-bottom: 2px;
        >span >i {
            width: 12px;
            height: 10px;
        }
      }
`;

const StyleTextTitle = styled.div`
    display: block;
    color: #be2f37;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: .5rem;
      @media (max-width: 1280px) {
        font-size: 1.3rem;
      }
    @media (max-width: 1023px) {
        text-align: start; 
      }
      @media (max-width: 414px) {
        font-size: 1rem;
        line-height: 1.2rem;
        margin-bottom: 2px;
      }
`;

class HappyHour extends PureComponent<Props> {
    componentWillMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera 
    }
    render() {
        const {
            nodeData,
            location: {
                pathname,
            },
            langData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[2]}`]) return null;
        const Datas = nodeData[`/${mainpPath[2]}`].datas;
        const responseData = Datas.filter(val => val.title !== 'Dynamic Area');
        const dynamicData = Datas.filter(val => val.title === 'Dynamic Area');
        const FoodData = responseData[0].datas[0].datas.filter(val => val.isArticle === true);
        return (
            <div class="content-warpper">
                <div class="content-inner-title-warpper">
                    <div class="en-content-sub-title-inner">{responseData[0].summary}</div>
                    <div class="lunch-block"></div>
                    <div id={responseData[0].achor || null } /> 
                    <div class="lunch-content-italic">{responseData[0].html}</div>
                </div>

                {/* food */}
                {responseData[0] && responseData[0].datas[0] && <div class="content-inner-warpper">
                    {responseData[0].datas[0].title && <div class="content-title">{responseData[0].datas[0].title}</div>}
                    {responseData[0].datas[0].summary && <div class="en-content-sub-title-inner">{responseData[0].datas[0].summary}</div>}
                </div>}

                <StyleHappyHourImg style={{backgroundImage: `url('${responseData[0].datas[0].url}')`}}/>

                {FoodData.map(val =>(
                <div>
                    <StyleDividingLine>
                        <StyleIconFleur />
                        <StyleDividingText>
                            <div class="dividing-inner-text">{val.title}</div>
                        </StyleDividingText>
                    </StyleDividingLine>

                    <StyleContentLunchList>
                        {val.datas.map(data => (
                        <StyleLunchItem>
                            <StyleLunchItemTitle>{data.title}</StyleLunchItemTitle>
                            <div class="en-content-inner">{data.summary}</div>
                        </StyleLunchItem>
                        ))}
                    </StyleContentLunchList>
                </div>))}

                {responseData[0].datas[0].datas[1].url && 
                <StyleHappyHourImgFull style={{backgroundImage: `url('${responseData[0].datas[0].datas[1].url}')`}}/>}

                {/* drink */}
                <div id={responseData[1].achor || null } /> 
                <StyleDividingType>
                    <StyleIconFleurWep>
                        <StyleIconFleur />
                    </StyleIconFleurWep>
                </StyleDividingType>
                <div class="content-inner-warpper">
                    <div class="content-title">{responseData[1].datas[0].title}</div>
                    <div class="en-content-sub-title-inner">{responseData[1].datas[0].summary}</div>
                </div>

                <div class="content-inner-warpper happy-hour-attr">
                    <div class="happy-hour-content">
                        <StyleImg className="happy-hour-bottom" style={{backgroundImage: `url('${responseData[1].datas[1].url}')`}}/>
                        {responseData[1].datas[1].datas[0].datas.map(data =>(
                        <StyleSwirlItem>
                            <StyleHappyHourItemTitle>{data.title}</StyleHappyHourItemTitle>
                            <div class="content-inner">{data.summary}</div>
                        </StyleSwirlItem>
                        ))}
                    </div>
                    <div class="gray-line display-none"></div>
                    <div class="happy-hour-content">
                        <div class="happy-hour-bottom">
                        {responseData[1].datas[2].datas[0].datas.map(data =>(
                        <StyleSwirlItem>
                            <StyleHappyHourItemTitle>{data.title}</StyleHappyHourItemTitle>
                            <div class="en-content-inner">{data.summary}</div>
                        </StyleSwirlItem>
                        ))}
                        </div>
                        <StyleImg style={{backgroundImage: `url('${responseData[1].datas[2].url}')`}}/>
                    </div>
                </div>

                {/* Dynamic Area */}
                {dynamicData.length ? dynamicData.map(val => (
                <div id={val.html || null}>
                     <StyleDividingType>
                        <StyleIconFleurWep>
                            <StyleIconFleur />
                        </StyleIconFleurWep>
                    </StyleDividingType>
                    <div class="content-inner-warpper">
                        {val.title ? <div class="content-title">{val.title}</div> : null}
                        {val.summary ? <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>{val.summary}</div> : null}
                    </div>
                    {val.url ? <StyleContentImg imgUrl={val.url}/> : null}
                    {val.datas.length ? val.datas.map(data => (
                    <div>
                    <StyleDividingLine>
                        <StyleIconFleur />
                        <StyleDividingText>
                            <div class="dividing-inner-text">{data.title}</div>
                        </StyleDividingText>
                    </StyleDividingLine>
                    <StyleContentSpecialtyList>
                        {data.datas ? data.datas.map(list => (
                            <StyleSpecialtytItem>
                                {list.url ? 
                                <StyleTextTitleLink 
                                    style={list.url ? null : {color:'#5d5d5d'}} 
                                    to={list.url ? {pathname: `/${langData.type}/${mainpPath[2]}${list.url}`, state: {...list}}: {}}>
                                    <span dangerouslySetInnerHTML={{ __html: list.title }} />
                                    <span>
                                        {list.url ? <i class='icon-arrow-red' style={{transform: 'rotate(-90deg)'}} /> : null}
                                    </span>
                                </StyleTextTitleLink> : 
                                <StyleTextTitle
                                    dangerouslySetInnerHTML={{ __html: list.title }} 
                                    style={{color:'#5d5d5d'}}>
                                </StyleTextTitle>}
                            {list.summary ? <div class={langData.type === "zh-TW" ? "content-inner" : "en-content-inner"}>{list.summary}</div> : null}
                            </StyleSpecialtytItem>
                        )) : null}

                    </StyleContentSpecialtyList>
                    </div>)) : null}
                </div>
                )) : null}

                <div class="content-inner-bottom-warpper">
                    <div class="lunch-content-italic">{responseData[1].datas[3].summary}</div>
                    <div class="lunch-block"></div>
                    <div class="lunch-content-italic">{responseData[1].datas[3].html}</div>
                </div>

                {/* MORE */}
                 {responseData[2] && <div>
                <StyleDividingType>
                    <StyleIconFleurWep>
                        <StyleIconFleur />
                    </StyleIconFleurWep>
                </StyleDividingType>
                    <div class="content-inner-warpper-bottom">
                        {responseData[2].summary && <div class={langData.type === "zh-TW" ? 'content-sub-title-inner' : 'en-content-sub-title-inner'}>{responseData[2].summary}</div>}
                        {responseData[2].html && <div class="content-sub-innner-warp">
                            {responseData[2].html && <a class="content-food-bt" href={responseData[2].url} target='_blank'>{responseData[2].html}</a>}
                        </div>}
                    </div>
                </div>}

            </div>
            );
        }
    }
    
    const reduxHook = connect(
        state => ({
            nodeData: state.Content.nodeData,
            allNodes: state.Content.allNodes,
            langData: state.Content.lang,
        }),
        dispatch => bindActionCreators({
            ...indexAction,
        }, dispatch),
      );
    
    export default compose(
        withRouter,
        reduxHook,
    )(HappyHour);