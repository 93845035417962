import React, { PureComponent } from 'react';
// import styled from 'styled-components';
import { bindActionCreators, compose } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, reset, Field, formValueSelector, change} from 'redux-form'; 
import Textinput, { regexCheck } from '../conponents/shared/Textinput';
import APPCONFIG from '../config/config.js';
import { MEMBER_FORM } from '../shared/form';
import * as indexAction from '../actions/index';

class Member extends PureComponent<Props> {
    constructor(props) {
        super(props);
        this.state = {
            dateType: 'text',
            dateType2: 'text',
            isMan: true,
            isAgree: false,
            errorMsg: {
                name: '',
                phone: '',
                email: '',
            },
        };
        this.setHintMsg = this.setHintMsg.bind(this);
        this.changeAgree = this.changeAgree.bind(this); 
    }

    componentWillMount() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera 
    }

    componentDidUpdate(preprops) {
        const {
            location: {
                pathname,
                state,
              },
            allNodes,
            footerData,
            fetchNodeData,
            nodeData,
            changeLanguage,
            sendMemberData,
            menuData,
            sendMemberSuccess,
        } = this.props;
        const contactUs = footerData.find(val => val.route === '/contact-us');
        const mainpPath = pathname.split("/");
        if (state) {
            if(!nodeData['/contact-us']) {
                if(!contactUs) return null;
                fetchNodeData(contactUs.url, contactUs.route);
            }
        } else {
            if(footerData.length && menuData.length) {
                if(nodeData[`/${mainpPath[2]}`] && nodeData['/reservations'] && !nodeData['/contact-us']) {
                    fetchNodeData(contactUs.url, contactUs.route);
                } 
                 
            } 
        }
        if(sendMemberData && sendMemberData.statusCode === 0) {
            this.props.reset(MEMBER_FORM);
            sendMemberSuccess(null);
        }
    }

    changeAgree() {
        const {
            isAgree,
        } = this.state;
        this.setState({isAgree: !isAgree})
    }

    setHintMsg(name, value) {
        const {
            errorMsg,
        } = this.state;
        this.setState({
            errorMsg: {
                ...errorMsg,
                [name]: value,
            },
        });
    }

    postData() {
        const {
            isAgree,
        } = this.state;
        const {
            location: {
                pathname,
            },
            LastName,
            FirstName,
            Phone,
            Email,
            sendMember,
            Date,
            Birthday,
            langData,
            nodeData,
        } = this.props;
        const lastNameMsg = regexCheck('name', LastName || '');
        const firstNameMsg = regexCheck('name', FirstName || '');
        const phoneMsg = regexCheck('phone', Phone || '');
        const emailMsg = regexCheck('email', Email || '');
        const mainpPath = pathname.split("/");
        const responseData = nodeData[`/${mainpPath[2]}`];
        if (lastNameMsg || firstNameMsg || phoneMsg || emailMsg) {
            this.setState({
                errorMsg: {
                    lastName: lastNameMsg,
                    firstName: firstNameMsg,
                    phone: phoneMsg,
                    email: emailMsg,
                },
            })
        } else {
            const post = {
                email: Email,
                date: Date,
                name: LastName + ' ' + FirstName,
                phone: Phone,
                birthday: Birthday,
                head: langData.emailHead['member'],
            };
            
            if(responseData.summary) {
                if(isAgree) {
                    sendMember(post);
                    let box=document.getElementById("check"); 
                    box.checked=false;
                    this.changeAgree();
                } else {
                    alert(`${langData.memberAlert}`)
                };
            } else {
                sendMember(post);
            }
            
        }
    }

    render() {
        const {
            errorMsg,
            dateType,
            dateType2,
            isAgree,
        } = this.state;
        const {
            location: {
                state,
                pathname,
            },
            footerData,
            nodeData,
            langData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[2]}`] || !nodeData['/contact-us']) return null;
        const privacy = footerData.find(val => val.route === '/privacy');
        const responseData = nodeData[`/${mainpPath[2]}`];
        const contactPlaceholder = nodeData['/contact-us'] && nodeData['/contact-us'].datas[1].datas;
        return (
            <form class="content-warpper" onSubmit={() => this.postData()}>
                <div class="content-sub-warpper">
                    <div class="menber-title">{responseData.title}</div>
                    <div class="content-input-warpper">
                        <Field
                            regex="name"
                            placeholder={contactPlaceholder[0].title}
                            name="LastName"
                            hintMsg={errorMsg.lastName}
                            setHintMsg={this.setHintMsg}
                            component={Textinput} />
                        <Field
                            regex="name"
                            placeholder={contactPlaceholder[10].title}
                            name="FirstName"
                            hintMsg={errorMsg.firstName}
                            setHintMsg={this.setHintMsg}
                            component={Textinput} />
                        <Field
                            regex="phone"
                            placeholder={contactPlaceholder[3].title}
                            name="Phone"
                            setHintMsg={this.setHintMsg}
                            hintMsg={errorMsg.phone}
                            component={Textinput} />
                        <Field
                            regex="email"
                            placeholder={contactPlaceholder[4].title}
                            name="Email"
                            setHintMsg={this.setHintMsg}
                            hintMsg={errorMsg.email}
                            component={Textinput} />
                        <Field
                            type={dateType}
                            changeType={() =>this.setState({dateType: 'date'})}
                            FocusType={() =>this.setState({dateType: 'date'})}
                            BlurType={() =>this.setState({dateType: 'text'})}
                            placeholder={contactPlaceholder[11].title}
                            name="Birthday"
                            component={Textinput} />
                        <Field
                            type={dateType2}
                            changeType={() =>this.setState({dateType2: 'date'})}
                            FocusType={() =>this.setState({dateType2: 'date'})}
                            BlurType={() =>this.setState({dateType2: 'text'})}
                            placeholder={contactPlaceholder[7].title}
                            name="Date"
                            component={Textinput} />
                    </div> 
                    <div class="content-sub-innner-warp">
                        {responseData.summary && <div class="content-checkbox">
                            <div class="form">
                                <input type="checkbox" id="check" name="agree" value={isAgree}/>
                                <label for="check" onClick={() =>this.changeAgree()}>
                                    <svg viewBox="0,0,50,50">
                                    <path d="M5 30 L 20 45 L 45 5"></path>
                                    </svg>
                                </label>
                            </div>
                            {/* <Link to={{ pathname: `${privacy && privacy.route}`, state: {...privacy} }}> */}
                            <p>{responseData.summary}</p>
                            {/* </Link> */}
                        </div>}
                    </div>
                    <div class="content-inner-bottom-warpper">
                        <div 
                            class="content-gray-bt" 
                            onClick={
                                () => {this.postData()}}>
                           {contactPlaceholder[9].title}
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const selector = formValueSelector(MEMBER_FORM);

const reduxHook = connect(
    state => {
        const initialValues = {
            LastName: '',
            FirstName: '',
            Phone: '',
            Email: '',
            // Date: (new Date()).toISOString().slice(0,10).replace(/-/g,"-"),
            Date: '',
            Birthday: '',
        };
        return {
            ...initialValues,
            LastName: selector(state, 'LastName'),
            FirstName: selector(state, 'FirstName'),
            Phone: selector(state, 'Phone'),
            Email: selector(state, 'Email'),
            Date: selector(state, 'Date'),
            Birthday: selector(state, 'Birthday'),
            nodeData: state.Content.nodeData,
            menuData: state.Content.menuData,
            footerData: state.Content.footerData,
            allNodes: state.Content.allNodes,
            langData: state.Content.lang,
            sendMemberData: state.Content.sendMemberData,
        }
    },
    dispatch => bindActionCreators({
        ...indexAction,
        // reset: () => dispatch(reset()),
    }, dispatch)
);

const formHook = reduxForm({
    form: MEMBER_FORM,
    enableReinitialize: true,
    initialValues: {
        LastName: '',
        FirstName: '',
        Phone: '',
        Email: '',
        // Date: (new Date()).toISOString().slice(0,10).replace(/-/g,"-"),
        Date: '',
        Birthday: '',
    },
});

export default compose(
    reduxHook,
    formHook,
)(Member);