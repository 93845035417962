import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  compose,
  bindActionCreators,
} from 'redux';
import * as indexAction from '../actions/index.js';
import { withRouter } from 'react-router';

import food06 from '../static/food06.jpg';
import whiteWine from '../static/white_wine_feature.jpg';
import home2_01 from '../static/home2-01.jpg';
import num1 from '../static/num1.svg';
import num2 from '../static/num2.svg';

class HomeContent2 extends PureComponent<Props> {
    componentWillMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    
    componentDidMount() {
        const {
            fetchSpecialBlock,
        } = this.props;
        fetchSpecialBlock(1);
    }
    
    render() {
        const {
            location: {
                pathname,
              },
            nodeData,
            menuData,
            specialBlock,
            footerData,
            langData,
        } = this.props;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[1]}`]) return null;
        const responseData = nodeData[`/${mainpPath[1]}`].datas;
        const member = footerData.find(val => val.route === '/member');
        const reservations = menuData.find(val => val.route === '/reservations');
        const foodData = menuData.find(val => val.route === '/food-drink');
        return (
            <div class="content-warpper home2-background-color">
                <div class="home2-conetent1-background-color">
                    <div class="home2-inner-title-warpper">
                        <div class="content-title">{responseData[0].datas[0].title}</div>
                        <div class="home2-conetent-title-inner">{responseData[0].datas[0].summary}</div>
                    </div>
                    
                    <div class="content-inner-warpper home2-conetent1-wrap">
                        <div class="home2-conetent1-left">
                            <div class="home2-conetent1-number" style={{backgroundImage: `url(${num1})`}}></div>
                            <div class="home2-conetent1-left-img" style={{backgroundImage: `url(${responseData[0].datas[0].datas[2] ? responseData[0].datas[0].datas[2].url : ''})`}}></div>
                            <div class="home2-conetent1-left-text">{responseData[0].datas[0].datas[2] ? responseData[0].datas[0].datas[2].title : ''}</div>
                        </div>
                        <div class="home2-conetent1-right">
                            <div class="home2-conetent1-number" style={{backgroundImage: `url(${num2})`}}></div>
                            <div class="home2-conetent1-right-img" style={{backgroundImage: `url(${responseData[0].datas[0].datas[3] ? responseData[0].datas[0].datas[3].url : ''})`}}></div>
                            <div class="home2-conetent1-right-text">{responseData[0].datas[0].datas[3] ? responseData[0].datas[0].datas[3].title : ''}</div>
                        </div>
                    </div>
                </div>

                <div class="content-inner-warpper home2-conetent2-wrap">
                    <div class="home2-conetent2-inner">
                        <div class="home2-conetent2-left-img" style={{backgroundImage: `url(${responseData[0].datas[0].datas[0].url})`}}></div>
                    </div>
                    {/* Technique */}
                    <div class="home2-conetent2-inner">
                        <div class="home2-conetent2-text">
                            <div class="home2-inner-text-italic home2-inner-padding">{responseData[0].datas[0].datas[0].summary}</div>
                            <div class="home2-inner-title home2-inner-margin">{responseData[0].datas[0].datas[0].title}</div>
                            <div class="home2-inner-text home2-inner-padding">{responseData[0].datas[0].datas[0].html}</div>
                            <button class="home2-button">
                                <Link to={{ pathname: `/${langData.type}${foodData && foodData.route}`, state: {...foodData} }}>{responseData[0].datas[0].datas[0].achor}</Link>
                            </button>
                        </div>
                    </div>
                </div>
                {/* Ingredients */}
                <div class="content-sub-innner-warp home2-conetent3-wrap">
                    <div class="home2-conetent3-text-italic">{responseData[0].datas[0].datas[1] ? responseData[0].datas[0].datas[1].summary : ''}</div>
                    <div class="home2-conetent3-title">{responseData[0].datas[0].datas[1] ? responseData[0].datas[0].datas[1].title : ''}</div>
                    <div class="home2-inner-text">
                    {responseData[0].datas[0].datas[1] ? responseData[0].datas[0].datas[1].html : ''}                    
                    </div>
                {/* cow */}
                    <div class="home2-conetent3-img" style={{backgroundImage: `url(${responseData[0].datas[0].icon})`}}/>
                </div>
                {/* THE PERFECT PAIRING */}
                <div class="content-inner-warpper">
                    <div class="content-title">{responseData[0].datas[1].title}</div>
                </div>
                {/* Experience */}
                <div class="content-inner-warpper home2-conetent2-wrap">
                    <div class="home2-conetent2-inner">
                        <div class="home2-conetent2-left-img" style={{backgroundImage: `url(${responseData[0].datas[1].url})`}}></div>
                    </div>
                    <div class="home2-conetent2-inner">
                        <div class="home2-conetent2-text">
                            <div class="home2-inner-text-italic home2-inner-padding">{responseData[0].datas[1].datas[0].summary}</div>
                            <div class="home2-inner-title home2-inner-margin">{responseData[0].datas[1].datas[0].title}</div>
                            <div class="home2-inner-text home2-inner-padding">
                            {responseData[0].datas[1].datas[0].html} 
                            </div>
                            {/* explore our wines */}
                            <button class="home2-button">
                                <Link to={`/${langData.type}/drink`}>
                                {responseData[0].datas[1].datas[0].achor}
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
                {/* TASTING NOTES */}
                <div class="content-sub-innner-warp home2-conetent4-wrap">
                    <div class="home2-conetent4-background"></div>
                    <div class="home2-conetent4-inner-wrap">
                        <div class="home2-conetent4-left">
                            <div class="home2-inner-text-italic home2-conetent4-inner-padding">{responseData[0].datas[2].datas[1] ? responseData[0].datas[2].datas[1].summary : ''}</div>
                            <div class="home2-inner-title home2-conetent4-inner-margin">{responseData[0].datas[2].datas[1] ? responseData[0].datas[2].datas[1].title : ''}</div>
                            <div class="home2-inner-text home2-conetent4-inner-padding">
                            {responseData[0].datas[2].datas[1] ? responseData[0].datas[2].datas[1].html : ''} 
                            </div>
                        </div>
                        <div class="home2-conetent4-middle" style={{backgroundImage: `url(${responseData[0].datas[2].datas[1] ? responseData[0].datas[2].datas[1].url : ''})`}}>
                            <div class="home2-conetent4-title-italic">FAUST</div>
                        </div>
                        {/* HUNEEUS FAMILY WINERY */}
                        <div class="home2-conetent4-right">
                            <div class="home2-conetent3-text-italic">{responseData[0].datas[2].datas[0] ? responseData[0].datas[2].datas[0].summary : ''}</div>
                            <div class="home2-conetent3-title home2-conetent3-right-title">{responseData[0].datas[2].datas[0] ? responseData[0].datas[2].datas[0].title : ''}</div>
                            <div class="home2-conetent4-right-img" style={{backgroundImage: `url(${responseData[0].datas[2].datas[0] ? responseData[0].datas[2].datas[0].url : ''})`}}></div>
                        </div>
                    </div>
                </div>
                {/* STAGS' LEAP WINE DINNER */}
                <div class="content-sub-innner-warp home2-conetent5-wrap" style={{backgroundImage: `url(${responseData[0].datas[3].datas[0] && responseData[0].datas[3].datas[0].url})`}}>
                    <div class="overlay"></div>
                    <div class="home2-conetent5-inner-wrap">
                        <div class="home2-conetent5-title">{responseData[0].datas[3] && responseData[0].datas[3].title}</div>
                        {/* button */}
                        <button class="home2-conetent5-button">
                            <Link to={{ pathname: `/${langData.type}${reservations && reservations.route}`, state: {...reservations} }}>
                                {responseData[0].datas[3] && responseData[0].datas[3].achor}
                            </Link>
                        </button>
                    </div>
                </div>

                <div class="content-inner-warpper">
                    <div class="content-title">{specialBlock.title}</div>
                </div>
                <div class="home2-conetent6-wrap">
                    <div class="home2-conetent6-inner-wrap">
                        <div class="home2-conetent6-inner-img" style={{backgroundImage: `url(${home2_01})`}}></div>
                        <div class="home2-conetent6-inner">
                            <div class="home2-inner-text">{specialBlock.summary}</div>
                            <Link to={{ pathname: `/${langData.type}${member && member.route}`, state: {...member} }}><button class="home2-conetent6-button">{specialBlock.subHtml}</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const reduxHook = connect(
    state => ({
        nodeData: state.Content.nodeData,
        specialBlock: state.Content.specialBlock,
        footerData: state.Content.footerData,
        menuData: state.Content.menuData,
        langData: state.Content.lang,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
  );

export default compose(
    withRouter,
    reduxHook,
)(HomeContent2);