import React, { PureComponent } from 'react';

class Radioinput extends PureComponent<Props> {
    constructor(props) {
        super(props);
        this.state = {
            isMan: true,
            sex: 'Male', 
        };
    }
    componentDidUpdate() {
        const {
            reset,
        } = this.props;
        
        if(reset) {
            this.setState({
                isMan: true,
            });
        }
    }

    getValue(val) {
        this.setState({
            isMan: val,
        });
    }

    render() {
        const {
            input: {
                onChange,
              },
              placeholder,
        } = this.props;
        return (
            <div class="content-radio content-inner" type="text">
                <div class="content-radio-label">
                    <label for="opt1" class="radio">
                        <input
                            onChange={(e) => {
                                onChange(e);
                                this.setState({ 
                                    isMan: !this.state.isMan,
                                });
                                
                            }}
                            checked={this.state.isMan}
                            type="radio"
                            name="Sex"
                            id="opt1"
                            class="hidden"
                            value="Male" />
                        <span class="label"></span>
                    </label>
                    <p>{placeholder[1].title}</p>
                </div>
                <div class="content-radio-label">
                    <label for="opt2" class="radio">
                        <input
                            onChange={(e) => {
                                onChange(e);
                                this.setState({ 
                                    isMan: !this.state.isMan,
                                });
                                
                        }}
                            checked={!this.state.isMan}
                            type="radio"
                            name="Sex"
                            id="opt2"
                            class="hidden"
                            value="Female" />
                        <span class="label"></span>
                    </label>
                    <p>{placeholder[2].title}</p>
                </div>
            </div>
        );
    }
}


export default Radioinput;