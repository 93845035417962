// @flow

import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';

const StyleWarpper = styled.div`
  width: ${props => props.isFullWidth ? '100%' : '48%'};
  position: relative;
  @media (max-width: 1023px) {
    width: 100%;
}
`;
const StyleErrorMsg = styled.div`
  position: absolute;
  color: red;
  font-size: 12px;
  left: 0;
  bottom: 0;
`

export const regexCheck = (name, value) => {
  switch (name) {
    case 'name':
      if (!value || value.match(/\d/)) {
        return '姓名格式錯誤';
      }
      return '';

    case 'phone':
      if (!value.match(/\(?([0-9]{4})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/)) {
        return '電話格式錯誤';
      }
      return '';

    case 'email':
      if (!value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/)) {
        return '信箱格式錯誤';
      }
      return '';

    // case 'date':
    //   if (!value.match(/^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/)) {
    //     return '日期格式錯誤(mm/dd/yyyy)';
    //   }
    //   return '';
    default:
      return '';
  }
}

class TextInput extends PureComponent<Props> {
  state = {
    errorMsg: '',
  }
  
  render() {
    const {
      input: {
        onChange,
        value,
      },
      placeholder,
      isFullWidth,
      regex,
      setHintMsg,
      hintMsg,
      type,
      changeType,
      FocusType,
      BlurType,
    } = this.props;
    const {
      errorMsg,
    } = this.state;
  
    return (
      <StyleWarpper>
        <input
            isFullWidth={isFullWidth}
            class={isFullWidth ? "content-input content-input-full" : "content-input"}
            placeholder={placeholder}
            type={type ? type : "text"}
            value={value}
            // onClick={() => aaa()}
            onBlur={() => {
              if (regex) {
                const msg = regexCheck(regex, value);
                setHintMsg(regex, msg);
                this.setState({ errorMsg: msg});
              };
              if(BlurType){
                if(!value){BlurType();};
              };
            }}
            onChange={(e) => {
              onChange(e);
              if(changeType){changeType();}
              // if (regex && (hintMsg || errorMsg)) {
              //   const msg = regexCheck(regex, e.target.value);
              //   setHintMsg(regex, msg);
              //   this.setState({ errorMsg: msg });
              // }
            }} 
            onFocus={(e) => {
              if(FocusType){FocusType();}
            }} 
            />
        <StyleErrorMsg>{hintMsg || errorMsg}</StyleErrorMsg>
      </StyleWarpper>
    );
  }
}
  
  
export default TextInput;
  