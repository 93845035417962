import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as indexAction from '../actions/index.js';
import APPCONFIG from '../config/config.js';

import HeaderTop2 from './HeaderTop2.jsx';

class NavList2 extends PureComponent<Props> {
    constructor(props) {
        super(props);
        this.state = {
            menuVisible: false,
        };
    }

    // componentDidUpdate(prevProps) {
    //     const {
    //         getMenu,
    //         getFooter,
    //         langData,
    //     } = this.props;
    //     if (prevProps.langData !== langData) {
    //         getMenu('');
    //         getFooter('');
    //     }
    // }

    render() {
        const {
            location: {
                pathname,
            },
            menuData,
            langData,
        } = this.props;

        const {
            menuVisible,
        } = this.state;

        return (
            <div>
                {/* <HeaderTop2/> */}
                <div class={`home2-nav-list-wrap ${menuVisible ? 'is-fall' : ''}`}>
                    <div class={`home2-nav-bt ${menuVisible ? 'is-change' : ''}`}>
                        <div class={`home2-hamburger ${menuVisible ? 'is-active' : ''}`}
                                onClick={() => {
                                    this.setState({
                                        menuVisible: !menuVisible,
                                    })
                                }}>
                                <div class="home2-hamburger-box">
                                    <div class="home2-hamburger-inner" />
                                </div>
                            </div>
                            <div class="home2-menu-text">MENU</div>
                    </div>
                    <div class={`home2-nav ${menuVisible ? 'is-active' : ''}`}>
                        <div class="home2-nav-bt-warp">
                            {menuData.map(val => val.route !== '/reservations' ? (
                            <Link 
                                key={val.route} 
                                class="home2-nav-button" 
                                onClick={() => {
                                    this.setState({
                                        menuVisible: !menuVisible,
                                    })}} 
                                    to={{ pathname: `/${langData.type}${val.route}`, state: {...val} }}>
                                <i class="icon-arrow-white"/>
                                {val.title}
                            </Link>
                            ) : null)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const reduxHook = connect(
    state => ({
        menuData: state.Content.menuData,
        langData: state.Content.lang,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch),
);
export default compose(
    withRouter,
    reduxHook,
)(NavList2);