import React, { PureComponent } from 'react';
// import styled from 'styled-components';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form'; 
import Textinput, { regexCheck } from '../conponents/shared/Textinput';
import Textarea from '../conponents/shared/Textarea';
import Radioinput from '../conponents/shared/Radioinput';
import Dropdown from '../conponents/shared/Dropdown';
import { CONTACTUS_FORM } from '../shared/form';
import * as indexAction from '../actions/index';
import SpecialBlock from '../conponents/shared/SpecialBlock';

class ContactUs extends PureComponent<Props> {
    constructor(props) {
        super(props);
        this.state = {
            // isMan: true,
            errorMsg: {
                name: '',
                phone: '',
                email: '',
            },
        };
        this.setHintMsg = this.setHintMsg.bind(this);
    }

    componentWillMount() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    componentDidUpdate(preprops) {
        const {
            sendContactUsData,
            sendContactUsSuccess,
        } = this.props;
        if(sendContactUsData && sendContactUsData.statusCode === 0) {
            this.props.reset(CONTACTUS_FORM);
            sendContactUsSuccess(null);
        }
    }



    postData() {
        const {
            location: {
                pathname,
              },
            LastName,
            FirstName,
            Phone,
            Email,
            Item,
            Comments,
            sendContactUs,
            Sex,
            nodeData,
            langData,
            allNodes
        } = this.props;
        const mainpPath = pathname.split("/");
        const lastNameMsg = regexCheck('name', LastName || '');
        const firstNameMsg = regexCheck('name', FirstName || '');
        const phoneMsg = regexCheck('phone', Phone || '');
        const emailMsg = regexCheck('email', Email || '');
        const contactList = nodeData[`/${mainpPath[2]}`].datas[0].datas;
        const ItemData = Item || contactList[0].title;
        let country = '';
        switch (allNodes.nodeId) {
            case 'EC6038CEA6634D1D8D9FA30610004B66':
                country = 'tw';
                break;
            case '93394D2A0E4847F8A9EF893EBEE89D72':
                country = 'twen';
                break;
            case '4E23F940C2BC4930852B6E4D0DDCA477':
                country = 'hk';
                break;
            case 'D6E55A22847244BFB3509F539AAA923F':
                country = 'jp';
                break;
            case 'E14D32056D884353877DC1F090B66477':
                country = 'jpen';
                break;
            case '015C92B4FF634BAB9EF352F34FAEFC51':
                country = 'sg';
                break;
            case 'DCAFFBEA22344BB9AD52BB51C87BDB0E':
                country = 'id';
                break;
            case '2D6882035ECD4AC5BCEBA14A733690A4':
                country = 'iden';
                break;
            case '2E733BC6F54A442DA5984097BB809688':
                country = 'ph';
                break;
            case '3B0F68D324184DE78C686D7C0A549F70':
                country = 'phen';
                break;
            default:
                break;
        }
        if (lastNameMsg || firstNameMsg || phoneMsg || emailMsg) {
            this.setState({
                errorMsg: {
                    lastName: lastNameMsg,
                    firstName: firstNameMsg,
                    phone: phoneMsg,
                    email: emailMsg,
                },
            })
        } else {
            const post = {
                email: Email,
                item: ItemData,
                comments: Comments,
                name: LastName + ' ' + FirstName,
                phone: Phone,
                sex: '',
                head: langData.emailHead['contactus'],
                country: country,
            };
            sendContactUs(post);
        }
    }

    setHintMsg(name, value) {
        const {
            errorMsg,
        } = this.state;
        this.setState({
            errorMsg: {
                ...errorMsg,
                [name]: value,
            },
        });
    }

    render() {
        const {
            nodeData,
            location: {
                state,
                pathname,
            },
            sendContactUsData,
        } = this.props;
        const {
            errorMsg,
        } = this.state;
        const mainpPath = pathname.split("/");
        if (!nodeData[`/${mainpPath[2]}`] && (!state || !nodeData[state.route])) return null;
        const responseData = nodeData[`/${mainpPath[2]}`] || nodeData[state.route];
        const contactList = responseData.datas[0].datas;
        const contactPlaceholder = responseData.datas[1].datas;
        return (
            <form class="content-warpper" onSubmit={() => this.postData()}>
                <div class="content-sub-warpper">
                    <div class="menber-title">{responseData.title}</div> 
                    <div class="content-input-warpper">
                        <Field
                            regex="name"
                            placeholder={contactPlaceholder[0].title}
                            name="LastName"
                            hintMsg={errorMsg.lastName}
                            setHintMsg={this.setHintMsg}
                            component={Textinput} />
                        <Field
                            regex="name"
                            placeholder={contactPlaceholder[10].title}
                            name="FirstName"
                            hintMsg={errorMsg.firstName}
                            setHintMsg={this.setHintMsg}
                            component={Textinput} />
                        <Field
                            regex="phone"
                            placeholder={contactPlaceholder[3].title}
                            name="Phone"
                            setHintMsg={this.setHintMsg}
                            hintMsg={errorMsg.phone}
                            component={Textinput} />
                        <Field
                            regex="email"
                            placeholder={contactPlaceholder[4].title}
                            name="Email"
                            setHintMsg={this.setHintMsg}
                            hintMsg={errorMsg.email}
                            component={Textinput} />
                        <Field
                            isFullWidth
                            placeholder={contactPlaceholder[5].title}
                            name="Item"
                            data={contactList}
                            optiontype="other"
                            component={Dropdown} />
                        <Field
                            placeholder={contactPlaceholder[8].title}
                            name="Comments"
                            component={Textarea} />
                    </div>
                    <div class="content-sub-innner-warp">
                        <div class="content-gray-bt" onClick={() => this.postData()}>{contactPlaceholder[9].title}</div>
                    </div>  
                </div>
                <SpecialBlock pathname={pathname} temp={1} />
            </form>
        );
    }
}

const selector = formValueSelector(CONTACTUS_FORM);

const reduxHook = connect(
    state => ({
        LastName: selector(state, 'LastName'),
        FirstName: selector(state, 'FirstName'),
        Sex: selector(state, 'Sex'),
        Phone: selector(state, 'Phone'),
        Email: selector(state, 'Email'),
        Item: selector(state, 'Item'),
        Comments: selector(state, 'Comments'),
        nodeData: state.Content.nodeData,
        allNodes: state.Content.allNodes,
        langData: state.Content.lang,
        sendContactUsData: state.Content.sendContactUsData,
    }),
    dispatch => bindActionCreators({
        ...indexAction,
    }, dispatch)
);

const formHook = reduxForm({
    form: CONTACTUS_FORM,
    initialValues: {
        LastName: '',
        FirstName: '',
        Sex: 'Male',
        Phone: null,
        Email: '',
        Item: null,
        Comments: '',
      },
});

export default compose(
    reduxHook,
    formHook,
)(ContactUs);